import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-setting-cookies',
  templateUrl: './modal-setting-cookies.component.html',
  styleUrls: ['./modal-setting-cookies.component.scss']
})
export class ModalSettingCookiesComponent implements OnInit {
  modalData: any;
  strictlyConsent: boolean = true
  performanceConsent: boolean = false;
  targetConsent: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalSettingCookiesComponent>,
  ) {
    this.modalData = data;
  }

  ngOnInit(): void {
    // console.log(this.modalData);
  }

  closeModal() {
    const response = {
      status: false
    }
    this.dialogRef.close(response);
  }

  acceptAllCookies() {
    const response = {
      status: true,
      strictly: true,
      performance: true,
      target: true,
      accept: 'all',
    }
    this.dialogRef.close(response);
  }

  submitCookies() {
    const response = {
      status: true,
      strictly: this.strictlyConsent,
      performance: this.performanceConsent,
      target: this.targetConsent,
      accept: 'option'
    }
    this.dialogRef.close(response);
  }

}
