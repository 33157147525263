<div class="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
    <div class="cookie-banner d-flex">
        <div class="cookie-message-wrapper">
            <p class="header">นโยบายการใช้คุกกี้</p>
            <p class="content">
                CarFinn มีการเรียกใช้งานคุกกี้ (Cookies) เพื่อให้เว็บไซต์ทำงานได้ตรงตามวัตถุประสงค์
                โดยมีการแสดงเนื้อหาข้อมูลต่างๆ เพื่อนำไปวิเคราะห์ให้ตรงกับความสนใจของผู้ใช้บริการ
                คุณสามารถยอมรับการทำงานของคุกกี้ หรือปรับแต่งค่าคุกกี้ได้ตามความต้องการ
            </p>
        </div>

        <div class="button-wrapper d-flex">
            <a (click)="settingCookies()" class="setting">
                ตั้งค่าคุกกี้
            </a>
            <button (click)="acceptAllCookies()" class="btn btn-submit">
                ยอมรับคุกกี้ทั้งหมด
            </button>
        </div>
    </div>
</div>

<div class="d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
    <div class="cookie-banner">
        <div class="cookie-message-wrapper">
            <p class="header">นโยบายการใช้คุกกี้</p>
            <p class="content">
                CarFinn มีการเรียกใช้งานคุกกี้ (Cookies) เพื่อให้เว็บไซต์ทำงานได้ตรงตามวัตถุประสงค์
                โดยมีการแสดงเนื้อหาข้อมูลต่างๆ เพื่อนำไปวิเคราะห์ให้ตรงกับความสนใจของผู้ใช้บริการ
                คุณสามารถยอมรับการทำงานของคุกกี้ หรือปรับแต่งค่าคุกกี้ได้ตามความต้องการ
            </p>
        </div>

        <div class="button-wrapper">
            <button (click)="settingCookies()" class="btn setting">ตั้งค่าคุกกี้</button>
            <button (click)="acceptAllCookies()" class="btn btn-submit">ยอมรับคุกกี้ทั้งหมด</button>
        </div>
    </div>
</div>