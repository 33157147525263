<div class="py-5" style="background-color: white">
  <p class="text-center head-text mb-4" data-cy="h1">บริการของเรา</p>
  <div class="row justify-content-center pt-4">
    <div
      data-testid="car-refinance"
      class="hover-box col-lg-2 col-md-6 col-sm-12"
      (click)="navigateLink('car-loan')"
    >
      <div class="box mb-4" data-cy="refinance car">
        <img
          style="max-width: 100px; aspect-ratio: 10/9"
          src="assets/home-image/our-service1.webp"
          alt="our-service1"
          fetchpriority="low"
        />
      </div>
      <p class="content-text" data-cy="h2">รีไฟแนนซ์รถยนต์</p>
    </div>
    <div
      data-testid="car-loan"
      class="hover-box col-lg-2 col-md-6 col-sm-12"
      (click)="navigateLink('car-loan')"
    >
      <div class="box mb-4" data-cy="registration car">
        <img
          style="max-width: 110px; aspect-ratio: 10/9"
          src="assets/home-image/our-service2.webp"
          alt="our-service2"
          fetchpriority="low"
        />
      </div>
      <p class="content-text" data-cy="h3">จำนำทะเบียนรถยนต์</p>
    </div>
    <div
      data-testid="insurance"
      class="hover-box col-lg-2 col-md-6 col-sm-12"
      (click)="gotoInsurance()"
    >
      <div class="box mb-4" data-cy="insurance bn">
        <img
          style="max-width: 125px; aspect-ratio: 10/9"
          src="assets/home-image/our-service3.webp"
          alt="our-service3"
          fetchpriority="low"
        />
      </div>
      <p class="content-text" data-cy="h4">ประกันภัย</p>
    </div>
    <div
      data-testid="propfinn-loan"
      class="hover-box col-lg-2 col-md-6 col-sm-12"
      (click)="gotoPropfinn()"
    >
      <div class="box mb-4" data-cy="refinance home">
        <img
          style="max-width: 100px; aspect-ratio: 10/9"
          src="assets/home-image/our-service4.webp"
          alt="our-service4"
          fetchpriority="low"
        />
      </div>
      <p class="content-text" data-cy="h5">จำนอง อสังหาฯ</p>
    </div>
    <div
      data-testid="propfinn-consignment-sale"
      class="hover-box col-lg-2 col-md-6 col-sm-12"
      (click)="gotoPropfinn()"
    >
      <div class="box mb-4" data-cy="consignment home">
        <img
          style="max-width: 120px; aspect-ratio: 10/9"
          src="assets/home-image/our-service5.webp"
          alt="our-service5"
          fetchpriority="low"
        />
      </div>
      <p class="content-text" data-cy="h6">ขายฝาก อสังหาฯ</p>
    </div>
  </div>
</div>
