import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewChecked,
  QueryList,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FacebookLoginProvider } from 'angularx-social-login';
import { LoginService } from './login.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';
import { Subscription, of, throwError } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalBanComponent } from './modal-ban/modal-ban.component';
import { AES } from 'crypto-js';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
declare const AppleID: any;
import { RedirectService } from 'src/app/core/services/redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  userDetail;
  create: boolean = false;
  createWith: string = 'mail';
  loginForm: FormGroup;
  registerEmailForm: FormGroup;
  registerPhoneForm: FormGroup;
  passwordVisible = false;
  remember: boolean = false;
  isError: boolean = false;
  errorMessage: string = 'อีเมล เบอร์โทรศัพท์ หรือ รหัสผ่านไม่ถูกต้อง';
  redirect: string;

  ErrorTips: string = 'โปรดระบุ';

  redirectPath: string = '';

  lineProfile: any;

  lineLoginSubscribtion: Subscription;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private cookieService: CookieService,
    public matDialog: MatDialog,
    private jwt: JwtHelperService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private redirectService: RedirectService
  ) {
    this.loginForm = fb.group({
      emailorphone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$)|(^02[0-9]{7}$)|(^06[0-9]{8}$)|(^08[0-9]{8}$)|(^09[0-9]{8}$)'
          ),
        ],
      ],
      password: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.redirectPath = new URL(location.href).searchParams.get('redirect');

    const storage = localStorage.getItem('google_auth');

    if (storage) {
      this.userDetail = JSON.parse(storage);
    }
  }

  handleSuccessLogin(accessToken) {
    this.cookieService.set('token', accessToken, 7, '/');
    const redirectData = this.redirectService.getRedirect();
    this.router.navigate([redirectData.redirectUrl], {
      queryParams: redirectData.queryParams,
    });
  }

  handleFailedLogin(statusCode) {
    if (statusCode === 9300) {
      this.isError = true;
    } else if (statusCode === 8300) {
      const banDialogConfig = new MatDialogConfig();
      banDialogConfig.disableClose = true;
      banDialogConfig.id = 'modalConfirm';
      banDialogConfig.width = '480px';
      banDialogConfig.autoFocus = false;
      banDialogConfig.panelClass = 'full-width-dialog';
      const banModal = this.matDialog.open(ModalBanComponent, banDialogConfig);
      banModal.afterClosed();
    } else {
      alert('ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง');
    }
  }

  systemLogin() {
    this.isLoading = true;
    let body;
    if (this.loginForm.valid) {
      body = {
        login_with: 'system',
        emailorphone: this.loginForm.value.emailorphone,
        password: this.loginForm.value.password,
      };
      this.loginService.login(body).subscribe(
        (res) => {
          this.isLoading = false;
          if (res.status.code === 1000) {
            this.handleSuccessLogin(res.data.accessToken);
          } else if (res.status.code === 9300) {
            this.isError = true;
          } else if (res.status.code === 8300) {
            const banDialogConfig = new MatDialogConfig();
            banDialogConfig.disableClose = true;
            banDialogConfig.id = 'modalConfirm';
            banDialogConfig.width = '480px';
            banDialogConfig.autoFocus = false;
            banDialogConfig.panelClass = 'full-width-dialog';

            const banModal = this.matDialog.open(
              ModalBanComponent,
              banDialogConfig
            );
            banModal.afterClosed();
          }
        },
        catchError((err) => {
          this.isLoading = false;
          alert('ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง');
          return of(false);
        })
        // (err) => // // console.log(err)
      );
    } else {
      this.isLoading = false;
      {
        Object.values(this.loginForm.controls).forEach((control) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }
  }

  // redirectToPrevious() {
  //   this.router.navigate([this.redirect ? this.redirect : '/']);
  // }

  validate(val) {
    if (val) {
      this.ErrorTips = 'อีเมลหรือเบอร์โทรศัพท์ไม่ถูกต้อง';
    } else {
      this.ErrorTips = 'โปรดระบุ';
    }
  }

  register() {
    if (this.redirectPath) {
      window.open(`register?redirect=${this.redirectPath}`, '_self');
    } else {
      window.open(`register`, '_self');
    }
  }
}
