<div class="homeInfo">
  <carfinn-navbar-transparent></carfinn-navbar-transparent>

  <div *ngIf="isLoading$ | async">
    <div
      style="
        position: fixed;
        z-index: 900;
        width: 100vw;
        height: 100vh;
        opacity: 80;
        top: 0;
        left: 0;
        background: #e8e8e880;
      "
    >
      <nz-spin
        nzSimple
        [nzSize]="'large'"
        style="
          z-index: 1000;
          padding: 400px 50px;
          margin: 20px 0;
          text-align: center;
        "
      ></nz-spin>
    </div>
  </div>

  <div class="bg" style="">
    <div
      *ngIf="articlesList && newArticle && recommendArticle"
      class="container py-5"
    >
      <h1 class="text-center mb-5 carfinn-primary-cyan-text topic">บทความ</h1>

      <div class="d-flex mb-4">
        <div class="header">
          <p class="main-header text-left ml-3">
            <span>มาใหม่ ! น่ารู้</span>
          </p>
        </div>
        <div *ngIf="isNewArticle" class="tag">NEW</div>
      </div>

      <div class="row d-flex">
        <div class="col-lg-6 col-md-7 col-sm-12">
          <div class="new-card">
            <div class="new-img-wrapper">
              <img
                [routerLink]="newArticle.path"
                [src]="newArticle.article_image"
                class="img-sec"
                alt="image"
              />
            </div>
            <div class="date">
              {{ newArticle.date | dateTime : "FullNoTime" }}
            </div>

            <div class="new-content">
              <p [routerLink]="newArticle.path" class="content-header">
                {{ newArticle.article_title }}
              </p>

              <p [routerLink]="newArticle.path" class="content">
                {{ newArticle.short_description }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-block"
        >
          <hr style="width: 100%" />
        </div>
        <div class="col-lg-6 col-md-5 col-sm-12 d-inline-grid">
          <div *ngFor="let rec of recommendArticle; let i = index">
            <div class="recommend-sec">
              <div class="recommend-img">
                <img
                  [routerLink]="rec.path"
                  class="rec-img"
                  [src]="rec.article_image"
                  alt="image"
                />
              </div>
              <div class="recommend-content">
                <p class="date">{{ rec.date | dateTime : "FullNoTime" }}</p>
                <p [routerLink]="rec.path" class="content">
                  {{ rec.article_title }}
                </p>
              </div>
            </div>
            <hr *ngIf="i !== 2" style="width: 100%; margin: 21px 0 11px 0" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-blue">
    <div *ngIf="articlesList" class="container py-5">
      <div class="d-flex mb-4">
        <div class="header">
          <p class="main-header text-left ml-3">
            <span>หัวข้อสำหรับคุณ</span>
          </p>
        </div>
      </div>

      <div
        class="d-xxl-block d-xl-block d-lg-block d-md-block d-sm-none d-none px-xxl-3 px-xl-0 px-lg-0 px-md-0 px-sm-0 px-0"
      >
        <div class="selector-sec mb-4">
          <div
            (click)="articleSelector = 'all'"
            (click)="changeType()"
            [ngClass]="{ active: articleSelector === 'all' }"
            class="btn-selector"
          >
            ทั้งหมด
          </div>
          <div
            (click)="articleSelector = 'car'"
            (click)="changeType()"
            [ngClass]="{ active: articleSelector === 'car' }"
            class="btn-selector"
          >
            รถ
          </div>
          <div
            (click)="articleSelector = 'home'"
            (click)="changeType()"
            [ngClass]="{ active: articleSelector === 'home' }"
            class="btn-selector"
          >
            อสังหาฯ
          </div>
          <div
            (click)="articleSelector = 'insurance'"
            (click)="changeType()"
            [ngClass]="{ active: articleSelector === 'insurance' }"
            class="btn-selector"
          >
            ประกัน
          </div>
        </div>
      </div>
      <div class="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-block">
        <select
          class="form-select"
          style="width: 150px"
          [(ngModel)]="articleSelector"
          (change)="changeType()"
        >
          <option value="all">ทั้งหมด</option>
          <option value="car">รถ</option>
          <option value="home">อสังหาฯ</option>
          <option disabled value="insurance">ประกัน</option>
        </select>
      </div>

      <div *ngIf="articlesList.length > 0" class="row">
        <div
          *ngFor="let article of articlesList"
          class="col-lg-4 col-md-6 col-sm-12 my-3"
        >
          <carfinn-article-card [article]="article"></carfinn-article-card>
        </div>
      </div>
      <div
        *ngIf="articlesList.length === 0"
        class="d-flex justify-content-center"
      >
        <div class="text-center disable-text">ยังไม่มีบทความ</div>
      </div>

      <div
        *ngIf="articlesList.length > 0"
        class="d-flex justify-content-center p-2"
      >
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [maxSize]="5"
          [pageSize]="pageSize"
          (pageChange)="refresh()"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
  <carfinn-footer></carfinn-footer>
</div>
