<div class="row part-1" style="font-size: 24px;margin: 2% 0;">
  <p data-cy="text10">
    <span style="font-weight: 500; color:#0A428F;">เป็นการทำสินเชื่อ ในรูปแบบของการซื้อขาย ของเจ้าของทรัพย์
      กับผู้รับซื้อฝาก</span> โดยการทำสัญญาเอกสารขายฝากที่ดิน และ มีข้อตกลงกันว่าผู้ขายฝากสามารถไถ่ทรัพย์สินนั้นคืนได้ตามเงื่อนไขของสัญญา และ ระยะเวลาที่ตกลงกัน
  </p>
</div>

<div class="row d-flex part-2" style="margin: 10px 0;">
  <div class="col-lg-4 col-12 d-flex align-items-center" >
    <img class="lazyload" fetchpriority="low" src="assets/tab-home/image 522.webp" alt="" style="border-radius: 5px; max-width: 100%; aspect-ratio: 398/185;">
  </div>

  <div class="col-lg-8 col-12" style="font-size: 22px;
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;">
    <p data-cy="text11" style="margin: 10px 0;">ซึ่งกรรมสิทธิ์ในทรัพย์สินที่ขายฝากจะตกเป็นของผู้รับซื้อฝากทันทีที่
      ขายฝากและได้ส่งมอบทรัพย์สินแก่ผู้รับซื้อฝาก
    </p>
    <p data-cy="text12" style="margin: 10px 0;">
      ซึ่งกรณีทรัพย์สินที่ขายฝากเป็นอสังหาริมทรัพย์อย่างที่ดิน คู่สัญญา
      จะต้องนำสัญญาขายฝากไปจดทะเบียน ณ สำนักงานที่ดิน ตามเขตพื้นที่
      ของทรัพย์ด้วยการขายฝากที่ดินจึงจะมีผลสมบูรณ์ตามกฎหมาย
    </p>
  </div>
</div>

<div class="row part-3" style="text-align: center;font-size: 24px;margin: 2% 0;">
  <p data-cy="text13">
    <span style="font-weight: 500;color:#0A428F;">ทั้งนี้
      หากผู้ขายฝากที่ดินไม่ได้ไถ่ทรัพย์สินคืนตามเงื่อนไขและระยะเวลาที่ตกลงกันดังกล่าว
      ผู้ขายฝากจะหมดสิทธิไถ่ทรัพย์สินคืนทันที และผู้รับซื้อฝากไม่ต้องไปใช้สิทธิฟ้องบังคับตามสัญญาขายฝากอีกด้วย</span>
  </p>
</div>
