import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'carfinn-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
})
export class PlatformComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() image: string = '';
  @Input() contentDetail: any[] = [];
  @Input() buttonPhoneNumber: string;
  @Input() buttonLineName: string;
  @Input() buttonLinkLine: string;

  collapseIndex: number = null;

  constructor() { }

  ngOnInit(): void { }

  toggleActive(content: any): void {
    content.active = !content.active;
  }

  setActive(index: number) {
    if (index === this.collapseIndex) {
      if (!this.contentDetail[index].active) {
        this.contentDetail[index].active = true;
      } else {
        this.contentDetail[index].active = false;
      }
    } else {
      this.contentDetail.forEach((e, i) => {
        if (i === index) {
          e.active = true;
        } else {
          e.active = false;
        }
      });
    }
    this.collapseIndex = index;
  }
}
