import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AES } from 'crypto-js';

interface resLogin {
  status: {
    code: number;
    description: string;
  };
  data?: {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
    expiresAt: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {

  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  encryptData(data: any) {
    console.log(JSON.stringify(data))
    return AES.encrypt(JSON.stringify(data), environment.CRYPTO_JS_PASSPHASE).toString();
  }



  auth(): void {
    this.loggedIn.next(true);
  }

  loginWithThirdParty(data): Observable<resLogin> {
    return this.http.post<resLogin>(
      environment.URL_FRONT + '/authen/register',
      data
    );
  }

  login(data: any): any {
    return this.http.post<resLogin>(
      environment.URL_FRONT + '/authen/login',
      data
    );
  }
}
