import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-parthner',
  templateUrl: './parthner.component.html',
  styleUrls: ['./parthner.component.scss']
})
export class ParthnerComponent implements OnInit {

  partnerOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 15,
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: { items: 2 },
      700: { items: 4 },
      1000: { items: 4 }
    },
  };

  financeOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 15,
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: { items: 2 },
      700: { items: 5 },
      1000: { items: 5 },
    },
  };

  @Input() partnerList = []
  @Input() leasingList = []

  constructor() { }

  ngOnInit(): void {
  }

}
