<div class="stepper-wrapper">
    <div class="stepper-item completed">
        <nz-card class="card step-card" [ngClass]="current < changeStep ? 'current': 'away'">
            <div class="d-flex justify-content-center">
                <p class="text-center circle-text" [ngClass]="current < changeStep ? 'active': 'inactive'">1</p>
                <div class="stepper-text ml-3">
                    <p class="text-left m-0 step-text">ขั้นตอนที่ 1</p>
                    <p class="text-left m-0 step-text">ข้อมูลรถยนต์</p>
                </div>
            </div>
        </nz-card>
    </div>
    <div class="stepper-item completed">
        <nz-card class="card step-card" [ngClass]="current >= changeStep ? 'current': 'away'">
            <div class="d-flex justify-content-center">
                <p class="text-center circle-text" [ngClass]="current >= changeStep ? 'active': 'inactive'">2</p>
                <div class="stepper-text ml-3">
                    <p class="text-left m-0 step-text">ขั้นตอนที่ 2</p>
                    <p class="text-left m-0 step-text">ข้อมูลผู้ยื่นกู้</p>
                </div>
            </div>
        </nz-card>
    </div>
</div>