import { GlobalFunctionService } from 'src/app/core/services/global-function.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AdvertisingService } from './core/services/advertising.service';
import { AdvertisingModalComponent } from './shared/components/advertising-modal/advertising-modal.component';
import { filter, shareReplay, takeLast } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  cookie_banner: any;
  close: boolean = false;
  title: 'carfinn';

  @ViewChild('tplContent') tplContent!: ElementRef;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private globalFunctionService: GlobalFunctionService,
    private route: ActivatedRoute,
    private router: Router,
    private advertisingService: AdvertisingService,
    private modal: NzModalService
  ) {
    translate.addLangs(['en', 'th']);

    const permission = this.cookieService.get('OptIn');
    if (permission.includes('performance=accept') && environment.production) {
      this.globalFunctionService.addGA();
    }

    this.cookie_banner = cookieService.get('cookie_banner');
    this.close = this.cookie_banner === 'close' ? true : false;

    if (!cookieService.get('language')) {
      cookieService.set('language', 'th');
      translate.setDefaultLang('th');
      translate.use(cookieService.get('language'));
    } else {
      translate.use(cookieService.get('language'));
    }
  }

  private shouldShowAdvertisement(url: string): boolean {
    const pathToShowAd = ['/', '/propfinn', '/car-loan'];
    const result = pathToShowAd.includes(url);
    return result;
  }

  async ngOnInit(): Promise<void> {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        shareReplay(1)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.shouldShowAdvertisement(event.urlAfterRedirects)) {
          this.showAdvertiser();
        }
      });
  }

  onActive(): void {
    window.scrollTo({ top: 0 });
  }

  private getMataData(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err);
      image.src = url;
    });
  }

  private async showAdvertiser(): Promise<void> {
    const delayInSeconds = 0;
    timer(delayInSeconds * 1000).subscribe(async () => {
      try {
        const res = await this.advertisingService
          .getAdvertisement()
          .toPromise();
        const id = res.id;
        const fileLocation = res.advertise_file;
        const advertiseLink = res.advertise_link;

        const image = await this.getMataData(fileLocation);
        let width = image.width;
        let height = image.height;

        if (width > height && width > 800) {
          width = 800;
        } else if (height < width && width > 400) {
          width = 400;
        } else {
          width = 400;
        }

        const modal = this.modal.create({
          nzComponentParams: {
            id,
            fileLocation,
            advertiseLink,
            width,
          },
          nzWidth: `${width + 100}px`,
          nzClosable: false,
          nzAutofocus: null,
          nzContent: AdvertisingModalComponent,
          nzFooter: null,
          nzBodyStyle: {
            textAlign: 'center',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },

          nzWrapClassName: 'advertising-modal',
        });

        modal.afterClose.subscribe(() => {
          this.onActive();
        });
      } catch (error) {
        console.error('Error fetching advertisement or image metadata:', error);
      }
    });
  }
}
