import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { GlobalFunctionService } from 'src/app/core/services/global-function.service';
import { HomeService } from '../../../home/home.service';
import { Router } from '@angular/router';
import { ModalSuccessComponent } from 'src/app/shared/components/modal-success/modal-success.component';
import { CarLoanService } from '../../car-loan.service';
import { UserProfileService } from '../../../user-profile/user-profile.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalLoginComponent } from 'src/app/shared/components/modal-login/modal-login.component';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-money-changer',
  templateUrl: './money-changer.component.html',
  styleUrls: ['./money-changer.component.scss'],
})
export class MoneyChangerComponent implements OnInit {
  @ViewChildren('cmp') components: QueryList<MoneyChangerComponent>;
  @ViewChild(NzCarouselComponent, { static: false })
  myCarousel: NzCarouselComponent;

  isLoading: boolean = false;
  current = 0;
  nextIndex: number = 0;

  filteredOptions = [];
  inputValue: string = '';
  addressOption: any[] = [];

  effect = 'scrollx';

  index = 'First-content';
  sources = ['carModel', 'carType', 'finalForm'];

  // carTypeSelect = ['ส่วนบุคคล', 'นิติบุคคล',];
  carTypeSelect: any[] = [];
  carInsuranStatusceSelect = ['ผ่อนหมดแล้ว', 'ยังผ่อนอยู่'];
  jobSelect: any[] = [
    'รับราชการ, รัฐวิสาหกิจ (มีรายได้ประจำ)',
    'พนักงานเอกชน (มีรายได้ประจำ)',
    'ผู้ประกอบการ, ประกอบธุรกิจส่วนตัว (จดทะเบียน นิติบุคคล)',
    'ผู้รับเหมา',
    'ค้าขาย (ไม่จดทะเบียนนิติบุคคล)',
    'ฟรีแลนซ์, ทำงานอิสระ',
    'เกษตรกร (ชาวนา, ชาวไร่, ชาวสวน)',
  ];
  incomeSelect: any[] = [
    '10,000 - 20,000 บาท/เดือน',
    '20,000 - 30,000 บาท/เดือน',
    '30,000 - 40,000 บาท/เดือน',
    '40,000 - 50,000 บาท/เดือน',
    '50,000 - 70,000 บาท/เดือน',
    '70,000 - 100,000 บาท/เดือน',
    '100,000 - 300,000 บาท/เดือน',
    'มากกว่า 300,000 บาท/เดือน',
  ];
  customerAgeSelector = [
    '20 - 35 ปี',
    '36 - 45 ปี',
    '46 - 55 ปี',
    '56 - 65 ปี',
    '66 ปี ขึ้นไป',
  ];

  workingLifeSelect = ['น้อยกว่า 1 ปี', '1 - 2 ปี', '2 - 3 ปี', 'มากกว่า 3 ปี'];
  usedCreditSelect = ['บัตรเครดิต', 'รถยนต์', 'บ้าน/ที่อยู่อาศัย'];
  threeYearAgoSatatusSelect = [
    'ผ่อนปกติ',
    'ค้างชำระบ้าน',
    'เคยมีคดีความเกี่ยวกับสินเชื่อ',
  ];

  loanSelect = [
    'ชำระภายใน 30 วัน',
    'ชำระภายใน 60 วัน',
    'ชำระภายใน 90 วัน',
    'ค้างชำระเกิน 120 วัน',
    'ฟ้องร้องดำเนินคดี',
    'ผ่อนหมดแล้ว',
  ];

  threeYearCheck: boolean = true;
  creditCheck: boolean = false;
  carCheck: boolean = false;
  homeCheck: boolean = false;

  carDefaultArr: any[] = [];
  carArr: any[] = [];

  profileList: any;

  carBrandSelect: any[] = [];
  carModelSelect: any[] = [];
  carYearSelect: any[] = [];
  carGearSelect: any[] = [];
  carEngineSelect: any[] = [];
  carClassSelect: any[] = [];
  provinceSelect: Set<string> = new Set();

  // for call function เพราะเลือก option หนึ่งตัวแล้วมันเรียกทุกฟังก์ชั่น ไม่รู้เพราะอะไร
  carBrandBoolean: boolean = false;
  carModelBoolean: boolean = false;
  carYearBoolean: boolean = false;
  carGearBoolean: boolean = false;
  carEngineBoolean: boolean = false;
  carClassBoolean: boolean = false;

  // for check input engine and class
  carEngineCheck: boolean = false;
  carClassCheck: boolean = false;

  carModelForm: FormGroup;
  carTypeForm: FormGroup;
  carOwner1Form: FormGroup;
  refinanceForm: FormGroup;
  moneyChangerForm: FormGroup;
  finalForm: FormGroup;

  car_logo: string = ''; // for send params

  token: string = '';

  haveCarRegisBook: boolean = false;

  bankList$: Observable<
    {
      bankname: string;
    }[]
  >;

  body: string;

  formatterPrice = (value: number): string =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private renderer: Renderer2,
    private globalFunctionService: GlobalFunctionService,
    private homeService: HomeService,
    public matDialog: MatDialog,
    private router: Router,
    private carLoanService: CarLoanService,
    private profileService: UserProfileService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService
  ) {
    this.token = this.authService.getToken();
    this.initializeForms();

    // this.path = this.router.url.split('\/')[1];
  }

  ngOnInit(): void {
    this.globalFunctionService.setSEO('รถแลกเงิน', 'website', '');

    this.globalFunctionService.setSEO('รถแลกเงิน', 'website', '');

    if (isPlatformBrowser(this.platformId)) {
      this.getCar();
      this.getExternalBank();
      this.getProvince();
      this.finalFormFactory();

      if (this.token) {
        this.getProfile();
      }
    }
  }

  initializeForms(): void {
    this.carModelForm = this.fb.group({
      car_brand: ['', Validators.required],
      car_model: ['', Validators.required],
      car_year: ['', Validators.required],
      car_gear: ['', Validators.required],
      car_engine: [''],
      car_class: [''],
      RegisterType: ['', Validators.required],
    });

    this.carTypeForm = this.fb.group({
      customer_province: ['', Validators.required],
      customer_zipcode: [
        '',
        [Validators.required, Validators.pattern('^[1234567890]{5}$')],
      ],
      customer_age: ['', Validators.required],
      job: ['', Validators.required],
      customer_income_amount: ['', Validators.required],
    });

    this.refinanceForm = this.fb.group({
      previous_bankname: ['', Validators.required],
      monthly_installment: ['', Validators.required],
      liabilities_amount: ['', Validators.required],
    });

    this.moneyChangerForm = this.fb.group({
      customer_credit: [''],
      customer_car: [''],
      customer_home: [''],
      loan_amount: ['', Validators.required],
    });
  }

  checkDisable(index: number): boolean {
    if (index === 0) {
      return this.carModelForm?.invalid || false;
    } else if (index === 1) {
      return this.carTypeForm?.invalid || false;
    }
    return true;
  }

  ngAfterViewInit() {
    // // console.log(this.components['_results']);
    const comp = this.components['_results'];
    let index = 105;

    comp.forEach((e, i) => {
      this.renderer.setStyle(
        this.components['_results'][i].nativeElement,
        'z-index',
        index
      );
      index--;
    });
  }

  checkPreDisable(index) {
    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }

  // checkDisable(index) {
  //   if (index === 0) {
  //     if (this.carModelForm.invalid) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else if (index === 1) {
  //     if (this.carTypeForm.invalid) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  getExternalBank() {
    this.bankList$ = this.carLoanService.getExternalBank().pipe(
      map((res) => {
        return res.data.map((item) => {
          return {
            bankname: item.bankname,
          };
        });
      })
    );
  }

  finalFormFactory() {
    if (this.haveCarRegisBook) {
      this.finalForm = this.moneyChangerForm;
    } else {
      this.finalForm = this.refinanceForm;
    }
  }

  isMoneyChangerForm(): boolean {
    return this.finalForm === this.moneyChangerForm;
  }

  isRefinanceForm(): boolean {
    return this.finalForm === this.refinanceForm;
  }

  change(form) {
    // // // console.log(form);
    if (form.status === 'VALID') {
      this.next();
    }
  }

  pre(): void {
    // // // console.log('this is prev', this.nextIndex);
    // // // console.log('this is current', this.current);
    this.myCarousel.pre();
  }

  // next(): void {
  //   this.nextIndex++;
  //   // // // console.log('this is next', this.nextIndex);
  //   // // // console.log('this is current', this.current);

  //   this.isLoading = true;
  //   setTimeout(() => {
  //     this.setNextCardStyle();
  //   }, 300);
  // }

  next() {
    this.myCarousel.next();
  }

  setNextCardStyle() {
    this.isLoading = false;
    // // // console.log(this.current);
    let indexNext = 105;
    const comp = this.components['_results'];
    this.renderer.setStyle(
      comp[this.current].nativeElement,
      'transition',
      'transform 300ms ease-out 0ms'
    );
    this.renderer.setStyle(
      comp[this.current].nativeElement,
      'transform',
      'translate(350px) rotate(0deg)'
    );

    setTimeout(() => {
      this.renderer.setStyle(
        comp[this.current].nativeElement,
        'transform',
        'translate(0px) rotate(0deg)'
      );
      this.renderer.setStyle(
        comp[this.current].nativeElement,
        'z-index',
        '100'
      );

      for (let i = this.current + 1; i < comp.length; i++) {
        this.renderer.setStyle(comp[i].nativeElement, 'z-index', indexNext);
        indexNext--;
      }
      for (let i = this.current - 1; i >= 0; i--) {
        this.renderer.setStyle(comp[i].nativeElement, 'z-index', indexNext);
        indexNext--;
      }
      this.current = this.nextIndex;
    }, 300);
  }

  setPrevCardStyle() {
    let indexNext = 104;
    const comp = this.components['_results'];
    // // // console.log(this.current);
    this.renderer.setStyle(
      comp[this.nextIndex].nativeElement,
      'transition',
      'transform 300ms ease-out 0ms'
    );
    this.renderer.setStyle(
      comp[this.nextIndex].nativeElement,
      'transform',
      'translate(-350px) rotate(0deg)'
    );

    setTimeout(() => {
      this.renderer.setStyle(
        comp[this.nextIndex].nativeElement,
        'transform',
        'translate(0px) rotate(0deg)'
      );
      this.renderer.setStyle(
        comp[this.nextIndex].nativeElement,
        'z-index',
        '105'
      );

      for (let i = this.nextIndex + 1; i < comp.length; i++) {
        this.renderer.setStyle(comp[i].nativeElement, 'z-index', indexNext);
        indexNext--;
      }
      for (let i = this.nextIndex - 1; i >= 0; i--) {
        this.renderer.setStyle(comp[i].nativeElement, 'z-index', indexNext);
        indexNext--;
      }

      this.current = this.nextIndex;
    }, 300);
  }

  getCar() {
    this.homeService
      .getCarBrand()
      .pipe(
        switchMap((res) => {
          if (res.status.code === 1000) {
            // console.log(res);
            this.carBrandSelect = res.data;

            return this.homeService.getCarType().pipe(
              map((type) => {
                if (res.status.code === 1000) {
                  this.carTypeSelect = type.data;
                } else {
                  return of(false);
                }
              })
            );
          } else {
            return of(false);
          }
        }),
        catchError((err) => {
          alert(err.error.message);
          return of(false);
        })
      )
      .subscribe();
  }

  getCarModel(brand) {
    this.carModelSelect = [];
    this.carYearSelect = [];
    this.carGearSelect = [];
    this.carEngineSelect = [];
    this.carClassSelect = [];

    this.carYearBoolean = false;
    this.carGearBoolean = false;
    this.carEngineBoolean = false;
    this.carClassBoolean = false;

    this.carEngineCheck = false;
    this.carClassCheck = false;
    this.carBrandSelect.forEach((e) => {
      if (e.car_brand === brand) {
        // // console.log(e);
        this.car_logo = e.logo;
      }
    });
    this.carModelForm.controls['car_model'].setValue('');
    this.carModelForm.controls['car_year'].setValue('');
    this.carModelForm.controls['car_gear'].setValue('');
    this.carModelForm.controls['car_engine'].setValue('');
    this.carModelForm.controls['car_class'].setValue('');
    this.homeService
      .getCarModel(brand)
      .pipe(
        map((res) => {
          if (res.status.code === 1000) {
            // console.log(res.data);
            this.carModelSelect = res.data;
            this.carYearBoolean = true;
          }
        })
      )
      .subscribe();
  }

  getCarYear(value) {
    if (this.carYearBoolean) {
      this.carYearSelect = [];
      this.carGearSelect = [];
      this.carEngineSelect = [];
      this.carClassSelect = [];

      this.carGearBoolean = false;
      this.carEngineBoolean = false;
      this.carClassBoolean = false;

      this.carEngineCheck = false;
      this.carClassCheck = false;

      this.carModelForm.controls['car_year'].setValue('');
      this.carModelForm.controls['car_gear'].setValue('');
      this.carModelForm.controls['car_engine'].setValue('');
      this.carModelForm.controls['car_class'].setValue('');
      this.homeService
        .getCarYear(value.car_brand, value.car_model)
        .pipe(
          map((res) => {
            if (res.status.code === 1000) {
              // console.log(res.data);
              this.carYearSelect = res.data;
              this.carGearBoolean = true;
            }
          })
        )
        .subscribe();
    }
  }

  getCarGear(value) {
    if (this.carGearBoolean) {
      this.carGearSelect = [];
      this.carEngineSelect = [];
      this.carClassSelect = [];

      this.carEngineBoolean = false;
      this.carClassBoolean = false;

      this.carEngineCheck = false;
      this.carClassCheck = false;

      this.carModelForm.controls['car_gear'].setValue('');
      this.carModelForm.controls['car_engine'].setValue('');
      this.carModelForm.controls['car_class'].setValue('');
      this.homeService
        .getCarGear(value.car_brand, value.car_model, value.car_year)
        .pipe(
          map((res) => {
            if (res.status.code === 1000) {
              // console.log(res.data);
              this.carGearSelect = res.data;
              this.carEngineBoolean = true;
            }
          })
        )
        .subscribe();
    }
  }

  getCarEngine(value) {
    if (this.carEngineBoolean) {
      this.carEngineSelect = [];
      this.carClassSelect = [];

      this.carClassBoolean = false;

      this.carEngineCheck = false;
      this.carClassCheck = false;

      this.carModelForm.controls['car_engine'].setValue('');
      this.carModelForm.controls['car_class'].setValue('');
      this.homeService
        .getCarEngine(
          value.car_brand,
          value.car_model,
          value.car_year,
          value.car_gear
        )
        .pipe(
          map((res) => {
            if (res.status.code === 1000) {
              // console.log(res.data);
              if (res.data.length > 1 && res.data[0].car_engine !== '') {
                this.carEngineSelect = res.data;
                this.carClassBoolean = true;
                this.carEngineCheck = true;
                this.carModelForm
                  .get('car_engine')
                  .setValidators(Validators.required);
                // this.carModelForm.get('car_engine').markAsDirty();
                this.carModelForm
                  .get('car_class')
                  .setValidators(Validators.required);
                // this.carModelForm.get('car_class').markAsDirty();
              } else {
                this.carModelForm.get('car_engine').clearValidators();
                this.carModelForm.get('car_engine').markAsPristine();
                this.carModelForm.get('car_class').clearValidators();
                this.carModelForm.get('car_class').markAsPristine();
              }
              this.carModelForm.get('car_engine').updateValueAndValidity();
              this.carModelForm.get('car_class').updateValueAndValidity();
            }
          })
        )
        .subscribe();
    }
  }

  getCarClass(value) {
    if (this.carClassBoolean) {
      this.carClassSelect = [];
      this.carClassCheck = false;

      this.carModelForm.controls['car_class'].setValue('');
      this.homeService
        .getCarClass(
          value.car_brand,
          value.car_model,
          value.car_year,
          value.car_gear,
          value.car_engine
        )
        .pipe(
          map((res) => {
            if (res.status.code === 1000) {
              // console.log(res.data);
              if (res.data.length > 1 && res.data[0].car_class !== '') {
                this.carClassSelect = res.data;
                this.carClassCheck = true;

                this.carModelForm
                  .get('car_class')
                  .setValidators(Validators.required);
                // this.carModelForm.get('car_class').markAsDirty();
              } else {
                this.carModelForm.get('car_class').clearValidators();
                this.carModelForm.get('car_class').markAsPristine();
              }
              this.carModelForm.get('car_class').updateValueAndValidity();
            }
          })
        )
        .subscribe();
    }
  }

  requiredChange(e) {
    if (e === 'ผ่อนหมดแล้ว') {
      this.carTypeForm.get('previous_contract_remaining').clearValidators();
      this.carTypeForm.get('previous_contract_remaining').markAsPristine();
    } else {
      this.carTypeForm
        .get('previous_contract_remaining')
        .setValidators(Validators.required);
      this.carTypeForm.get('previous_contract_remaining').markAsDirty();
    }
    this.carTypeForm
      .get('previous_contract_remaining')
      .updateValueAndValidity();
  }

  getProvince() {
    this.homeService
      .getAddress()
      .pipe(
        map((res) => {
          if (res['status'].code === 1000) {
            // // // console.log(res['data']);
            this.addressOption = res['data'];
          }
          const provinceSelect = this.addressOption.map(
            (item) => item.province
          );
          this.provinceSelect = new Set(provinceSelect);
        }),
        catchError((err) => {
          alert(err.error.message);
          return of(false);
        })
      )
      .subscribe();
  }

  getAddress(event: Event): void {
    let newArr = [];
    const filterStr = (event.target as HTMLInputElement).value;
    // // // console.log(filterStr)
    if (filterStr.length > 2) {
      this.addressOption.forEach((el) => {
        if (
          (el.area && el.area.includes(filterStr)) ||
          (el.district && el.district.includes(filterStr)) ||
          (el.province && el.province.includes(filterStr)) ||
          (el.zipcode && el.zipcode.toString().includes(filterStr))
        ) {
          // // // console.log(el);
          newArr.push(el);
        } else {
        }
      });

      newArr = newArr.map(
        (item) =>
          `${item.area} | ${item.district} | ${item.province} | ${item.zipcode}`
      );
      this.filteredOptions = newArr;
      // // // console.log(this.filteredOptions);
    }
  }

  setValue(value): void {
    // // // console.log(value);
    if (value) {
      value = value.replace(/\s/g, '');
      const valueArr = value.split('|');
      if (valueArr.length === 4) {
        this.carTypeForm.controls['customer_area'].setValue(valueArr[0]);
        this.carTypeForm.controls['customer_district'].setValue(valueArr[1]);
        this.carTypeForm.controls['customer_province'].setValue(valueArr[2]);
        this.carTypeForm.controls['customer_zipcode'].setValue(valueArr[3]);
      }
    }
  }

  dontHaveCheck(e) {
    this.creditCheck = false;
    this.carCheck = false;
    this.homeCheck = false;

    this.moneyChangerForm.patchValue({
      customer_credit: 'ไม่มี',
      customer_car: 'ไม่มี',
      customer_home: 'ไม่มี',
    });
    this.moneyChangerForm.get('customer_credit').updateValueAndValidity();
    this.moneyChangerForm.get('customer_car').updateValueAndValidity();
    this.moneyChangerForm.get('customer_home').updateValueAndValidity();
  }

  checkLoan(field, credit, car, home) {
    if (credit || car || home) {
      this.threeYearCheck = false;
    } else if (!credit && !car && !home) {
      this.threeYearCheck = true;
      this.dontHaveCheck(true);
      // if (!credit && !car && !home) {
      // }
    }
  }

  checkValidator(field, value) {
    if (value) {
      this.moneyChangerForm.get(field).setValue('');
      this.moneyChangerForm.get(field).setValidators(Validators.required);
      this.moneyChangerForm.get(field).markAsDirty();
    } else {
      this.moneyChangerForm.get(field).setValue('');
      this.moneyChangerForm.get(field).clearValidators();
      this.moneyChangerForm.get(field).markAsPristine();
    }

    this.moneyChangerForm.get(field).updateValueAndValidity();
  }

  redirect() {
    if (this.haveCarRegisBook) {
      this.setMoneyChangerBody();
      window.open(
        `/car-loan/money-changer/result-money-changer?bodyKey=${this.body}&car_logo=${this.car_logo}&age_length=${this.carTypeForm.value.customer_age}`,
        '_self'
      );
    } else {
      this.setRefinanceBody();
      window.open(
        `/car-loan/refinance/result-refinance?bodyKey=${this.body}&car_logo=${this.car_logo}&age_length=${this.carTypeForm.value.customer_age}&previous_per_month=${this.refinanceForm.value.monthly_installment}`,
        '_self'
      );
    }
  }

  getAge() {
    let age;
    switch (this.carTypeForm.value.customer_age) {
      case '20 - 35 ปี': {
        age = '20';
        break;
      }
      case '36 - 45 ปี': {
        age = '36';
        break;
      }
      case '46 - 55 ปี': {
        age = '46';
        break;
      }
      case '56 - 65 ปี': {
        age = '56';
        break;
      }
      case '66 ปี ขึ้นไป': {
        age = '66';
        break;
      }
    }
    return age;
  }

  setRefinanceBody() {
    this.isLoading = true;
    let age;

    const body = {
      car_brand: this.carModelForm.value.car_brand,
      car_model: this.carModelForm.value.car_model,
      car_year: this.carModelForm.value.car_year,
      car_gear: this.carModelForm.value.car_gear,
      car_engine: this.carModelForm.value.car_engine,
      car_class: this.carModelForm.value.car_class,
      car_type: this.carModelForm.value.RegisterType,
      // customer_age: this.getAge(),
      customer_province: this.carTypeForm.value.customer_province,
      customer_zipcode: this.carTypeForm.value.customer_zipcode,

      customer_have_overdue: 'ไม่มี',

      previous_bankname: this.refinanceForm.value.previous_bankname,
      // monthly_installment: this.refinanceForm.value.monthly_installment,
      liabilities_amount: this.refinanceForm.value.liabilities_amount,
    };

    let bodyKey = JSON.stringify(body);
    this.body = bodyKey;
  }

  setMoneyChangerBody() {
    this.isLoading = true;

    let body = {
      car_brand: this.carModelForm.value.car_brand,
      car_model: this.carModelForm.value.car_model,
      car_year: this.carModelForm.value.car_year,
      car_gear: this.carModelForm.value.car_gear,
      car_engine: this.carModelForm.value.car_engine,
      car_class: this.carModelForm.value.car_class,
      car_type: this.carModelForm.value.RegisterType,
      customer_age: this.getAge(),
      customer_job: this.carTypeForm.value.job,
      customer_income_amount: this.carTypeForm.value.customer_income_amount,
      customer_province: this.carTypeForm.value.customer_province,
      customer_zipcode: this.carTypeForm.value.customer_zipcode,
      loan_amount: this.moneyChangerForm.value.loan_amount,
      have_burden: [
        {
          detail: 'ไม่มี',
          checkbox: this.threeYearCheck ? 1 : 0,
          value: '',
        },
        {
          detail: 'บัตรเครดิต',
          checkbox: this.creditCheck ? 1 : 0,
          value: this.creditCheck
            ? this.moneyChangerForm.value.customer_credit
            : '',
        },
        {
          detail: 'รถยนต์',
          checkbox: this.carCheck ? 1 : 0,
          value: this.carCheck ? this.moneyChangerForm.value.customer_car : '',
        },
        {
          detail: 'บ้าน/ที่อยู่อาศัย',
          checkbox: this.homeCheck ? 1 : 0,
          value: this.homeCheck
            ? this.moneyChangerForm.value.customer_home
            : '',
        },
      ],
    };

    console.log(body);

    let bodyKey = JSON.stringify(body);
    this.body = bodyKey;
  }

  getProfile() {
    this.profileService
      .getProfile()
      .pipe(
        map((profile) => {
          if (profile.status.code === 1000) {
            this.profileList = profile.data[0];
            // console.log(profile.data[0]);
            if (this.profileList.address_delivery) {
              console.log(this.profileList.address_delivery);
              this.checkAge();
            }
          }
        }),
        catchError((err) => {
          alert(err.error.message);
          return of(false);
        })
      )
      .subscribe();
  }

  checkAge() {
    let age = this.calculateAge(new Date(this.profileList.birthdate));
    let age_range = '';
    if (age >= 20 && age <= 35) {
      age_range = '20 - 35 ปี';
    } else if (age >= 36 && age <= 45) {
      age_range = '36 - 45 ปี';
    } else if (age >= 46 && age <= 55) {
      age_range = '46 - 55 ปี';
    } else if (age >= 56 && age <= 65) {
      age_range = '56 - 65 ปี';
    } else if (age > 65) {
      age_range = '66 ปี ขึ้นไป';
    }

    this.patchValueFormProfile(this.profileList, age_range);
  }

  calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  patchValueFormProfile(profile, age) {
    let pv = '';
    if (
      profile.area_delivery &&
      profile.district_delivery &&
      profile.province_delivery &&
      profile.zipcode_delivery
    ) {
      pv = `${profile.area_delivery} | ${profile.district_delivery} | ${profile.province_delivery} | ${profile.zipcode_delivery}`;
    } else {
      pv = '';
    }

    this.carTypeForm.patchValue({
      customer_age: age,
      job: profile.job ? profile.job : '',
      customer_address: profile.address_delivery
        ? profile.address_delivery
        : '',
      customer_income_amount: profile.income_amount
        ? profile.income_amount
        : '',
      customer_area: profile.area_delivery ? profile.area_delivery : '',
      customer_district: profile.district_delivery
        ? profile.district_delivery
        : '',
      customer_province: profile.province_delivery
        ? profile.province_delivery
        : '',
      customer_zipcode: profile.zipcode_delivery
        ? profile.zipcode_delivery
        : '',
      province: pv,
    });

    this.carTypeForm.get('customer_age').updateValueAndValidity();
    this.carTypeForm.get('job').updateValueAndValidity();
    this.carTypeForm.get('customer_income_amount').updateValueAndValidity();
    this.carTypeForm.get('customer_address').updateValueAndValidity();
    this.carTypeForm.get('customer_area').updateValueAndValidity();
    this.carTypeForm.get('customer_district').updateValueAndValidity();
    this.carTypeForm.get('customer_province').updateValueAndValidity();
    this.carTypeForm.get('customer_zipcode').updateValueAndValidity();
    this.carTypeForm.get('province').updateValueAndValidity();
  }

  openModalLogin() {
    const loginDialogConfig = new MatDialogConfig();
    loginDialogConfig.disableClose = true;
    loginDialogConfig.id = 'modalConfirm';
    loginDialogConfig.width = '500px';
    loginDialogConfig.autoFocus = false;

    const loginModal = this.matDialog.open(
      ModalLoginComponent,
      loginDialogConfig
    );
    loginModal
      .afterClosed()
      .pipe(
        map((res) => {
          if (res.status) {
            this.router.navigate(['login'], {
              state: {
                redirect: this.router.url,
              },
              queryParams: {
                redirect: '/car-loan/money-changer',
              },
            });
          } else {
            this.router.navigate(['/']);
          }
        })
      )
      .subscribe();
  }
}
