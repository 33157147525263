import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  constructor() { }

  @HostBinding('class.fixed-top') fixedTop: boolean;
  @HostBinding('class.sticky-top') stickyTop: boolean;
  @HostBinding('class.bg-primary-blue-linear') bgDefault: boolean;
  @HostBinding('class.bg-transparent') bgTransparent: boolean;

  @HostListener('window:scroll') onScroll() {

    if (window.scrollY >= 10) {
      this.bgDefault = true;
    } else {
      this.bgDefault = false;
    }
  }



}
