<div>
  <div class="text-right mb-1">
    <button
    (click)="close()"
    nz-button
    nzType="primary"
    style="background-color: white; color: black; border: none"
    nzShape="circle"
    class="close-icon"
  >
    <span nz-icon nzType="close" nzTheme="outline"></span>
  </button>
  </div>
  <div class="image-container">
     
    <a (click)="onAdvertisingClick()" [href]="advertiseLink" target="_blank">
      <img
        [src]="fileLocation"
        style="max-width: 100%"
        [width]="width"
        height="auto"
      />
    </a>

    
  </div>
</div>
