<div class="container">
    <div class="row justify-content-center px-3 py-4">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <p class="text-center otp-header my-4">{{ headerText }}</p>
            <div *ngIf="registerType === 'email'" class="my-4">
                <p class="text-center otp-text">เราส่งรหัสตรวจสอบการยืนยัน 6 หลักไปที่</p>
                <p class="text-center otp-email">{{ otpForm.value.emailorphone }}</p>
                <p class="text-center otp-ref-code">Ref Code: {{ refCode }}</p>
            </div>
            <div *ngIf="registerType === 'phone'" class="my-4">
                <p class="text-center otp-text">รหัส 6 หลักได้ถูกส่งไปที่
                    <span style="color: #000;">{{ otpForm.value.emailorphone }}</span>
                </p>
                <p class="text-center otp-ref-code">Ref Code: {{ refCode }}</p>
            </div>
            <div [formGroup]="otpForm">
                <div class="d-flex justify-content-center">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config">
                    </ng-otp-input>
                </div>
            </div>

            <div *ngIf="isError" class="d-flex mt-3 justify-content-center">
                <p class="text-danger">{{ errMsg }}</p>
            </div>

            <p *ngIf="!repeatOtp" class="text-center count-down-text my-4">
                ขอรหัสใหม่อีกครั้งได้ใน
                <span class="count-down">
                    <countdown [config]="{ leftTime: 300, format: 'mm:ss' }" (event)="handleEvent($event)"></countdown>
                </span>
                นาที
            </p>
            <div *ngIf="repeatOtp" class="d-flex justify-content-center my-4">
                <a (click)="callOtp()">
                    <i class="bi bi-arrow-clockwise" style="color: #03428E; font-size: 23px; margin-right: 10px;"></i>
                </a>
                <a (click)="callOtp()" class="text-center">
                    <span class="text-secondary">
                        <u>ขอรหัส OTP ใหม่</u>
                    </span>
                </a>
            </div>

            <div class="d-flex justify-content-center">
                <button *ngIf="otpForm.valid" (click)="next('password')" class="btn btn-next">ถัดไป</button>
                <button *ngIf="!otpForm.valid" class="btn btn-next disabled">ถัดไป</button>
            </div>


        </div>
    </div>
</div>

<div *ngIf="isLoading">
    <div
        style="position: fixed; z-index: 900; width: 100vw; height: 100vh; opacity: 80; top: 0; left: 0; background: #e8e8e880;">
        <nz-spin nzSimple [nzSize]="'large'"
            style="z-index: 1000; padding: 400px 50px; margin: 20px 0; text-align: center;"></nz-spin>
    </div>
</div>