import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-create-success',
  templateUrl: './modal-create-success.component.html',
  styleUrls: ['./modal-create-success.component.scss']
})
export class ModalCreateSuccessComponent implements OnInit {
  modalData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalCreateSuccessComponent>,
  ) {
    this.modalData = data;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close(false);
  }

}
