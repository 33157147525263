import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'carfinn-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  calculationForm: FormGroup;
  isCal: boolean = false

  loanTypeSelect = [
    'สินเชื่อจำนำเล่ม',
    'สินเชื่อรีไฟแนนซ์',
    'สินเชื่อรถมือหนึ่ง',
    'สินเชื่อซื้อ-ขายรถยนต์',
  ];
  financePeriod = ["1", "2", "3", "4", "5", "6", "7"];
  numberOfInstallmentSelect = [24, 36, 48, 60, 72, 84];
  interest_price: number = 0;

  formatterPrice = (value: number): string =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  constructor(
    private fb: FormBuilder
  ) {
    this.calculationForm = fb.group({
      loan_type: ['', Validators.required],
      principle: ['', Validators.required],
      numberOfInstallment: ['', Validators.required],
      rate: ['', Validators.required],
    })
  }

  ngOnInit(): void {
  }

  onCalculate(value) {
    let total_interest = value.principle * (value.rate / 100) * (value.numberOfInstallment / 12);
    let total_price = value.principle + total_interest;

    this.isCal = true;
    this.interest_price = total_price / value.numberOfInstallment;
  }

  clearCal() {
    this.isCal = false;
    this.calculationForm.reset();
    this.calculationForm.controls['principle'].setValue('');
    this.calculationForm.controls['rate'].setValue('');
  }

}
