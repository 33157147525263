import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'carfinn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  panelOpenState = false;
  currentUrl: string;
  constructor(private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {}

  location() {
    window.open(
      'https://www.google.com/maps/dir//CARFINN+INTERNATIONAL+CO.,+LTD.+2158+Sukhumvit+Road+Bang+Chak,+Phra+Khanong+Bangkok+10260/@13.6917576,100.6075396,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x30e29facd451bfeb:0x1c60fe16f10f1256!2m2!1d100.6075396!2d13.6917576',
      '_blank'
    );
  }

  facebook() {
    window.open('https://www.facebook.com/carfiinn/', '_blank');
  }

  goToSection(sectionId: string) {
    this.currentUrl = this.router.url;

    const element = document.getElementById(sectionId);

    if (this.currentUrl !== '/') {
      this.router.navigate(['/'], { fragment: sectionId });
    }

    if (element) {
      const offset = element.offsetTop - window.innerHeight * 0.1;
      this.renderer.setProperty(document.documentElement, 'scrollTop', offset);
    }
  }
}
