<div class="container-fluid main-bg px-0">
  <carfinn-navbar-transparent></carfinn-navbar-transparent>
  <div class="main-content py-5 secNav" >
    <p class="text-center header mt-5">404</p>
    <p class="text-center sub-header">
      <span>Oops!</span> it seems you follow backlink
    </p>
    <div class="d-flex justify-content-center">
      <button [routerLink]="'/'" class="btn btn-blue-outline">
        <i class="bi bi-arrow-left"></i>
        Back To Home
      </button>
    </div>
  </div>


  <div class="bg-footer">
    <div class="d-flex justify-content-center">
      <img src="assets/404/mr-carfin.png" class="img-fluid img-center" alt="mr-carfinn">
    </div>
    <div class="shape-bottom"></div>
  </div>
</div>
