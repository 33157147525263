<div *ngIf="carTypeForm && carModelForm && finalForm">
  <div class="col-12 d-flex justify-content-center" style="height: auto">
    <nz-carousel
      [nzEffect]="effect"
      [nzEnableSwipe]="false"
      [nzDots]="false"
      style="height: auto; width: 520px"
    >
      <div nz-carousel-content *ngFor="let source of sources; let i = index">
        <div class="card">
          <div class="card-header text-center cover">
            <div *ngIf="i === 0">ข้อมูลรถยนต์</div>
            <div *ngIf="i !== 0">ข้อมูลผู้กู้</div>
          </div>
          <div class="container pt-4">
            <form
              nz-form
              nzLayout="vertical"
              *ngIf="source === 'carModel'"
              [formGroup]="carModelForm"
            >
              <div class="row">
                <div class="col-lg-5 col-12">
                  <nz-form-item>
                    <nz-form-label> ยี่ห้อรถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ" [nzSpan]="24">
                      <nz-select
                        id="car_brand"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="โปรดระบุยี่ห้อของรถ"
                        nzSize="large"
                        formControlName="car_brand"
                        (ngModelChange)="getCarModel($event)"
                      >
                        <nz-option
                          *ngFor="let cb of carBrandSelect"
                          [nzLabel]="cb.car_brand"
                          [nzValue]="cb.car_brand"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col-lg-7 col-12">
                  <nz-form-item>
                    <nz-form-label> รุ่นรถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <nz-select
                        id="car_model"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="โปรดระบุรุ่นของรถ"
                        nzSize="large"
                        formControlName="car_model"
                        (ngModelChange)="getCarYear(carModelForm.value)"
                      >
                        <nz-option
                          *ngFor="let cm of carModelSelect"
                          [nzLabel]="cm.car_model"
                          [nzValue]="cm.car_model"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5 col-12">
                  <nz-form-item>
                    <nz-form-label> ปีรถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ" style="width: 100%">
                      <nz-select
                        id="car_year"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="โปรดระบุปีของรถ"
                        nzSize="large"
                        formControlName="car_year"
                        (ngModelChange)="getCarGear(carModelForm.value)"
                      >
                        <nz-option
                          *ngFor="let cy of carYearSelect"
                          [nzLabel]="cy.car_year"
                          [nzValue]="cy.car_year"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col-lg-7 col-12">
                  <nz-form-item>
                    <nz-form-label> เกียร์รถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <nz-select
                        id="car_year"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="โปรดระบุเกียร์รถยนต์"
                        nzSize="large"
                        formControlName="car_gear"
                        (ngModelChange)="getCarEngine(carModelForm.value)"
                      >
                        <nz-option
                          *ngFor="let cg of carGearSelect"
                          [nzLabel]="cg.car_gear"
                          [nzValue]="cg.car_gear"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5 col-12">
                  <nz-form-item *ngIf="carEngineCheck">
                    <nz-form-label> เครื่องยนต์รถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <nz-select
                        id="car_class"
                        nzPlaceHolder="โปรดระบุเครื่องยนต์รถยนต์"
                        nzSize="large"
                        formControlName="car_engine"
                        (ngModelChange)="getCarClass(carModelForm.value)"
                      >
                        <nz-option
                          *ngFor="let ce of carEngineSelect"
                          [nzLabel]="ce.car_engine"
                          [nzValue]="ce.car_engine"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col-lg-7 col-12">
                  <nz-form-item *ngIf="carClassCheck">
                    <nz-form-label> รุ่นย่อยรถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <nz-select
                        id="car_class"
                        nzPlaceHolder="โปรดระบุรุ่นย่อยของรถ"
                        nzSize="large"
                        formControlName="car_class"
                      >
                        <nz-option
                          *ngFor="let cc of carClassSelect"
                          [nzLabel]="cc.car_class"
                          [nzValue]="cc.car_class"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <nz-form-item>
                    <nz-form-label> ประเภทรถยนต์ </nz-form-label>

                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <nz-select
                        id="car_type"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="โปรดระบุประเภท"
                        nzSize="large"
                        formControlName="RegisterType"
                      >
                        <nz-option
                          *ngFor="let ct of carTypeSelect"
                          [nzLabel]="ct.car_type"
                          [nzValue]="ct.car_type"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </form>

            <form
              nz-form
              nzLayout="vertical"
              *ngIf="source === 'carType'"
              [formGroup]="carTypeForm"
            >
              <div class="row">
                <div class="col-lg-6 col-12">
                  <nz-form-item>
                    <nz-form-label>จังหวัด</nz-form-label>
                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <nz-select
                        nzSize="large"
                        style="width: 100%"
                        nzPlaceHolder="กรุณาเลือก"
                        formControlName="customer_province"
                        [nzDropdownMatchSelectWidth]="false"
                      >
                        <nz-option
                          *ngFor="let province of provinceSelect"
                          [nzLabel]="province"
                          [nzValue]="province"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col">
                  <nz-form-item>
                    <nz-form-label>รหัสไปรษณีย์</nz-form-label>
                    <nz-form-control [nzErrorTip]="zipcodeErrorTemplate">
                      <input
                        nzSize="large"
                        nz-input
                        maxlength="5"
                        formControlName="customer_zipcode"
                      />
                      <ng-template #zipcodeErrorTemplate let-control>
                        <ng-container *ngIf="control.hasError('required')"
                          >โปรดระบุ</ng-container
                        >
                        <ng-container *ngIf="control.hasError('pattern')"
                          >รหัสไปรษณีย์ต้องเป็นตัวเลข 5 หลัก</ng-container
                        >
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <nz-form-item>
                    <nz-form-label> อายุผู้กู้ </nz-form-label>
                    <nz-select
                      nzSize="large"
                      style="width: 100%"
                      nzPlaceHolder="กรุณาเลือก"
                      formControlName="customer_age"
                    >
                      <nz-option
                        *ngFor="let age of customerAgeSelector"
                        [nzLabel]="age"
                        [nzValue]="age"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-item>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-form-label> อาชีพ </nz-form-label>
                      <nz-select
                        nzSize="large"
                        style="width: 100%"
                        nzPlaceHolder="กรุณาเลือก"
                        formControlName="job"
                      >
                        <nz-option
                          *ngFor="let job of jobSelect"
                          [nzLabel]="job"
                          [nzValue]="job"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <nz-form-item>
                    <nz-form-label> รายได้รวมต่อเดือน </nz-form-label>
                    <nz-form-control>
                      <nz-select
                        formControlName="customer_income_amount"
                        style="width: 100%"
                        nzSize="large"
                      >
                        <nz-option
                          *ngFor="let income of incomeSelect"
                          [nzLabel]="income"
                          [nzValue]="income"
                        >
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <nz-form-item>
                    <nz-form-label>
                      คุณมีเล่มทะเบียนรถ อยู่กับตัวเองไหม
                    </nz-form-label>
                    <nz-radio-group
                      [(ngModel)]="haveCarRegisBook"
                      (ngModelChange)="finalFormFactory()"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <label nz-radio [nzValue]="true">มี</label>
                      <label nz-radio [nzValue]="false">ไม่มี</label>
                    </nz-radio-group>
                  </nz-form-item>
                </div>
              </div>
            </form>
            <div *ngIf="finalForm">
              <form
                nz-form
                *ngIf="source === 'finalForm'"
                [formGroup]="finalForm"
              >
                <div *ngIf="isMoneyChangerForm()">
                  <div class="row">
                    <div class="col">
                      <!-- <p class="text-left">
                            3 ปีที่ผ่านมาเคยใช้สินเชื่ออะไรบ้าง
                          </p> -->

                      <nz-form-label [nzSpan]="24">
                        3 ปีที่ผ่านมาเคยใช้สินเชื่ออะไรบ้าง
                      </nz-form-label>
                      <nz-form-control>
                        <label
                          class="my-auto"
                          nz-checkbox
                          [(ngModel)]="threeYearCheck"
                          [ngModelOptions]="{ standalone: true }"
                          (ngModelChange)="dontHaveCheck(threeYearCheck)"
                          >ไม่มี</label
                        >

                        <div class="d-flex justify-content-between form-check">
                          <label
                            class="my-auto"
                            nz-checkbox
                            [(ngModel)]="creditCheck"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              checkLoan(
                                'customer_credit',
                                creditCheck,
                                carCheck,
                                homeCheck
                              )
                            "
                            (ngModelChange)="
                              checkValidator('customer_credit', creditCheck)
                            "
                          >
                            บัตรเครดิต
                          </label>
                          <nz-form-item *ngIf="creditCheck" style="width: 57%">
                            <nz-form-control nzErrorTip="โปรดระบุ">
                              <small class="text-grey"
                                >สถานะสามปีที่ผ่านมา</small
                              >
                              <nz-select
                                nzPlaceHolder="โปรดระบุสินเชื่อ"
                                formControlName="customer_credit"
                              >
                                <nz-option
                                  *ngFor="let uc of loanSelect"
                                  [nzLabel]="uc"
                                  [nzValue]="uc"
                                >
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div class="d-flex justify-content-between form-check">
                          <label
                            class="my-auto"
                            nz-checkbox
                            [(ngModel)]="carCheck"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              checkLoan(
                                'customer_car',
                                creditCheck,
                                carCheck,
                                homeCheck
                              )
                            "
                            (ngModelChange)="
                              checkValidator('customer_car', carCheck)
                            "
                          >
                            รถยนต์
                          </label>
                          <nz-form-item *ngIf="carCheck" style="width: 57%">
                            <nz-form-control nzErrorTip="โปรดระบุ">
                              <small class="text-grey"
                                >สถานะสามปีที่ผ่านมา</small
                              >
                              <nz-select
                                nzPlaceHolder="โปรดระบุสินเชื่อ"
                                formControlName="customer_car"
                              >
                                <nz-option
                                  *ngFor="let uc of loanSelect"
                                  [nzLabel]="uc"
                                  [nzValue]="uc"
                                >
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div class="d-flex justify-content-between form-check">
                          <label
                            class="my-auto"
                            nz-checkbox
                            [(ngModel)]="homeCheck"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="
                              checkLoan(
                                'customer_home',
                                creditCheck,
                                carCheck,
                                homeCheck
                              )
                            "
                            (ngModelChange)="
                              checkValidator('customer_home', homeCheck)
                            "
                          >
                            บ้าน/ที่อยู่อาศัย
                          </label>
                          <nz-form-item *ngIf="homeCheck" style="width: 57%">
                            <nz-form-control nzErrorTip="โปรดระบุ">
                              <small class="text-grey"
                                >สถานะสามปีที่ผ่านมา</small
                              >
                              <nz-select
                                nzPlaceHolder="โปรดระบุสินเชื่อ"
                                formControlName="customer_home"
                              >
                                <nz-option
                                  *ngFor="let uc of loanSelect"
                                  [nzLabel]="uc"
                                  [nzValue]="uc"
                                >
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div class="row">
                          <div class="col">
                            <nz-form-item>
                              <nz-form-label [nzSpan]="24">
                                ยอดที่อยากกู้
                              </nz-form-label>
                              <nz-form-control
                                [nzSpan]="24"
                                nzErrorTip="โปรดระบุ"
                              >
                                <nz-input-number
                                  [nzFormatter]="formatterPrice"
                                  formControlName="loan_amount"
                                  id="loan_amount"
                                >
                                </nz-input-number>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </nz-form-control>
                    </div>
                  </div>
                </div>

                <div *ngIf="isRefinanceForm()">
                  <div class="row">
                    <div class="col">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                          สถาบันการเงินที่ผ่อน
                        </nz-form-label>
                        <nz-form-control nzErrorTip="โปรดระบุ" [nzSpan]="24">
                          <nz-select
                            formControlName="previous_bankname"
                            nzPlaceHolder="กรุณาเลือก"
                            style="width: 100%"
                          >
                            <nz-option
                              *ngFor="let bank of bankList$ | async"
                              [nzLabel]="bank.bankname"
                              [nzValue]="bank.bankname"
                            >
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                          ค่างวดเดิมต่อเดือน
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="โปรดระบุ">
                          <nz-input-number
                            [nzFormatter]="formatterPrice"
                            formControlName="monthly_installment"
                            id="monthly_installment"
                          ></nz-input-number>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                          ยอดหนี้คงเหลือ
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="โปรดระบุ">
                          <nz-input-number
                            [nzFormatter]="formatterPrice"
                            formControlName="liabilities_amount"
                            id="liabilities_amount"
                          ></nz-input-number>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="source === 'finalForm'">
              <div class="row mt-3">
                <div class="col d-flex justify-content-center">
                  <button
                    (click)="redirect()"
                    class="btn btn-promotion"
                    [ngClass]="{ disabled: !finalForm.valid }"
                  >
                    ส่งข้อมูล
                  </button>
                </div>
              </div>
            </div>
            <div class="my-3 row">
              <div class="col-4">
                <button
                  nz-button
                  aria-label="back"
                  nzType="primary"
                  (click)="pre()"
                  [ngClass]="{ hidden: i === 0 }"
                  [disabled]="checkPreDisable(i)"
                  nzShape="circle"
                >
                  <span nz-icon nzType="left"></span>
                </button>
              </div>

              <div class="col-4 text-center">
                {{ i + 1 }} / {{ sources.length }}
              </div>

              <div class="col-4 d-flex justify-content-end">
                <button
                  nz-button
                  aria-label="next"
                  nzType="primary"
                  (click)="next()"
                  [disabled]="checkDisable(i)"
                  [ngClass]="{ hidden: i + 1 === sources.length }"
                  nzShape="circle"
                >
                  <span nz-icon nzType="right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-carousel>
  </div>
</div>
