<!-- <div mat-dialog-title class="dialog-header">
    <div class="header" style="position: relative;">
        <h5 class="text-left">
            ตั้งค่าการใช้งานคุกกี้
        </h5>
        <div class="close-btn">
            <a [mat-dialog-close]="true" (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div> -->

<mat-dialog-content class="mat-typography dialog-centent">
    <div class="container py-4">
        <div class="header" style="position: relative;">
            <p class="text-left text-header">
                ตั้งค่าการใช้งานคุกกี้
            </p>
            <div class="close-btn">
                <a [mat-dialog-close]="true" (click)="closeModal()">
                    <i class="bi bi-x-lg"></i>
                </a>
            </div>
        </div>
        <div class="message-wrapper">
            <p class="text-content">
                เมื่อคุณเข้าชมเว็บไซต์ใดก็ตาม ไซต์นั้นอาจจัดเก็บหรือดึงข้อมูลจากเบราว์เซอร์ของคุณ
                ส่วนใหญ่แล้วอยู่ในรูปแบบของคุกกี้ ข้อมูลนี้อาจเกี่ยวกับคุณ การตั้งค่าของคุณ อุปกรณ์ของคุณ
                หรือเพื่อช่วยให้ไซต์ทำงานอย่างที่คุณต้องการ ซึ่งมักเป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้โดยตรง
                แต่ช่วยให้คุณใช้งานเว็บตามความต้องการส่วนบุคคลได้มากยิ่งขึ้น
                คุณสามารถปฏิเสธไม่ให้คุกกี้บางประเภททำงานได้ คลิกที่หัวข้อประเภทอื่นๆ
                เพื่อดูข้อมูลเพิ่มเติมและเปลี่ยนการตั้งค่าเริ่มต้นของเรา อย่างไรก็ตาม
                คุณควรทราบว่าการบล็อกคุกกี้บางประเภทอาจส่งผลต่อการใช้งานไซต์และบริการที่เรามีให้ใช้งาน
            </p>
        </div>
        <div class="accordion" id="cookiesAccordion">
            <div class="card">
                <div class="card-header" id="strictly-consent">
                    <div class="mb-0 d-flex justify-content-between">
                        <a class="btn btn-link btn-block text-left" data-toggle="collapse"
                            data-target="#strictlyCollapse" aria-expanded="false" aria-controls="strictlyCollapse">
                            คุกกี้ที่จำเป็น
                        </a>
                        <nz-switch class="m-auto" [(ngModel)]="strictlyConsent" nzDisabled="true">
                        </nz-switch>
                    </div>
                </div>

                <div id="strictlyCollapse" class="collapse" aria-labelledby="strictly-consent"
                    data-parent="#cookiesAccordion">
                    <div class="card-body">
                        คุกกี้เหล่านี้จำเป็นสำหรับการทำงานกับเว็บไซต์ และไม่สามารถปิดการใช้งานในระบบของเราได้
                        และโดยปกติแล้ว คุกกี้เหล่านี้จะถูกกำหนดไว้เพื่อตอบสนองต่อการดำเนินการร้องขอบริการของคุณ เช่น
                        การตั้งค่าความเป็นส่วนตัว การเข้าสู่ระบบ หรือการกรอกแบบฟอร์ม
                        คุณสามารถกำหนดให้เบราว์เซอร์ของคุณทำการบล็อก หรือเตือนให้คุณทราบเกี่ยวกับคุกกี้เหล่านี้
                        แต่นั่นอาจทำให้บางส่วนของไซต์ไม่สามารถทำงานได้
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="performanceCookies">
                    <div class="mb-0 d-flex justify-content-between">
                        <a class="btn btn-link btn-block text-left collapsed" data-toggle="collapse"
                            data-target="#performanceCollapse" aria-expanded="false"
                            aria-controls="performanceCollapse">
                            คุกกี้ประสิทธิภาพ
                        </a>
                        <nz-switch class="m-auto" [(ngModel)]="performanceConsent">
                        </nz-switch>
                    </div>
                </div>
                <div id="performanceCollapse" class="collapse" aria-labelledby="performanceCookies"
                    data-parent="#cookiesAccordion">
                    <div class="card-body">
                        คุกกี้เหล่านี้ช่วยเราในการนับจำนวนและที่มาของการเข้าชม
                        เพื่อให้เราสามารถวัดและปรับปรุงประสิทธิภาพไซต์ของเราได้
                        นอกจากนี้ยังช่วยให้เราทราบว่าหน้าใดได้รับความนิยมมากที่สุดและน้อยที่สุด
                        และทราบว่าผู้เยี่ยมชมเข้าดูส่วนใดบ้าง ข้อมูลทั้งหมดที่คุกกี้เหล่านี้จัดเก็บไว้จะถูกรวมไว้ด้วยกัน
                        จึงทำให้ไม่สามารถระบุตัวตนได้ หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน
                        เราจะไม่ทราบว่าคุณเข้าชมไซต์ของเราเมื่อใด
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="targetCookies">
                    <div class="mb-0 d-flex justify-content-between">
                        <a class="btn btn-link btn-block text-left collapsed disabled" data-toggle="collapse"
                            data-target="#targetCollapse" aria-expanded="false" aria-controls="targetCollapse">
                            คุกกี้กำหนดเป้าหมาย
                        </a>
                        <nz-switch class="m-auto" [(ngModel)]="targetConsent" nzDisabled="true">
                        </nz-switch>
                    </div>
                </div>
                <div id="targetCollapse" class="collapse" aria-labelledby="targetCookies"
                    data-parent="#cookiesAccordion">
                    <div class="card-body">
                        คุกกี้เหล่านี้จะถูกกำหนดผ่านไซต์ของเราโดยพาร์ทเนอร์โฆษณาของเรา
                        โดยบริษัทดังกล่าวอาจใช้คุกกี้เหล่านี้เพื่อสร้างโปรไฟล์เกี่ยวกับความสนใจของคุณ
                        และแสดงโฆษณาที่คุณสนใจบนไซต์อื่นๆ คุกกี้เหล่านี้ทำงานโดยการระบุแต่ละเบราว์เซอร์และอุปกรณ์ของคุณ
                        หากคุณไม่อนุญาตให้คุกกี้เหล่านี้ทำงาน คุณจะไม่เห็นโฆษณาที่คุณเป็นเป้าหมายบนเว็บไซต์ต่างๆ
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div class="d-flex justify-content-center">
                <button (click)="submitCookies()" class="btn btn-accept mx-3 mt-3">ยืนยัน</button>
                <button (click)="acceptAllCookies()" class="btn btn-accept-all mx-3 mt-3">อนุญาตทั้งหมด</button>
            </div>
        </div>
        <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
            <button (click)="submitCookies()" class="btn btn-accept my-2">ยืนยัน</button>
            <button (click)="acceptAllCookies()" class="btn btn-accept-all">อนุญาตทั้งหมด</button>
        </div>
    </div>
</mat-dialog-content>