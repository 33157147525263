import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-history',
  templateUrl: './company-history.component.html',
  styleUrls: ['./company-history.component.scss']
})
export class CompanyHistoryComponent implements OnInit {

  dot: number[] = [1, 2, 3]

  constructor() { }

  ngOnInit(): void {
  }

}
