<div style="overflow: hidden;">

  <carfinn-navbar-transparent></carfinn-navbar-transparent>

    <div class="container-fluid background pb-5 pt-5 secNav" style="">
        <div class="container px-0">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="card request-card py-5 px-3">
                        <div class="row d-flex justify-content-center">
                            <div class="col-12">
                                <img src="assets/logo/carfinn.png" alt="" class="img-fluid mx-auto d-block logo mb-3">

                                <p data-cy="textmain" class="text-center thank-text mt-3">
                                    <span>ขอขอบคุณที่ใช้บริการคาร์ฟินน์</span><br>
                                    <span>เราได้รับข้อมูลของท่านเรียบร้อย</span>
                                </p>

                                <p data-cy="textchild" class="text-center thank-sub-text mt-3">
                                    <span>หลังจากนี้รอทางเราติดต่อกลับภายใน 24 ชั่วโมง</span><br>
                                    <span>เพื่อดำเนินการสมัครในขั้นตอนต่อไปค่ะ</span>
                                </p>
                            </div>
                        </div>
                        <div data-cy="back_home_page" class="d-flex justify-content-center">
                            <a (click)="redirect()" class="btn btn-submit" style="color: white;">กลับสู่หน้าหลัก</a>
                        </div>

                        <img src="assets/image-logo/smiling.png" alt=""
                            class="img-fluid smile d-none d-sm-none d-md-none d-lg-block d-xl-block">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <carfinn-footer></carfinn-footer>
</div>
