import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'carfinn-carousel-pic-router',
  templateUrl: './carousel-pic-router.component.html',
  styleUrls: ['./carousel-pic-router.component.scss']
})
export class CarouselPicRouterComponent implements OnInit {
  @Input() options: OwlOptions;
  @Input() list: any[];
  @Input() width: any[];

  constructor() { }

  ngOnInit(): void {
  }

}
