<div class="bg-linear screen">
  <div>

    <carfinn-navbar-transparent></carfinn-navbar-transparent>
  </div>


  <div class="secNav" >
    <app-coming-soon-card></app-coming-soon-card>
  </div>
</div>
