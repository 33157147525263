<div mat-dialog-title class="dialog-header">
    <div class="header" style="position: relative;">
        <div style="position: absolute; right:0; margin-top: -5px;">
            <a [mat-dialog-close]="true" (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>

<mat-dialog-content class="mat-typography dialog-centent">
    <div class="container pb-4">
        <h1 class="text-center mt-2">สร้างบัญชีสำเร็จ</h1>
        <div class="d-flex justify-content-center">
            <img src="assets/logo/confirm.png" class="mx-auto logo img-fluid" alt="">
        </div>
        <div class="p-3">
            <p class="text-center sub-content">
                <span>บัญชีของคุณพร้อมใช้งานแล้ว</span>
            </p>
        </div>
    </div>
</mat-dialog-content>