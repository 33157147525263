import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor() { }

  setLocalStorage(redirectUrl: string, queryParams: any): void {
    localStorage.removeItem('redirectUrl')
    localStorage.removeItem('queryParams')
    localStorage.setItem('redirectUrl', redirectUrl)
    localStorage.setItem('queryParams', JSON.stringify(queryParams))
  }
  
  getRedirect(): { redirectUrl: string, queryParams: any } {
    const redirectUrl = localStorage.getItem('redirectUrl');
    const queryParams = JSON.parse(localStorage.getItem('queryParams'));
    // localStorage.removeItem('redirectUrl')
    // localStorage.removeItem('queryParams')
    if (redirectUrl && queryParams) {
      return { redirectUrl, queryParams };
    } else if (redirectUrl) {
      return { redirectUrl, queryParams: {} };
    } else {
      return { redirectUrl: '/', queryParams: {} };
    }
  }

  deleteRedirect(): void {
    localStorage.removeItem('redirectUrl')
    localStorage.removeItem('queryParams')
  }
}
