import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-request-success',
  templateUrl: './request-success.component.html',
  styleUrls: ['./request-success.component.scss']
})
export class RequestSuccessComponent implements OnInit {

  redirectParam

  constructor(private route: ActivatedRoute, private router: Router) { }



  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.redirectParam = params['redirect'];
    })
  }


  redirect() {
    if (this.redirectParam) {
      this.router.navigate([this.redirectParam]);
    } else {
      this.router.navigate(['/']);
    }
  }
}
