<div class="container-fluid background p-0">

  <carfinn-navbar-transparent></carfinn-navbar-transparent>

  <div class="container pt-5 py-3 secNav" >

    <div class="row d-flex justify-content-center">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div *ngIf="state === 'initial'" class="login-section">
          <div class="row d-flex justify-content-center" style="height: 100%;">
            <div class="col-lg-8 col-md-12 col-sm-12">
              <div class="main-section p-3">
                <p class="text-color text-center text-header">ลืมรหัสผ่าน
                </p>
                <p class="text-center content">
                  กรุณาเลือกและระบุอีเมลหรือเบอร์โทรศัพท์ที่ท่านใช้ในการสมัคร<br>
                  <span class="sub-content">เพื่อรับรหัสในการตั้งค่ารหัสผ่านใหม่</span>
                </p>

                <div class="input-section">
                  <nz-alert *ngIf="isError" class="ml-4 mb-3" nzType="error" [nzMessage]="errorMessage"
                    nzShowIcon></nz-alert>

                  <form [formGroup]="forgetPasswordForm" nz-form>

                    <nz-radio-group formControlName="type" (ngModelChange)="checkType(forgetPasswordForm.value.type)">
                      <label nz-radio nzValue="phone">
                        <nz-form-item style="width: 100%;">
                          <nz-form-control [nzErrorTip]="errorPhoneMessage">
                            <label for="phone" [ngClass]="{'active-text': forgetPasswordForm.value.type === 'phone'}"
                              class="form-label">เบอร์โทรศัพท์</label>
                            <nz-input-group>
                              <input formControlName="phone" id="phone" nz-input nzSize="large"
                                [ngClass]="{'active': forgetPasswordForm.value.type === 'phone'}" autocomplete="off"
                                style="border-radius: 5px;" (input)="validatePhone(forgetPasswordForm.get('phone'))" />
                            </nz-input-group>
                          </nz-form-control>
                        </nz-form-item>
                      </label>

                      <label nz-radio nzValue="email">
                        <nz-form-item style="width: 100%;">
                          <nz-form-control [nzErrorTip]="errorEmailMessage">
                            <label for="email" [ngClass]="{'active-text': forgetPasswordForm.value.type === 'email'}"
                              class="form-label">อีเมล</label>
                            <nz-input-group>
                              <input formControlName="email" id="email" nz-input nzSize="large"
                                [ngClass]="{'active': forgetPasswordForm.value.type === 'email'}" autocomplete="off"
                                style="border-radius: 5px;" (input)="validateEmail(forgetPasswordForm.get('email'))" />
                            </nz-input-group>
                          </nz-form-control>
                        </nz-form-item>
                      </label>
                    </nz-radio-group>
                  </form>
                </div>
                <div class="btn-section d-flex justify-content-center">
                  <button *ngIf="forgetPasswordForm.valid" (click)="submit(forgetPasswordForm.value)"
                    class="btn carfinn-btn-blue">
                    ส่งข้อมูล
                  </button>
                  <button *ngIf="!forgetPasswordForm.valid" class="btn carfinn-btn-blue disabled">
                    ส่งข้อมูล
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="state === 'request-otp'" class="login-section">
          <div style="height: 100%; padding: 10% 0;">
            <form [formGroup]="otpForm">
              <carfinn-otp-input [otpForm]="otpForm" registerType="phone" (nextStep)="state = $event">
              </carfinn-otp-input>
            </form>
          </div>
        </div>

        <div *ngIf="state === 'check-email'" class="login-section" style="position: relative;">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-8 col-md-12 col-sm-12">
              <div class="main-section p-3">
                <p class="text-color text-center text-header">ตั้งรหัสผ่านใหม่
                </p>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-lg-10 col-md-12 col-sm-12">
              <p class="text-center text-bigger">กรุณาตรวจสอบอีเมลของคุณ</p>
              <p class="text-center text-sub-bigger">ที่อีเมล {{ forgetPasswordForm.value.email }}
                เพื่อดำเนินการต่อ</p>
            </div>
          </div>

          <div class="footer-section">
            <div class="d-flex justify-content-center">
              <p class="text-center text-footer">
                หากมีข้อสงสัยติดต่อได้ทาง <a href="" class="link">Facebook Inbox</a> หรือ
                <a href="" class="link">Line : @Carfinn</a>
                หรือ <a href="" class="tel">โทร : 094-665-5114</a> เพื่อสอบถามเพิ่มเติม
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="state === 'password'">
          <div *ngIf="userType === 'phone'">
            {{ forgetPassword(forgetPasswordForm.value) }}
          </div>
          <div class="login-section" style="position: relative;">
            <div class="row d-flex justify-content-center px-3 py-4">
              <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                <p class="text-color text-center text-header">กำหนดรหัสผ่านใหม่
                </p>
                <p class="text-center gray-text">กำหนดรหัสผ่านขั้นต่ำ 8 ตัวอักษรต้องประกอบไปด้วย</p>

                <div class="d-flex justify-content-center">
                  <div>
                    <div class="d-flex">
                      <i class="bi bi-patch-check-fill" [ngClass]="checkUppercase ? 'correct' : 'correct-icon'"></i>
                      <p class="text-center gray-text-second">
                        A-Z ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร</p>
                    </div>
                    <div class="d-flex">
                      <i class="bi bi-patch-check-fill" [ngClass]="checkLowercase ? 'correct' : 'correct-icon'"></i>
                      <p class="text-center gray-text-second">
                        a-z ตัวพิมพเล็กอย่างน้อย 1 ตัวอักษร</p>
                    </div>
                    <div class="d-flex">
                      <i class="bi bi-patch-check-fill" [ngClass]="checkNumber ? 'correct' : 'correct-icon'"></i>
                      <p class="text-center gray-text-second">
                        เลข 0-9 อย่างน้อย 1 ตัวอักษร</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center p-3">
              <div class="col-lg-8 col-md-12 col-sm-12">
                <form [formGroup]="updatePasswordForm" nz-form>
                  <nz-form-item>
                    <nz-form-control nzErrorTip="รหัสผ่านยังไม่ปลอดภัย!">
                      <label for="password">รหัสผ่าน</label>
                      <nz-input-group [nzSuffix]="suffixIconPassword" style="height: 40px; border-radius: 5px;">
                        <input formControlName="password" id="password" [type]="passwordVisible ? 'text' : 'password'"
                          nz-input nzSize="large" autocomplete="off"
                          (input)="checkInput(updatePasswordForm.value.password)" (input)="updateConfirmValidator()" />
                      </nz-input-group>
                      <ng-template #suffixIconPassword>
                        <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" nzTheme="fill"
                          (click)="passwordVisible = !passwordVisible" style="cursor: pointer;"></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-control nzErrorTip="รหัสผ่านไม่ตรงกัน!">
                      <label for="confirmPassword">ยืนยันรหัสผ่าน</label>
                      <nz-input-group [nzSuffix]="suffixIconConFirmPassword" style="height: 40px; border-radius: 5px;">
                        <input formControlName="confirm_password" id="confirmPassword"
                          [type]="confirmPasswordVisible ? 'text' : 'password'" nz-input nzSize="large"
                          autocomplete="off" />
                      </nz-input-group>
                      <ng-template #suffixIconConFirmPassword>
                        <i nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'" nzTheme="fill"
                          (click)="confirmPasswordVisible = !confirmPasswordVisible" style="cursor: pointer;"></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </form>
                <button *ngIf="updatePasswordForm.valid" (click)="updatePassword(updatePasswordForm.value)"
                  class="btn carfinn-btn-blue" style="margin-top: 25px;">
                  ยืนยันเปลี่ยนรหัสผ่าน
                </button>
                <button *ngIf="!updatePasswordForm.valid" class="btn carfinn-btn-blue disabled"
                  style="margin-top: 25px;">
                  ยืนยันเปลี่ยนรหัสผ่าน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
    <img src="assets/login-page/building.png" class="bg-image-building" alt="building">
    <img src="assets/login-page/car.png" class="bg-image-car" alt="car">
    <img src="assets/login-page/text.png" class="bg-image-text" alt="text">
    <img src="assets/login-page/home.png" class="bg-image-home" alt="home">
  </div>
</div>

<div *ngIf="isLoading">
  <div
    style="position: fixed; z-index: 900; width: 100vw; height: 100vh; opacity: 80; top: 0; left: 0; background: #e8e8e880;">
    <nz-spin nzSimple [nzSize]="'large'"
      style="z-index: 1000; padding: 400px 50px; margin: 20px 0; text-align: center;"></nz-spin>
  </div>
</div>
