<div class="flex-button">
  <button class="phone-button" (click)="tel()" nz-button>
    <span nz-icon style="margin-right: 15px;">
      <img fetchpriority="low" style="width: 40px" src="assets/icons/phone.webp" alt="phone" />
    </span>
    <div>
        <p style="margin: 0; line-height: 1;">โทรปรึกษาเรา</p>
        <p style="margin: 0; line-height: 1.2;">{{phoneNumber}}</p>
    </div>
  </button>

  <button class="line-button" (click)="lineOA()" nz-button>
    <span nz-icon style="margin-right: 12px;">
      <img fetchpriority="low" style="width: 40px" src="assets/icons/Line.webp" alt="Line" />
    </span>
    <div>
        <p style="margin: 0; line-height: 1;">สอบถามผ่าน Line</p>
        <p style="margin: 0; line-height: 1.2; text-align: start;">{{lineName}}</p>
    </div>
  </button>
</div>
