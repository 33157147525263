import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'carfinn-btn',
  templateUrl: './carfinn-btn.component.html',
  styleUrls: ['./carfinn-btn.component.scss']
})
export class CarfinnBtnComponent implements OnInit {
  @Input() label: string;
  @Input() customStyle: string;
  @Input() disabled: boolean;
  @Output() onClick = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

}
