import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class GlobalFunctionService {

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private metaTagService: Meta,
    private titleService: Title,
  ) { }

  getTypeFile(e) {
    return e.name.slice((e.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
  }

  clearTag(content) {
    return content.replace(/<\/?[^>]+(>|$)/g, "");
  }

  // for front page
  setSEO(title: string, type: string, description: string, img?: string) {
    const url = window.location.origin + window.location.pathname;
    const defaultImg = '../../../modules/assets/logo/carfinn.png';
    const defaultDes = 'สินเชื่อรถ ต้องเชื่อเรา | รถแลกเงิน, รีไฟแนนซ์, ประกันรถ, CarFinn, คาร์ฟินน์, จำนำทะเบียนรถยนต์, จำนำทะเบียน, รีไฟแนนซ์รถยนต์, รีไฟแนนซ์, สินเชื่อรถยนต์, สินเชื่อ, เครื่องคำนวณสินเชื่อรถยนต์';
    let tit = title.length > 60 ? title.slice(0, 60) + '... | Carfinn' : title + ' | Carfinn'
    let des = this.clearTag(description.replace(/&nbsp;/g, ' '));

    des = des.length > 150 ? des.slice(0, 152) + '...' : des ? des : defaultDes.slice(0, 152) + '...';

    // set canonical tag
    const head = this.doc.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);

    // set title tag
    if (title) {
      this.titleService.setTitle(tit);
    }

    // // // console.log(this.metaTagService.getTag("property='og:title'"));

    // set and update meta tag
    if (this.metaTagService.getTag("property='og:title'")) {
      // // // console.log('update');

      if (title) {
        this.metaTagService.updateTag({ property: 'og:title', content: tit });
        this.metaTagService.updateTag({ name: 'twitter:title', content: tit });
      }

      this.metaTagService.updateTag({ name: 'description', content: des });
      this.metaTagService.updateTag({ name: 'robots', content: 'index, follow' });
      this.metaTagService.updateTag({ property: 'og:description', content: des });
      this.metaTagService.updateTag({ property: 'og:url', content: url });
      this.metaTagService.updateTag({ property: 'og:type', content: type });
      this.metaTagService.updateTag({ property: 'og:image', content: img ? img : defaultImg });
      this.metaTagService.updateTag({ name: 'twitter:card', content: type });
      this.metaTagService.updateTag({ name: 'twitter:description', content: des });
      this.metaTagService.updateTag({ name: 'twitter', content: img ? img : defaultImg });
    } else {
      // // // console.log('add');
      if (title) {
        this.metaTagService.addTags([
          { property: 'og:title', content: tit },
          { name: 'twitter:title', content: tit },
        ]);
      }

      this.metaTagService.addTags([
        { name: 'description', content: des },
        { name: 'robots', content: 'index, follow' },
        { name: 'twitter:card', content: type },
        { name: 'twitter:description', content: des },
        { name: 'twitter:image', content: img ? img : defaultImg },
        { property: 'og:description', content: des },
        { property: 'og:url', content: url },
        { property: 'og:type', content: type },
        { property: 'og:image', content: img ? img : defaultImg },
      ])
    }
  }

  // for management or not show in SEO
  setTitle(title) {
    this.titleService.setTitle(title.length > 60 ? title.slice(0, 60) + '... | Carfinn' : title + ' | Carfinn');
    this.clearSEO();
    this.metaTagService.addTag({
      name: 'robots', content: 'noindex, nofollow'
    })
  }

  clearSEO() {
    let element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`);
    if (element) {
      element.remove();
    }

    this.metaTagService.removeTag("name='description'");
    this.metaTagService.removeTag("name='keywords'");
    this.metaTagService.removeTag("name='robots'");
    this.metaTagService.removeTag("property='og:description'");
    this.metaTagService.removeTag("property='og:url'");
    this.metaTagService.removeTag("property='og:type'");
    this.metaTagService.removeTag("property='og:image'");
    this.metaTagService.removeTag("property='og:site_name'");
    this.metaTagService.removeTag("property='og:title'");
  }

  addGA() {
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');      
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.GA_ID}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.GA_ID}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

}
