import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _token = new BehaviorSubject<string>(null);
  private role;
  reqHeader;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.reqHeader = new HttpHeaders({
      Authorization: `Bearer ${this.cookieService.get('token')}`,
    });
  }

  get token() {
    // // console.log(this._token);
    return this._token.asObservable();
  }

  getToken() {
    this._token.next(this.cookieService.get('token'));

    return this.cookieService.get('token');
  }

  deleteToken() {
    this.cookieService.delete('token', '/');
    // this.cookieService.deleteAll();
    this._token.next(null);
    return of(true);
  }
}
