import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

interface Status {
  code: number;
  description: string;
}

interface resValidateValue {
  status: Status;
  data: { validate: boolean };
}

interface resForgotPassword {
  status: Status;
  data: {
    forgotpass_token: string;
    exp_date: string;
  }
}

interface resUpdatePassword {
  status: Status;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class ForgetPasswordService {

  constructor(
    private http: HttpClient,
  ) { }

  validateValue(body) {
    return this.http.post<resValidateValue>(environment.URL_FRONT + `/authen/validate_value`, body);
  }

  forgotPassword(body) {
    return this.http.post<resForgotPassword>(environment.URL_FRONT + `/authen/forgot_password`, body);
  }

  validateForgotPassToken(body) {
    return this.http.post<resForgotPassword>(environment.URL_FRONT + `/authen/validate_forgotpass_token`, body);
  }

  updatePassword(body) {
    return this.http.post<resUpdatePassword>(environment.URL_FRONT + `/authen/update_password`, body);
  }
}
