import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNO'
})
export class PhoneNOPipe implements PipeTransform {

  transform(phoneNO: string): unknown {
    let phone;

    if (phoneNO) {
      switch (phoneNO.length) {
        case 0:
          phone = '-';
          break;
        case 9:
          phone = [phoneNO.slice(0, 2) + '-' + phoneNO.slice(2)];
          break;
        case 10:
          phone = [phoneNO.slice(0, 3) + '-' + phoneNO.slice(3)];
          break;
      }
    } else {
      phone = '-';
    }

    return phone;
  }
}
