<article id="company-history">
  <section class="whatCarfinn">
    <h3 class="sub-title" style="text-align: center">คาร์ฟินน์ คือ ?</h3>

    <!-- <figure class="row  my-4">
    <div class="col-12 d-flex my-2 justify-content-center">
      <img class="img-fluid" src="assets/logo/carfinn-logo-footer.png" alt="carfinn-logo" />
    </div>
  </figure> -->

    <p class="content-justify py-4" style="font-size: 20px; text-align: center">
      บริษัท คาร์ฟินน์ อินเตอร์กรุ๊ป จำกัด “คาร์ฟินน์”
      เป็นแพลตฟอร์มการค้นหาสินเชื่อรถยนต์ ครบวงจร
      ที่มีเป้าหมายในการแก้ปัญหาให้กับผู้ต้องการทำธุรกรรมทางการเงินเกี่ยวกับรถ
      ทั้งจำนำเล่ม และ รีไฟแนนซ์ เนื่องจากเล็งเห็นปัญหาที่เกิดขึ้นในตลาด
      ทั้งการตีราคาที่ไม่เป็นธรรมของเต็นท์รถดอกเบี้ย
      ที่แตกต่างกันของแต่ละไฟแนนซ์ รวมไปถึงการเก็บ “ค่าจัด”
      ตั้งแต่ก่อนสินเชื่ออนุมัติ ซึ่งเป็นปัญหากับผู้กู้เป็นอย่างมาก
    </p>
  </section>

  <hr class="d-lg-block d-md-none d-sm-none" />

  <section class="starterPoint">
    <h3 class="sub-title" style="color: #00335e">จุดเริ่มต้น</h3>
    <div
      class="row mt-4"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div
        class="col-lg-7 col-12 left-text"
        style="box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2)"
      >
        <div class="row d-flex" style="justify-content: space-between">
          <div class="line" style="width: 80%"></div>
          <div class="dot" *ngFor="let i of dot"></div>
        </div>
        <div class="text-dis">
          <h4 class="">
            คาร์ฟินน์ นำโดยคุณเกียรติศักดิ์ กีรติยากรสกุล หรือคุณเรย์
          </h4>
          <div>
            <span
              >ได้กำเนิดขึ้นเพื่อเป็น “ตัวเชื่อม” ระหว่างไฟแนนซ์
              และผู้เข้ารับบริการ ด้วยการสร้าง platform
            </span>
          </div>
          <div style="color: #0a428f">
            <span>
              ที่ทุกคนสามารถเข้าเช็คยอดจัด ตามรุ่นรถ
              เงื่อนไขและรายละเอียดของตนเองได้ฟรี
            </span>
          </div>
          <div>
            <span> รวมทั้งเป็นที่ปรึกษาด้านการจัดไฟแนนซ์รถให้กับกลุ่มคน </span>
          </div>
          <div>
            <span>
              ที่ต้องการการจัดไฟแนนซ์ที่รวดเร็ว ยอดเยอะ ผ่อนยาว
              หรือกระทั่งติดเครดิตบูโร
            </span>
          </div>
          <div>
            <span>ที่เดียวครบ จบที่คาร์ฟิน</span>
            <span style="color: #0a428f"
              >ด้วยสโลแกน “สินเชื่อรถต้องเชื่อเรา”</span
            >
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-12 right-img">
        <img
          class="img-start"
          src="assets/about-us/company-history/company-history-1.webp"
          alt="company-history-1"
        />
      </div>
    </div>
    <div class="row text-disc2" style="margin-top: 5%">
      <div class="">
        <span>ตั้งแต่มีการก่อตั้งขึ้นมา คาร์ฟินน์</span>
        <span style="color: #0a428f; font-weight: 500"
          >มียอดผู้ใช้บริการขอสินเชื่อจำนวนรวมทั้งสิ้นกว่า 200,000 ราย
        </span>
      </div>
      <div>
        <span style="font-size: 25px; font-weight: 500; color: #0a428f"
          >มียอดจัดหาอนุมัติสินเชื่อสะสมมากกว่า 4,000 ล้านบาท</span
        >
        <span>
          คิดเป็นผู้ขอสินเชื่อเฉลี่ย 4,000 รายต่อเดือน โดยใช้เทคโนโลยีทางด้าน
          Fintech
        </span>
        <span
          >และการทำตลาดออนไลน์เป็นช่องทางหลัก
          โดยไม่มีสาขาในต่างจังหวัดแม้แต่สาขาเดียว</span
        >
      </div>
    </div>
  </section>

  <section class="goal">
    <h3 class="sub-title" style="color: #00335e">สู่..ความสำเร็จ</h3>
    <div class="goal-text my-5">
      <span
        >จากความสำเร็จ และการเติบโตอย่างต่อเนื่องของคาร์ฟินน์ ในปี 2565
        คาร์ฟินน์ ได้มีคุณชูเกียรติ รุจนพรพจี ประธานเจ้าหน้าที่บริหาร บมจ.
        สบายเทคโนโลยี (SABUY) เข้าซื้อหุ้น CarFinn
        ผู้ให้บริการแพลตฟอร์มสินเชื่อรถยนต์ออนไลน์แบบครบวงจร คิดเป็น 20%
        ของจำนวนหุ้นสามัญทั้งหมดเพื่อขยายความสามารถในการทำธุรกิจ
        สู่การทำพอร์ตสินเชื่อและขยายการตลาดให้ครอบคลุมมากขึ้นกว่าเดิม</span
      >
    </div>

    <div class="row d-flex justify-content-center img-goal">
      <div class="col-12 col-lg-6">
        <img
          src="assets/about-us/company-history/image-495.webp"
          class="img-goal1"
          alt="sabuy"
        />
      </div>
      <div class="col-12 col-lg-6">
        <img
          src="assets/about-us/company-history/image-497.webp"
          class="img-goal2"
          alt="grouop"
        />
      </div>
    </div>
  </section>

  <section class="buttom">
    <div style="margin-bottom: 3rem;">
      <h3 class="sub-title" style="color: #00335e">เป้าหมายในอนาคต</h3>
      <div class="disc-butt">
        <span>โดยเป้าหมายต่อไปของคาร์ฟินน์ </span>
        <span style="color: #0a428f"
          >คือเป็นตัวช่วยเรื่องไฟแนนซ์ ที่มากกว่าแค่เพียงเรื่องรถยนต์
        </span>
        <span>
          รุกเดินหน้าขยายธุรกิจสู่ธุรกิจที่เกี่ยวข้อง ทั้งธุรกิจ
          สินเชื่อบ้านธุรกิจประกัน และพอร์ตสินเชื่อของคาร์ฟิน
          พัฒนาระบบเพิ่มเติมโดยใช้ AI ร่วมตัดสินใจในการให้สินเชื่อและทำ Credit
          scoring มุ่งหน้า
        </span>
        <div class="d-lg-none d-block mt-5"></div>
        <span>
          สู่การเป็นธุรกิจ Fintech
          ที่สามารถนำบริษัทเข้าจดทะเบียนในตลาดหลักทรัพย์
          และแข่งขันต่อยอดสู่ระดับ SEA ได้ในอนาคต
        </span>
        <div class="mt-5"></div>
        <span>
          จากเส้นทางของคาร์ฟินน์ตั้งแต่จุดเริ่มต้น จนถึงปัจจุบัน
          เป้าหมายสำคัญของบริษัทขยายกว้างขึ้น จากรถยนต์ จนไปถึงธุรกิจอื่นๆ
          แต่ความมุ่งหวังของคาร์ฟินยังคงเดิม คือการเป็นแพลตฟอร์มที่เป็น
          “ผู้ช่วย” ด้านไฟแนนซ์ครบวงจร ให้กับทุกคนที่เข้ามาติดต่อกับเรา</span
        >
      </div>
    </div>

    <div
      class="container contain-butt"
      style="border: 2px solid #0a428f; border-radius: 3px"
    >
      <div class="d-flex justify-content-center text-box py-4">
        <div class="text-buttom" style="width: fit-content">
          <p>
            ยังคงเป็นคู่ค้าที่น่าเชื่อถือ และเป็นแพลตฟอร์มที่คนเชื่อใจ
            <br />
            เพื่อเป็นผู้ช่วยและผู้ให้กับทุกคนในทุกธุรกิจที่เราเดินไป พร้อมๆ กัน
          </p>
        </div>
      </div>
    </div>
  </section>
</article>
