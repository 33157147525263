<div class="menu-button d-flex justify-content-center blink">
  <button mat-fab (click)="clickMenu()" class="contact-btn" [class.mat-elevation-z2]="!isOver"
    [class.mat-elevation-z8]="isOver" (mouseover)="isOver = true" (mouseleave)="isOver = false">
    <i *ngIf="!openMenu" class="bi bi-telephone menu-icon" style="color: black"></i>
    <i *ngIf="openMenu" class="bi bi-chevron-up menu-icon" style="color: black;"></i>

  </button>
  <div class="btn-label">
    <div (click)="clickMenu()" class="contact-label d-flex justify-content-center align-items-center">
      <span class="">ติดต่อเรา ที่นี่</span>
    </div>
  </div>
</div>

<!-- menu button -->
<div *ngIf="openMenu" class="line action-button">
  <button mat-fab class="line-btn" (click)="line()">
    <i class="bi bi-line menu-icon"></i>
  </button>
  <div class="btn-label">
    <div (click)="line()" class="line-label d-flex justify-content-center align-items-center">
      <span>คุยผ่าน Line</span>
    </div>
  </div>
</div>

<div *ngIf="openMenu" class="facebook action-button">
  <button mat-fab class="facebook-btn" (click)="facebook()">
    <i class="bi bi-messenger menu-icon"></i>
  </button>
  <div class="btn-label">
    <div (click)="facebook()" class="facebook-label d-flex justify-content-center align-items-center">
      <span class="">คุยผ่าน Facebook</span>
    </div>
  </div>
</div>

<div *ngIf="openMenu" class="tel action-button">
  <button mat-fab class="tel-btn" (click)="tel()">
    <i class="bi bi-telephone menu-icon" style="color: black"></i>
  </button>
  <div class="btn-label">
    <div (click)="tel()" class="tel-label d-flex justify-content-center align-items-center">
      <span class="">094-665-5114</span>
    </div>
  </div>
</div>