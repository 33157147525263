<div class="coming-soon-card mx-auto my-4 p-4">
<div class="topic d-flex justify-content-center">
  <img src="assets/icons/cf-logo.png" class="img-fluid logo" alt="carfinn">
</div>
<div class="text-center topic-text">
  Coming Soon
</div>

<div class="d-xxl-block d-xl-block d-lg-block d-md-block d-sm-block d-none">
  <div class="footer d-flex justify-content-center">
      <a href="https://www.facebook.com/CarfinnTH/" class="footer-logo" target="_blank" id="carfinn-youtube">
          <img class="social-icon" src="assets/social-logo/facebook.png" alt="">
      </a>
      <a href="https://www.instagram.com/carfinn_official/" class="footer-logo" target="_blank"
          id="carfinn-instagram">
          <img class="social-icon" src="assets/social-logo/instagram.png" alt="">
      </a>
      <a href="https://www.tiktok.com/@carfinn.official" class="footer-logo" target="_blank"
          id="carfinn-instagram">
          <img class="social-icon" src="assets/social-logo/tiktok.png" alt="">
      </a>
      <a href="https://lin.ee/sfqF1Vo" id="carfinn-line" class="footer-logo" target="_blank">
          <img class="social-icon" src="assets/social-logo/line.png" alt="">
      </a>
      <a href="https://www.youtube.com/@carfinn" class="footer-logo" target="_blank" id="carfinn-youtube">
          <img class="social-icon" src="assets/social-logo/youtube.png" alt="">
      </a>
  </div>
</div>
<div class="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-none d-block">
  <div class="bottom">
      <div class="footer d-flex justify-content-center">
          <a href="https://www.facebook.com/CarfinnTH/" class="footer-logo" target="_blank" id="carfinn-youtube">
              <img class="social-icon" src="assets/social-logo/facebook.png" alt="">
          </a>
          <a href="https://www.instagram.com/carfinn_official/" class="footer-logo" target="_blank"
              id="carfinn-instagram">
              <img class="social-icon" src="assets/social-logo/instagram.png" alt="">
          </a>
          <a href="https://www.tiktok.com/@carfinn.official" class="footer-logo" target="_blank"
              id="carfinn-instagram">
              <img class="social-icon" src="assets/social-logo/tiktok.png" alt="">
          </a>
          <a href="https://lin.ee/sfqF1Vo" id="carfinn-line" class="footer-logo" target="_blank">
              <img class="social-icon" src="assets/social-logo/line.png" alt="">
          </a>
          <a href="https://www.youtube.com/@carfinn" class="footer-logo" target="_blank" id="carfinn-youtube">
              <img class="social-icon" src="assets/social-logo/youtube.png" alt="">
          </a>
      </div>
  </div>
</div>
</div>
