import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'carfinn-carousel-show-pic',
  templateUrl: './carousel-show-pic.component.html',
  styleUrls: ['./carousel-show-pic.component.scss']
})
export class CarouselShowPicComponent implements OnInit {
  @Input() options: OwlOptions;
  @Input() list: any[];
  @Input() width: any[];

  constructor() { }

  ngOnInit(): void {
  }

}
