import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { UserProfileService } from 'src/app/modules/user-profile/user-profile.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginService } from 'src/app/modules/login/login.service';
import { SocialAuthService } from 'angularx-social-login';
import { RedirectService } from 'src/app/core/services/redirect.service';

@Component({
  selector: 'carfin-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  visible: boolean = false;
  path: string;
  token: string = '';
  profile: any;
  image_profile: string = '';
  redirect: string = '';

  visiblepop: boolean = false;
  confirmLogoutPop: boolean = false;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthService,
    private profileService: UserProfileService,
    private loginService: LoginService,
    private redirectService: RedirectService
  ) {
    // this.authService.token.subscribe(res => this.token = res);
    this.token = this.authService.getToken();
    this.path = this.router.url.split('/')[1];
  }

  ngOnInit(): void {
    this.redirect = new URL(location.href).searchParams.get('redirect');
    // this.getProfile();
    // // // console.log(this.authService.getToken());
    // // console.log(this.token);
    if (this.token !== '') {
      this.profileService
        .getProfile()
        .pipe(
          map((profile) => {
            // // console.log(this.token);
            // // console.log(profile);
            this.profile = profile;
            this.image_profile = this.profile.data[0].image_profile;
            if (!this.profile.data[0].user_id) {
              if (this.redirect) {
                // this.redirect
                // this.router.navigate(['/profile'], {
                //   queryParams: { redirect: this.redirect },
                // });
              } else {
                this.router.navigate(['/profile']);
              }
            }
          })
        )
        .subscribe(
          () => {},
          (err) => {
            // // console.log(err);
            // if (err.error.message === 'token not found') {
            //   this.cookieService.deleteAll();
            //   if (this.path === '') {
            //     location.reload()
            //   } else {
            //     this.router.navigate(['']);
            //   }
            // }
          }
        );
    }
  }

  getProfile() {}

  changeLanguage(lang): void {
    this.translate.use(lang);
    this.cookieService.delete('language');
    this.cookieService.set('language', lang);
  }

  open(): void {
    // this.visible = true;
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  isSelected(route: string): boolean {
    return route === this.router.url;
  }

  confirmLogout(): void {
    this.confirmLogoutPop = true;
  }

  change(value: boolean): void {
    // // console.log(value);
    this.confirmLogoutPop = false;
  }

  async logout() {
    this.authService
      .deleteToken()
      .pipe(
        map((res) => {
          // if (res) {
          if (this.path === '') {
            location.reload();
          } else {
            this.router.navigate(['']).then(() => {
              location.reload();
            });
          }
          // }
        })
      )
      .subscribe();
  }
}
