import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-money-changer',
  templateUrl: './new-money-changer.component.html',
  styleUrls: ['./new-money-changer.component.scss']
})
export class NewMoneyChangerComponent implements OnInit {

  current = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
