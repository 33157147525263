// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const URL = 'https://growsit-develop.com:5100/carfinn/v1';
// const URL_FRONT = 'https://growsit-develop.com:6100/carfinn/v1';
const URL = 'https://carfinnplus.com:2096/carfinn/v1';
const URL_FRONT = 'https://carfinn.com:2096/carfinn/v1';
const KEY = 'WEW9ZUB64GDWD7Y4HN7CEP9YJ49L8LKZ7ZJT6Q2AEAKJWIUE';

// const GA_ID = 'G-KVPNDYK710';
const GA_ID = 'G-H5KQT5ML5V';

const CRYPTO_JS_PASSPHASE = 'U2FsdGVkX19/q+PLbxeTK3kmOx1HdN2eNMIs08djoVc=';
const LINE_LIFF_ID = '1660810465-wQLdE2Ml';
const FACEBOOK_CLIENT_ID = '173987175272728';

export const environment = {
  production: true,
  URL,
  URL_FRONT,
  KEY,
  GA_ID,
  CRYPTO_JS_PASSPHASE,
  LINE_LIFF_ID,
  FACEBOOK_CLIENT_ID,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
