<div class="row justify-content-center">
  <div class="card-direc col-lg-4 col-12 mt-4 d-flex justify-content-center" *ngFor="let director of directors">
    <div>
      <div class="card-img" style="margin-left: 20px;">
        <img src={{director.img}} alt={{director.name}}>
      </div>
      <div class="card-text">
        <div class="card-name">
          <p>{{director.name}}</p>
        </div>
        <div class="discription" style="padding: 10px;">
          <p class="role">{{director.role}}
          </p>
          <span class="discript">{{director.discript}}</span>
          <p class="exp">ประสบการณ์ทํางาน</p>
          <ul class="exp-list">
            <li *ngFor="let exp of director.exp">{{exp}}</li>
          </ul>
        </div>
      </div>
    </div>

  </div>

</div>