import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../../../home/home.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalFunctionService } from 'src/app/core/services/global-function.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, of, timer } from 'rxjs';

@Component({
  selector: 'app-article-content',
  templateUrl: './article-content.component.html',
  styleUrls: ['./article-content.component.scss'],
})
export class ArticleContentComponent implements OnInit {
  path: any;
  article: any;
  articleContent: any;
  recommendArticle: any[] = [];
  _isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading$.asObservable();

  constructor(
    private router: Router,
    private homeService: HomeService,
    private sanitizer: DomSanitizer,
    private globalService: GlobalFunctionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.path = decodeURIComponent(this.router.url.split('/')[2]);
    // console.log(this.path);
    // this.getArticles();

    this.route.params.subscribe((params) => {
      if (params['articleId']) {
        this.path = params['articleId'];
        this.getArticles();
      }
    });
  }

  getArticles() {
    // this.homeService.getArticles().subscribe(atc => {
    //   atc.forEach((e, i) => {
    //     if (e.path === this.path) {
    //       this.article = e;
    //       this.globalService.setSEO(this.article.article_title, 'article', this.article.articles_content, this.article.article_img);
    //       this.sanitizerHtml();
    //     }
    //   })
    //   this.recommendArticle = atc.slice(0, 3);
    // });
    this.homeService
      .getAllArticle()
      .pipe(
        tap(() => this._isLoading$.next(true)),
        switchMap((article) => {
          if (article.status.code === 1000) {
            while (this.recommendArticle.length < 3) {
              const randomIndex = Math.floor(
                Math.random() * article.data.length
              );
              const randomValue = article.data[randomIndex];
              if (!this.recommendArticle.includes(randomValue)) {
                this.recommendArticle.push(randomValue);
              }
            }

            return this.homeService.getArticleById(this.path).pipe(
              map((at) => {
                if (at.status.code === 1000) {
                  this.article = at.data[0];
                  this.globalService.setSEO(
                    this.article.article_title,
                    'article',
                    this.article.articles_content,
                    this.article.article_img
                  );
                  this.sanitizerHtml();
                }
              })
            );
          }
        }),
        catchError((err) => {
          this.router.navigate(['/']);
          return of(false);
        })
      )
      .subscribe(
        () => {},
        () => {},
        () => {
          this._isLoading$.next(false);
        }
      );
  }

  sanitizerHtml() {
    this.articleContent = this.sanitizer.bypassSecurityTrustHtml(
      this.article.articles_content
    );
  }
}
