<div mat-dialog-title class="dialog-header">
    <div class="header" style="position: relative;">
        <div style="position: absolute; right:0; margin-top: -5px;">
            <a [mat-dialog-close]="true" (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>

<mat-dialog-content class="mat-typography">
    <div class="contaner pb-3">
        <div class="d-flex justify-content-center">
            <div class="main-text d-flex justify-content-center">
                <div class="icon">
                    <i class="bi bi-exclamation-circle"></i>
                </div>
                <p class="text-left m-auto">
                    กรุณาเข้าสู่ระบบก่อนทำรายการค้นหาโปรโมชัน
                </p>
            </div>
        </div>

        <div class="d-flex justify-content-center py-4">
            <button (click)="closeModal()" class="btn btn-submit btn-outline-secondary m-3">
            <i class="bi bi-arrow-left"></i>
                กลับสู่หน้าหลัก
            </button>
            <button (click)="login()" class="btn btn-submit btn-primary-blue m-3">
                เข้าสู่ระบบ
            </button>

        </div>
    </div>
</mat-dialog-content>