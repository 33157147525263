import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {AdvertisingDto, ResIntoAdvertise} from './dto/advertise.dto';
import {ApiResonse} from '../shared-dto/api-response.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementGatewayService {

  apiUrl: string = environment.URL_FRONT;
  path = '/advertise';

  constructor(private http: HttpClient) {
  }

  public getAdvertisement(): Observable<ApiResonse<AdvertisingDto>> {
    return this.http.get<ApiResonse<AdvertisingDto>>(`${this.apiUrl}${this.path}/get_advertisement`, {});
  }

  public intoAdvertising(advertisingId: number): Observable<ApiResonse<ResIntoAdvertise>> {
    return this.http.post<ApiResonse<ResIntoAdvertise>>(`${this.apiUrl}${this.path}/into_advertise`, { id: advertisingId });
  }


}
