import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'carfinn-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  currentRate: number = 5;

  @Input() allReview = []

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    margin: 15,
    autoplay: true,
    dots: true,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      760: { items: 2 },
      1000: { items: 4 },
    },
  };

  constructor() {}

  ngOnInit(): void {}
}
