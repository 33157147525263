  <div class="d-flex justify-content-center">
    <div class="login-box text-center">
      <p>เข้าสู่ระบบ หรือ สมัครสมาชิก
        <br />
        เพื่อเข้าถึงผลลัพธ์ที่มากขึ้นและสามารถเข้าถึงเงื่อนไขการกรองวงเงินสูงสุดและดอกเบี้ยต่ำสุด
      </p>


      <div class="d-flex justify-content-center mt-4">
        <button class="login-button" (click)="login()" nz-button>
          เข้าสู่ระบบ
        </button>

        <button class="reg-button" (click)="regis()" nz-button>
          สมัครสมาชิก
        </button>
      </div>
    </div>
  </div>
