<div class="card carfinn-card mx-auto">
    <div class="card-img-top">
        <img class="lazyload img-top" [src]="article.article_image" [alt]="article.article_title" fetchpriority="low" style="aspect-ratio: 390/211;">
    </div>
    <div class="card-body cf-body">
        <h5 class="card-title content-title">{{ article.article_title }}</h5>
        <p class="card-text content-text">
            {{ article.short_description }}
        </p>
        <div class="content-footer">
            <p (click)="goToArticle(article.path)" class="text-right readmore">
                <span class="read-text" [attr.data-cy]="'article bn ' + index">
                    อ่านต่อ
                </span>
            </p>
            <hr width="100%">
            <div class="d-flex">
                <div class="profile-pic">
                    <img class="lazyload" src="assets/icons/cf-icon.png" alt="icon" fetchpriority="low" style="aspect-ratio: 1/1;">
                </div>
                <div class="admin-name p-3">
                    <p class="text-left admin-text">โดย {{ article.writer }}</p>
                </div>
                <div class="date p-3">
                    <p class="text-left date-text">{{ article.date | dateTime:'shortNoTime' }}</p>
                </div>
            </div>
        </div>
    </div>
</div>