<mat-dialog-content class="mat-typography dialog-centent">
    <div class="close-btn">
        <a [mat-dialog-close]="true" class="text-white" (click)="closeModal()">
            <i class="bi bi-x-lg"></i>
        </a>
    </div>
    <div class="container">
        <div class="card p-3">
            <img src="assets/logo/carfinn.png" class="mx-auto mb-3 logo img-fluid" alt="">
            <div class="d-flex mx-auto mt-3">
                <img src="assets/logo/confirm-blue.png" class="check" alt="">
                <p class="text-center text-header carfinn-primary-blue-text">ทำรายการสำเร็จแล้ว</p>
            </div>
            <p class="text-center text-sub-header mb-3 carfinn-primary-blue-text">คาร์ฟินน์ได้รับข้อมูลของท่านเรียบร้อยแล้ว</p>
            <p class="text-center sub-content">
                <span>ขอบคุณสำหรับการกรอกข้อมูล เพื่อง่ายต่อการประเมิน</span><br>
                <span>(เราจะติดต่อกลับหาท่านใน 48 ชม.)</span>
            </p>

            <div class="d-flex justify-content-center">
                <button (click)="confirm()" class="btn btn-confirm">ตกลง</button>
            </div>
        </div>
    </div>
</mat-dialog-content>