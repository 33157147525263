import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgOtpInputConfig } from 'ng-otp-input';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RegisterService } from '../register.service';

@Component({
  selector: 'carfinn-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
  @Input() otpForm: FormGroup;
  @Input() registerType: string = '';
  @Output() nextStep = new EventEmitter<string>();

  headerText: string = '';
  repeatOtp: boolean = false;
  isLoading: boolean = false;
  refCode: string = '';
  isError: boolean = false;
  errMsg: string = '';

  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };

  constructor(
    private registerService: RegisterService,
  ) { }

  ngOnInit(): void {
    this.headerText = this.registerType === 'email' ? 'กรอกรหัสตรวจสอบการยืนยัน' : 'กรอกรหัส OTP';

    this.refCode = this.otpForm.value.ref;
  }

  onOtpChange(otp) {
    let body;
    // // // console.log(otp.length);
    this.isError = false;

    if (otp.length === 6) {
      this.isLoading = true;

      body = {
        type: this.registerType,
        emailorphone: this.otpForm.value.emailorphone,
        OTP: otp,
        ref: this.otpForm.value.ref
      }

      this.registerService.validateOTP(body).pipe(
        map(res => {
          if (res.status.code === 1000) {
            this.otpForm.patchValue({
              otp: otp
            });
            this.otpForm.get('otp').updateValueAndValidity();

            this.next('password');

          } else {
            // alert(res.status.description);
            this.errMsg = res.status.description;
            this.isLoading = false;
            this.isError = true;
            return of(false);
          }
        })
      ).subscribe();

    }

    // // console.log(this.otpForm);
  }

  handleEvent(e) {
    // // console.log(e);
    if (e.left == 0) {
      this.repeatOtp = true;
    }
  }

  callOtp() {
    this.repeatOtp = false;
    let body = {
      type: this.registerType,
      emailorphone: this.otpForm.value.emailorphone,
    }
    this.registerService.requestOTP(body).pipe(
      map(res => {
        if (res.status.code === 1000)
          this.otpForm.controls['ref'].setValue(res.data.ref);
        this.refCode = res.data.ref;
      }),
      catchError(err => {
        alert(err.error.message);
        return of(false)
      })
    ).subscribe();
  }

  next(value: string) {
    setTimeout(() => {
      this.isLoading = false;
      this.nextStep.emit(value);
    }, 600)
    // this.state = value
  }

}
