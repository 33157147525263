import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'carfinn-contact-button',
  templateUrl: './contact-button.component.html',
  styleUrls: ['./contact-button.component.scss']
})
export class ContactButtonComponent implements OnInit {

  @Input() linkLine: string = '';
  @Input() lineName: string = '';
  @Input() phoneNumber: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  lineOA() {
    window.open(this.linkLine, '_blank');
  }

  tel() {
    document.location.href = 'tel:0946655114';
  }

}
