<div class="container pt-5">
  <!-- desktop display -->
  <div class="d-none d-xxl-block d-xl-block d-lg-block d-md-none">
    <p class="text-center display-4" data-cy="textmain3"
      style="font-weight: 400; color: #0a428f; text-shadow: 0px 0px 0px #000">
      <span class="">{{title}}</span>
    </p>
    <h3 class="text-center mt-3 sub-header" data-cy="h8" style="color: black">
      <span> {{subtitle}} </span>
    </h3>
  </div>

  <!-- mobile display -->
  <div class="d-block d-sm-block d-md-block d-xxl-none d-xl-none d-lg-none">
    <h1 class="text-center" data-cy="textmain3" style="font-weight: 400; color: #0a428f; text-shadow: 0px 0px 0px #000">
      <span class="">{{title}}</span>
    </h1>
    <h3 class="text-center mt-3 sub-header" data-cy="h8" style="color: black">
      <span> {{subtitle}} </span>
    </h3>
  </div>

  <div class="row d-flex justify-content-center pt-3 pb-5">

    <div class="col-lg-6 col-md-12 col-sm-12 justify-content-center">
      <div class="d-flex carfinn-image " data-cy="picture3">
        <img class="lazyload img-fluid" fetchpriority="low" src="{{image}}" alt="carfinn-2" style="aspect-ratio: 106/75;" />
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12" style="padding: 0% 10px;">
      <div class="d-flex justify-content-center">

        <div id="carfinnAccordion" class="test" style="width: 90%;">
          <div data-testid="platform-item" *ngFor="let content of contentDetail; let i = index"
            class="card content-card">
            <div class="card-header" [id]="'heading' + i">
              <h2 class="mb-0">
                <button [ngClass]="{ backgroundButton: content.active }" (click)="setActive(i)"
                  class="d-flex btn collapse-btn text-left collapsed" type="button" data-toggle="collapse"
                  [attr.data-target]="'#' + 'collapse' + i" aria-expanded="false" aria-controls="collapseTwo">
                  <div style="
                  display: flex;
                  justify-content: center;
                      background-color: white;
                      border-radius: 50%;
                      min-width: 45px;
                      min-height: 40px;
                    ">
                    <!-- Icon -->
                    <p class="m-0 plus-icon" [attr.data-cy]="'drop bn '+(i+1)">
                      <span *ngIf="!content.active" nz-icon nzType="up" nzTheme="outline"></span>
                      <span *ngIf="content.active" nz-icon nzType="down" nzTheme="outline"></span>
                    </p>
                  </div>
                  <!-- Header text -->
                  <p class="text-left center-main-content" [attr.data-cy]="'propfinn '+(i+1)">
                    <span class="center-content">{{content.title1}} </span>
                    <span class="end-content" style="color: #0a428f; font-weight: 500;">{{
                      content.title }}</span>
                  </p>
                </button>
              </h2>
            </div>
            <!-- Content text -->
            <div class="card-body collapse-body" [attr.data-cy]="'text childline '+(i+1)" *ngIf="content.active">
              <p class="collapse-content" [innerHTML]="content.content">
              </p>
            </div>
            <!-- <div [id]="'collapse' + i" class="collapse" [aria-labelledby]="'heading' + i"
              data-parent="#carfinnAccordion">
            </div> -->
          </div>

          <!-- <nz-collapse *ngFor="let content of contentDetail; let i = index" style="background-color: transparent;">
            <nz-collapse-panel class="my-3" [nzHeader]="content.title1+content.title" [nzActive]="content.active"
              [ngStyle]="{ 'background-color': content.active ? '#7bd3f9' : 'transparent' }" (click)="setActive(i)"
              style="border: 1px solid #0a428f;border-radius: 5px; font-size: 20px;">
              <div class="collapse-content" [innerHTML]="content.content"></div>
            </nz-collapse-panel>
          </nz-collapse> -->
          <carfinn-contact-button
          [phoneNumber]="buttonPhoneNumber"
          [lineName]="buttonLineName"
          [linkLine]="buttonLinkLine"
        ></carfinn-contact-button>
        </div>
      </div>
    </div>
  </div>
</div>
