<div class="card-container container">
  <nz-tabset
    nzType="card"
    (nzSelectedIndexChange)="setActiveTab($event)"
    @fadeInOutAnimation
  >
    <nz-tab [nzTitle]="financeTab" data-cy="Tab Refinance">
      <app-finance></app-finance>
    </nz-tab>
    <nz-tab [nzTitle]="sale" data-cy="Tab Consignment">
      <app-sale></app-sale>
    </nz-tab>
  </nz-tabset>
</div>

<ng-template #financeTab>
  <span style="font-size: 25px">รีไฟแนนซ์ / จำนอง</span>
</ng-template>

<ng-template #sale>
  <span style="font-size: 25px">ขายฝาก</span>
</ng-template>
