<h1 class="d-none">About us</h1>
<div class="homeInfo">
  <header>
    <carfinn-navbar-transparent></carfinn-navbar-transparent>

    <section class="first-section">
      <owl-carousel-o [options]="bannerOptions">
        <ng-container *ngFor="let b of banner">
          <ng-template carouselSlide>
            <div class="picsum-img-wrapper">
              <img
                [src]="b.imageUrl"
                [id]="b.name"
                class="mx-auto d-block section-img img-fluid"
                [alt]="b.name"
              />
              <div class="image-caption">
                <span>รู้จัก.. คาร์ฟินน์</span>
              </div>
              <div class="logo">
                <img src="assets/logo/carfinn.png" />
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </section>

    <nav class="container-fluid tab--container">
      <div
        class="container d-flex justify-content-between align-items-center px-sm-4"
      >
        <h2 class="m-0 title">เกี่ยวกับคาร์ฟินน์</h2>

        <!-- lg -->
        <div class="d-none d-lg-block">
          <nz-tabset
            [nzSelectedIndex]="tabIndex"
            (nzSelectedIndexChange)="onTabIndexChange($event)"
            class="mt-4"
          >
            <nz-tab nzTitle="ประวัติความเป็นมา"></nz-tab>
            <nz-tab nzTitle="โครงสร้างองค์กร"></nz-tab>
            <nz-tab nzTitle="คณะกรรมการบริษัท"></nz-tab>
          </nz-tabset>
        </div>

        <!-- smaller than lg -->
        <div class="d-lg-none my-4">
          <a class="menu" nz-dropdown [nzDropdownMenu]="menu">
            {{ menuItem[tabIndex] }}
            <span nz-icon nzType="down"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item (click)="onTabIndexChange(0)">
                ประวัติความเป็นมา
              </li>
              <li nz-menu-item (click)="onTabIndexChange(1)">
                โครงสร้างองค์กร
              </li>
              <li nz-menu-item (click)="onTabIndexChange(2)">
                คณะกรรมการบริษัท
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </nav>
  </header>

  <main id="content--container">
    <ng-container *ngIf="tabIndex === 0">
      <div class="container py-4 px-4">
      <app-company-history></app-company-history>
      </div>
    </ng-container>
    <ng-container *ngIf="tabIndex === 1">
      <div class="container py-4 px-4">
        <app-org-chart></app-org-chart>
      </div>
    </ng-container>
    <ng-container *ngIf="tabIndex === 2">
      <div class="container py-4 px-4">
        <app-board-of-directors></app-board-of-directors>
      </div>
    </ng-container>
  </main>

  <footer>
    <carfinn-footer></carfinn-footer>
  </footer>
</div>
