import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-tab-home',
  templateUrl: './tab-home.component.html',
  styleUrls: ['./tab-home.component.scss']
})
export class TabHomeComponent implements OnInit {
  tabs = [1, 2, 3];
  activeTabIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

  setActiveTab(index: number): void {
    this.activeTabIndex = index;
  }

}
