import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-complete',
  templateUrl: './modal-complete.component.html',
  styleUrls: ['./modal-complete.component.scss'],
})
export class ModalCompleteComponent implements OnInit {
  @Input() txt: string = '';

  isVisible = false;
  isOkLoading = false;

  constructor() {}

  ngOnInit(): void {}

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    this.isOkLoading = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
