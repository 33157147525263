<div class="container-fluid background p-0">
  <carfinn-navbar-transparent></carfinn-navbar-transparent>

  <div class="container pt-5 py-3 secNav" style="">

    <div *ngIf="state === 'register'" class="row d-flex justify-content-center">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="login-section">
          <div class="row d-flex justify-content-center" style="height: 100%;">
            <div
              class="col-lg-5 col-md-12 col-sm-12 col-12 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
              <div class="left-section">
                <div class="top-left">
                  <p class="text-center text-left-section">สมัครใช้งานฟรี !</p>
                </div>
                <div class="end-left">
                  <p class="text-left text-left-section">
                    <i class="bi bi-shield-shaded" style="margin-right: 20px;"></i> ปลอดภัย
                  </p>
                  <p class="text-left text-left-section">
                    <img src="assets/icons/free.png" class="mr-3" alt="free-icon"
                      style="width: 22px; margin-bottom: 5px;">
                    ไม่มีค่าใช้จ่าย
                  </p>
                  <p class="text-left text-left-section">
                    <img src="assets/icons/user.png" class="mr-3" alt="free-icon"
                      style="width: 22px; margin-bottom: 5px;">
                    เก็บรักษาข้อมูลเป็นความลับ
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div
                class="main-section pl-lg-4 pr-lg-5 py-lg-5 pl-md-5 pr-md-5 py-md-5 pl-sm-5 pr-sm-5 py-sm-5 pl-3 pr-3 py-3">
                <p class="mx-3 mt-3 mb-4 header-text" style="text-align: center;">{{ 'LOGIN.CREATE_TITLE' | translate }}
                </p>

                <div class="d-flex justify-content-center">
                  <nz-radio-group [(ngModel)]="registerType" (ngModelChange)="changeRegisterType()"
                    nzButtonStyle="solid">
                    <label nz-radio-button nzValue="phone">สร้างด้วยเบอร์โทรศัพท์</label>
                    <label nz-radio-button nzValue="email">สร้างด้วยอีเมล</label>
                  </nz-radio-group>
                </div>

                <div *ngIf="registerType === 'email'" class="login-form mt-4">
                  <nz-alert *ngIf="isError" class="my-3" nzType="error" [nzMessage]="errorMessage" nzShowIcon>
                  </nz-alert>
                  <p class="text-left">กรอกอีเมลของคุณเพื่อสร้างบัญชี</p>
                  <form nz-form [formGroup]="registerEmailForm" class="login-form">
                    <nz-form-item>
                      <nz-form-control [nzErrorTip]="errorEmailMessage">
                        <label for="email">อีเมล</label>
                        <nz-input-group style="height: 40px; border-radius: 5px;">
                          <input (blur)="validateEmail(registerEmailForm.value.email)"
                            (input)="validateEmail(registerEmailForm.value.email)" id="email" formControlName="email"
                            type="text" nz-input nzSize="large" autocomplete="off" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </form>
                </div>

                <div *ngIf="registerType === 'phone'" class="login-form mt-4">
                  <p class="text-left">กรอกเบอร์โทรศัพท์ของคุณเพื่อสร้างบัญชี</p>
                  <form nz-form [formGroup]="registerPhoneForm" class="login-form">
                    <nz-form-item>
                      <nz-form-control [nzErrorTip]="errorPhoneMessage">
                        <label for="email">เบอร์โทรศัพท์</label>
                        <nz-input-group style="height: 40px; border-radius: 5px;">
                          <input (blur)="validatePhone(registerPhoneForm.value.phone)"
                            (input)="validatePhone(registerPhoneForm.value.phone)" id="phone" formControlName="phone"
                            type="tel" maxlength="10" nz-input nzSize="large" autocomplete="off" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </form>
                </div>

                <div class="d-flex">
                  <label nz-checkbox [(ngModel)]="isConfirm" class="my-3">
                  </label>
                  <p class="confirm-text my-3 ml-3">
                    <span class="confirm-check">คุณได้ยอมรับ </span>
                    <span class="confirm-check-link" (click)="policy('term')">ข้อตกลงและเงื่อนไข </span>
                    <span class="confirm-check">รวมไปถึง </span>
                    <span class="confirm-check-link" (click)="policy('policy')">นโยบายคุ้มครองข้อมูลส่วนตัว </span>
                    <span class="confirm-check">ของ Carfinn</span>
                  </p>
                </div>

                <div *ngIf="registerType === 'email'" class="d-flex justify-content-center mt-3">
                  <button *ngIf="isConfirm && registerEmailForm.valid" (click)="next('otp', registerType)"
                    class="btn btn-next">ถัดไป</button>
                  <button *ngIf="!isConfirm || !registerEmailForm.valid" class="btn btn-next disabled">ถัดไป</button>
                </div>
                <div *ngIf="registerType === 'phone'" class="d-flex justify-content-center mt-3">
                  <button *ngIf="isConfirm && registerPhoneForm.valid" (click)="next('otp', registerType)"
                    class="btn btn-next">ถัดไป</button>
                  <button *ngIf="!isConfirm || !registerPhoneForm.valid" class="btn btn-next disabled">ถัดไป</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="state === 'otp'" class="row d-flex justify-content-center">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="login-section">
          <div style="height: 100%; padding: 10% 0;">
            <form [formGroup]="otpForm">
              <carfinn-otp-input [otpForm]="otpForm" [registerType]="registerType" (nextStep)="state = $event">
              </carfinn-otp-input>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="state === 'password'" class="row d-flex justify-content-center">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="login-section">
          <div class="row justify-content-center px-3 py-4">
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
              <p class="text-center header-text my-4">ตั้งรหัสผ่าน</p>
              <p class="text-center gray-text">กำหนดรหัสผ่านขั้นต่ำ 8 ตัวอักษรต้องประกอบไปด้วย</p>

              <div class="d-flex justify-content-center">
                <div>

                  <div class="d-flex">
                    <i class="bi bi-patch-check-fill" [ngClass]="checkUppercase ? 'correct' : 'correct-icon'"></i>
                    <p class="text-center gray-text-second">
                      A-Z ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร</p>
                  </div>
                  <div class="d-flex">
                    <i class="bi bi-patch-check-fill" [ngClass]="checkLowercase ? 'correct' : 'correct-icon'"></i>
                    <p class="text-center gray-text-second">
                      a-z ตัวพิมพเล็กอย่างน้อย 1 ตัวอักษร</p>
                  </div>
                  <div class="d-flex">
                    <i class="bi bi-patch-check-fill" [ngClass]="checkNumber ? 'correct' : 'correct-icon'"></i>
                    <p class="text-center gray-text-second">
                      เลข 0-9 อย่างน้อย 1 ตัวอักษร</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-center p-3">
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
              <form nz-form [formGroup]="passwordForm">
                <nz-form-item>
                  <nz-form-control nzErrorTip="รหัสผ่านยังไม่ปลอดภัย!">
                    <label for="password">รหัสผ่าน</label>
                    <nz-input-group [nzSuffix]="suffixIconPassword">
                      <input id="password" formControlName="password" (input)="checkInput(passwordForm.value.password)"
                        (input)="updateConfirmValidator()" [type]="checkPassword ? 'text' : 'password'" nz-input
                        nzSize="large" autocomplete="off" />
                    </nz-input-group>
                    <ng-template #suffixIconPassword>
                      <i nz-icon [nzType]="checkPassword ? 'eye-invisible' : 'eye'" nzTheme="fill"
                        (click)="checkPassword = !checkPassword" style="cursor: pointer;"></i>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-control nzErrorTip="รหัสผ่านไม่ตรงกัน!">
                    <label for="re-password">ยืนยันรหัสผ่าน</label>
                    <nz-input-group [nzSuffix]="reSuffixIconPassword">
                      <input id="re-password" formControlName="rePassword"
                        [type]="reCheckPassword ? 'text' : 'password'" nz-input nzSize="large" autocomplete="off" />
                    </nz-input-group>
                    <ng-template #reSuffixIconPassword>
                      <i nz-icon [nzType]="reCheckPassword ? 'eye-invisible' : 'eye'" nzTheme="fill"
                        (click)="reCheckPassword = !reCheckPassword" style="cursor: pointer;"></i>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </form>
            </div>
          </div>

          <div class="row d-flex justify-content-center p-3">
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
              <button *ngIf="passwordForm.valid" (click)="register()" class="btn btn-next">ลงชื่อเข้าใช้</button>
              <button *ngIf="!passwordForm.valid" class="btn btn-next disabled">ลงชื่อเข้าใช้</button>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
    <img src="assets/login-page/building.png" class="bg-image-building" alt="building">
    <img src="assets/login-page/car.png" class="bg-image-car" alt="car">
    <img src="assets/login-page/text.png" class="bg-image-text" alt="text">
    <img src="assets/login-page/home.png" class="bg-image-home" alt="home">
  </div>

</div>

<div *ngIf="isLoading">
  <div
    style="position: fixed; z-index: 900; width: 100vw; height: 100vh; opacity: 80; top: 0; left: 0; background: #e8e8e880;">
    <nz-spin nzSimple [nzSize]="'large'"
      style="z-index: 1000; padding: 400px 50px; margin: 20px 0; text-align: center;"></nz-spin>
  </div>
</div>
