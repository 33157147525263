import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../home/home.service';
import { GlobalFunctionService } from 'src/app/core/services/global-function.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  // isLoading: boolean = false;
  articlesList: any[] = [];
  newArticle: any;
  recommendArticle: any;
  articleSelector: string = 'all';
  allArticles: any[] = [];

  isNewArticle: boolean = false;
  _isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading$.asObservable();


  page = 1;
  pageSize = 9;
  collectionSize: number;

  constructor(
    private router: Router,
    private homeService: HomeService,
    private globalFunctionService: GlobalFunctionService,
  ) { }

  ngOnInit(): void {
    this.getArticles('all');
  }

  getArticles(type: string) {
    // this.homeService.getArticles().subscribe(article => {
    //   this.allArticles = article;
    //   this.newArticle = this.allArticles[0];
    //   this.recommendArticle = this.allArticles.slice(0, 3);
    //   this.globalFunctionService.setSEO('บทความทั้งหมด', 'website', '');

    //   this.changeType()
    // });
    this._isLoading$.next(true);
    this.homeService.getAllArticleV2(type).pipe(
      map(article => {
        if (article.status.code === 1000) {
          this.allArticles = article.data;

          this.newArticle = this.allArticles[0];
          this.recommendArticle = this.allArticles.slice(0, 3);
          this.globalFunctionService.setSEO('บทความทั้งหมด', 'website', '');
          this.refresh();
        }
      }),
      catchError(err => {
        return of(false);
      })
    ).subscribe();
  }

  changeType() {
    // console.log(this.articleSelector);
    // this.refresh();
    this.getArticles(this.articleSelector)
  }

  refresh() {
    this.collectionSize = this.allArticles.length;
    this.articlesList = this.allArticles
      .map((account, i) => ({ id: i + 1, ...account }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      this._isLoading$.next(false);
  }


  checkDate(article_date) {
    const today = new Date().getTime();
    const targetDate = new Date(article_date).getTime();

    const diffTime = Math.abs(targetDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 7) {
      this.isNewArticle = true;
    }
  }

}
