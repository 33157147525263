<div class="p-3" style="background-color:  #FAFAFA;">
  <div class="row d-flex mx-4">
    <div class="col-sm-12 col-lg-6 py-2">
      <div class="header">
        <p class="main-header text-left">
          <span>ธนาคารที่เป็นพาร์ทเนอร์กับเรา</span>
        </p>
      </div>
      <div class="mt-3 pt-5 d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <carfinn-carousel-show-pic [options]="partnerOptions" [list]="partnerList" width="width:120px; aspect-ratio: 24/11">
        </carfinn-carousel-show-pic>
      </div>
    </div>
    
    <div class="col-sm-12 col-lg-6 py-2">
      <div class="header">
        <p class="main-header text-left">
          <span>ไฟแนนซ์ที่เป็นพาร์ทเนอร์กับเรา</span>
        </p>
      </div>
      <div class="mt-3 pt-3 d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <carfinn-carousel-show-pic [options]="financeOptions" [list]="leasingList" width="width:120px; aspect-ratio: 24/23">
        </carfinn-carousel-show-pic>
      </div>
    </div>
  </div>
</div>