import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

interface Status {
  code: number;
  description: string;
}

interface resRequestOTP {
  status: Status,
  data: {
    ref: string,
    create_date: string,
    exp_date: string,
  }
}

interface resValidate {
  status: Status
}

interface resValidatePhoneEmail {
  status: Status;
  data: string;
}

interface Status {
  code: number;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpClient
  ) { }

  requestOTP(body) {
    return this.http.post<resRequestOTP>(environment.URL_FRONT + '/verify/requestOTP', body);
  }

  validateOTP(body) {
    return this.http.post<resValidate>(environment.URL_FRONT + '/verify/varidateOTP', body);
  }

  register(body) {
    return this.http.post<resValidate>(environment.URL_FRONT + '/authen/register', body);
  }

  validateEmail(body) {
    return this.http.post<resValidatePhoneEmail>(environment.URL_FRONT + '/authen/validateRegister_email', body);
  }

  validatePhone(body) {
    return this.http.post<resValidatePhoneEmail>(environment.URL_FRONT + '/authen/validateRegister_phone', body);
  }
}
