<link rel="preload" fetchpriority="low" href="of-us.component.scss" as="style" onload="this.onload=null;this.rel='stylesheet'" type="image/webp">

<noscript>
    <link rel="stylesheet" href="of-us.component.scss">
</noscript>

<div class="stat d-flex" style="justify-content: center;">

  <div class="row d-flex" style="justify-content:space-around;width: 100%;">

    <div class="col-lg-4 col-md-4 col-sm-12 col-stat1">
      <div style="display: flex; justify-content: center;">
        <div class="row" style="width: 100%;display: flex;align-items: center;justify-content: center;">

          <div class="col-3" style="display: flex;justify-content: center;">
            <img fetchpriority="low" src="assets/static/group_1.webp" alt="group_1" style="width: 60px;height: 60px; aspect-ratio: 1/1;">
          </div>

          <div class="col-7 col-md-5">
            <div>
              <span class="text-stat" data-cy="text1">ผู้สมัครสินเชื่อกับเรา</span>
            </div>
            <span class="number-stat" data-cy="text1">{{
              registAmout === 0? '0':
              registAmout?
              (registAmout| number : "1.0" )+'+':'-'}}
            </span>
            <div>
              <span class="text-stat">ราย</span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 col-stat2">
      <div class="row d-flex" style="align-items: center; justify-content: center;">

        <div class="row d-flex" style="align-items: center; justify-content: center;">
          <div class="col-3" style="
        display: flex;
        justify-content: center;">
            <img fetchpriority="low" src="assets/static/money_1.webp" alt="money_1" style="width: 60px;height: 60px; aspect-ratio: 1/1;">
          </div>

          <div class="col-5">
            <div>
              <span class="text-stat" data-cy="text3">วงเงินอนุมัติทั้งหมด</span>
            </div>
            <span class="number-stat" data-cy="text4">{{
              totalLoanAmout === 0? '0':
              totalLoanAmout?
              (totalLoanAmout| number : "1.0" )+'+':'-'}}
            </span>
            <div>
              <span class="text-stat">ล้านบาท</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 col-stat3">
      <div class="row d-flex" style="align-items: center; justify-content: center;">

        <div class="row d-flex" style="align-items: center; justify-content: center;">



          <div class="col-3" style="display: flex;justify-content: center;">
            <img fetchpriority="low" src="assets/static/relationship_1.webp" alt="relation_1" style="width: 60px;height: 60px; aspect-ratio: 1/1;">
          </div>

          <div class="col-5">
            <div>
              <span class="text-stat" data-cy="text5">จากไฟแนนซ์ทั้งหมด</span>
            </div>
            <span class="number-stat" data-cy="text6">
              30+
            </span>
            <div>
              <span class="text-stat">สถาบัน</span>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>
