import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { RedirectService } from 'src/app/core/services/redirect.service';

@Component({
  selector: 'app-propfinn-login-modal',
  templateUrl: './propfinn-login-modal.component.html',
  styleUrls: ['./propfinn-login-modal.component.scss'],
})
export class PropfinnLoginModalComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  redirectUrl: string;
  queryParams: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private redirectService: RedirectService
  ) {}

  ngOnInit(): void {
    this.setRedirectUrl();
  }

  setRedirectUrl() {
    // Capture the current URL and query parameters
    const url = this.router.url;
    const urlSegments = url.split('?');
    this.redirectUrl = urlSegments[0];
    this.queryParams = this.route.snapshot.queryParams
  }

  setLocalStorage() {
    this.redirectService.setLocalStorage(this.redirectUrl, this.queryParams);
  }

  redirectToLogin() {
    this.destroyModal();
    this.setLocalStorage();
    this.router.navigate(['/login']);
  }

  redirectToRegister() {
    this.destroyModal();
    this.setLocalStorage();
    this.router.navigate(['/register']);
  }

  destroyModal(): void {
    this.#modal.destroy();
  }
}
