import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalCreateSuccessComponent } from './modal-create-success/modal-create-success.component';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RegisterService } from './register.service';
import { of } from 'rxjs';

@Component({
  selector: 'carfinn-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  isLoading: boolean = false;
  create: boolean = false;
  isError: boolean = false;
  errorEmailMessage: string = 'อีเมลไม่ถูกต้อง';
  errorPhoneMessage: string = 'เบอร์โทรศัพท์ไม่ถูกต้อง';

  // loginForm: FormGroup;
  registerEmailForm: FormGroup;
  registerPhoneForm: FormGroup;
  otpForm: FormGroup;
  passwordForm: FormGroup;
  passwordVisible = false;
  isConfirm: boolean = false;
  registerType: string = 'phone';
  state: string = 'register';

  checkUppercase: boolean = false;
  checkLowercase: boolean = false;
  checkNumber: boolean = false;
  checkPassword: boolean = false;
  reCheckPassword: boolean = false;

  regexUpperCase = new RegExp('^(?=.*[A-Z])[a-zA-Z\\d]{1,}$');
  regexLowerCase = new RegExp('^(?=.*[a-z])[a-zA-Z\\d]{1,}$');
  regexNumber = new RegExp('^(?=.*\\d)[a-zA-Z\\d]{1,}$');
  emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');

  redirectPath: string = '';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private registerService: RegisterService
  ) {
    this.registerEmailForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      // password: ['', Validators.required]
    });

    this.registerPhoneForm = this.fb.group({
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(^02[0-9]{7}$)|(^06[0-9]{8}$)|(^08[0-9]{8}$)|(^09[0-9]{8}$)'
          ),
        ],
      ],
    });

    this.otpForm = this.fb.group({
      emailorphone: ['', Validators.required],
      otp: ['', Validators.required],
      ref: ['', Validators.required],
    });

    this.passwordForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$'
          ),
        ],
      ],
      rePassword: ['', [Validators.required, this.confirmationValidator]],
    });
  }

  ngOnInit(): void {
    this.redirectPath = new URL(location.href).searchParams.get('redirect');
  }

  changeRegisterType() {
    this.isConfirm = false;
    this.registerEmailForm.reset();
    this.registerPhoneForm.reset();
  }

  next(nextState, registerType) {
    let form =
      registerType === 'email'
        ? this.registerEmailForm
        : this.registerPhoneForm;
    let body;

    if (registerType === 'email') {
      body = {
        type: registerType,
        emailorphone: this.registerEmailForm.value.email,
      };
    } else if (registerType === 'phone') {
      body = {
        type: registerType,
        emailorphone: this.registerPhoneForm.value.phone,
      };
    }

    // // console.log(body);

    if (form.valid) {
      this.isLoading = true;
      setTimeout(() => {
        this.registerService
          .requestOTP(body)
          .pipe(
            map((res) => {
              if (res.status.code === 1000) {
                this.otpForm.controls['emailorphone'].setValue(
                  body.emailorphone
                );
                this.otpForm.controls['ref'].setValue(res.data.ref);
                this.isLoading = false;
                this.state = nextState;
              }
            }),
            catchError((err) => {
              alert(err.error.message);
              // // console.log(err.error.message)
              return of(false);
            })
          )
          .subscribe();
      }, 400);
    } else {
      Object.values(form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  policy(term) {
    window.open(`/policy?privacy=${term}`, '_blank');
  }

  register() {
    this.isLoading = true;
    let body = {
      register_with: this.registerType,
      email: this.registerEmailForm.value.email,
      phone: this.registerPhoneForm.value.phone,
      // line: '',
      // google: '',
      // apple: '',
      password: this.passwordForm.value.password,
    };

    const successDialogConfig = new MatDialogConfig();
    successDialogConfig.disableClose = true;
    successDialogConfig.id = 'modalConfirm';
    successDialogConfig.width = '560px';
    successDialogConfig.autoFocus = false;

    this.registerService
      .register(body)
      .pipe(
        switchMap((regist) => {
          if (regist.status.code === 1000) {
            const policyModal = this.matDialog.open(
              ModalCreateSuccessComponent,
              successDialogConfig
            );
            return policyModal.afterClosed().pipe(
              map((res) => {
                if (!res) {
                  if (this.redirectPath) {
                    this.router.navigate(['login'], {
                      queryParams: {
                        redirect: this.redirectPath,
                      },
                    });
                  } else {
                    this.router.navigate(['/login']);
                  }
                }
              })
            );
          }
        }),
        catchError((err) => {
          alert(err.error.message);
          return of(false);
        })
      )
      .subscribe();
  }

  checkInput(password) {
    // // console.log(password);

    if (this.regexUpperCase.test(password)) {
      this.checkUppercase = true;
    } else {
      this.checkUppercase = false;
    }
    if (this.regexLowerCase.test(password)) {
      this.checkLowercase = true;
    } else {
      this.checkLowercase = false;
    }
    if (this.regexNumber.test(password)) {
      this.checkNumber = true;
    } else {
      this.checkNumber = false;
    }
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.passwordForm.value.password) {
      return { confirm: true, error: true };
    }
    return {};
  };

  // * for password validate
  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.passwordForm.controls.rePassword.updateValueAndValidity()
    );
  }

  validateEmail(email) {
    const body = {
      email: email,
    };
    if (email) {
      if (this.emailRegex.test(email)) {
        if (this.registerEmailForm.get('email').valid) {
          this.registerService
            .validateEmail(body)
            .pipe(
              map((mail) => {
                if (mail.status.code !== 1000) {
                  this.registerEmailForm.controls['email'].setErrors({
                    incorrect: true,
                  });
                  this.errorEmailMessage = 'อีเมลนี้ถูกใช้งานแล้ว';
                } else if (this.errorEmailMessage === 'อีเมลนี้ถูกใช้งานแล้ว') {
                  this.errorEmailMessage = 'อีเมลนี้ถูกใช้งานแล้ว';
                } else {
                  this.errorEmailMessage = 'อีเมลไม่ถูกต้อง';
                }
              })
            )
            .subscribe();
        }
      } else {
        this.errorEmailMessage = 'อีเมลไม่ถูกต้อง';
      }
    } else {
      this.errorEmailMessage = 'โปรดระบุ';
    }
  }

  validatePhone(phone) {
    const body = {
      phone: phone,
    };

    if (phone) {
      if (phone.length === 10) {
        if (this.registerPhoneForm.get('phone').valid) {
          this.registerService
            .validatePhone(body)
            .pipe(
              map((ph) => {
                // // console.log(ph);
                if (ph.status.code !== 1000) {
                  this.registerPhoneForm.controls['phone'].setErrors({
                    incorrect: true,
                  });
                  this.errorPhoneMessage = 'เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว';
                } else {
                  this.errorPhoneMessage = 'เบอร์โทรศัพท์ไม่ถูกต้อง';
                }
              })
            )
            .subscribe();
        } else if (this.errorPhoneMessage === 'เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว') {
          this.errorPhoneMessage = 'เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว';
        } else {
          this.errorPhoneMessage = 'เบอร์โทรศัพท์ไม่ถูกต้อง';
        }
      } else {
        this.errorPhoneMessage = 'เบอร์โทรศัพท์ไม่ถูกต้อง';
      }
    } else {
      this.errorPhoneMessage = 'โปรดระบุ';
    }
  }
}
