import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalLoginComponent>,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  login() {
    const response = {
      status: true,
    }
    this.dialogRef.close(response);
  }

  closeModal() {
    const response = {
      status: false
    }
    this.dialogRef.close(response);
  }

}
