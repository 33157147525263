<h5 mat-dialog-title style="padding-left: 30px; position: relative;">
    {{ modalData.title }}
    <div style="position: absolute; right: 17px; margin-top: -5px;">
        <a [mat-dialog-close]="true" (click)="closeModal()">
            <i class="bi bi-x-lg"></i>
        </a>
    </div>
</h5>

<mat-dialog-content id="lifedi-modal" class="mat-typography">
    <div [innerHTML]="modalData.description" class="text-center"></div>
    <!-- <span> {{ modalData.description }}</span> -->
</mat-dialog-content>

<mat-dialog-actions *ngIf="modalData.actionBtnText || modalData.cancleBtnText" align="center">
    <button *ngIf="!modalData.isDelete" mat-button mat-dialog-close class="btn carfinn-btn-success"
        (click)="actionFunction()" style="box-shadow: 0 1px 1px -2px #0003, 0 1px 1px #00000024, 0 1px 5px #0000001f;">
        {{ modalData.actionBtnText }}
    </button>
    <button *ngIf="modalData.isDelete" mat-button mat-dialog-close class="btn carfinn-btn-success"
        (click)="actionFunction()" style="box-shadow: 0 1px 1px -2px #0003, 0 1px 1px #00000024, 0 1px 5px #0000001f;">
        {{ modalData.actionBtnText }}
    </button>

    <button class="btn btn-outline-danger" mat-button [mat-dialog-close]="true" (click)="closeModal()"
        style="box-shadow: 0 1px 1px -2px #0003, 0 1px 1px #00000024, 0 1px 5px #0000001f;">
        {{ modalData.cancleBtnText }}
    </button>
</mat-dialog-actions>