<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle=""
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzOkLoading]="isOkLoading"
  [nzFooter]="null"
>
  <div *nzModalContent>
    <div class="container text-center my-5">
      <p style="font-size: 32px; color: #21333d">{{ txt }}</p>
      <div class="border-check">
        <span nz-icon nzType="check" nzTheme="outline" class="check"></span>
      </div>
    </div>
  </div>
</nz-modal>
