import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { dataQuestions } from '../../../modules/faq/pages/faq-page/faq-page.component';

interface DataRender {
  active: boolean;
  title: string;
  text: SafeHtml

}


@Component({
  selector: 'app-question-page',
  templateUrl: './question-page.component.html',
  styleUrls: ['./question-page.component.scss']
})
export class QuestionPageComponent implements OnInit {

  @Input() data: dataQuestions[] = [];

  dataRender: DataRender[]

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dataRender = this.data.map((data) => {
      return {
        ...data, active: false, text: this.sanitizer.bypassSecurityTrustHtml(data.text)
      }
    })
    

  }


  activePanel(index: number): void {
    this.dataRender.forEach((e, i) => {
      if (i === index) {
        e.active = !e.active;
      }
    });
  }

}
