import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

interface ResGetProfile {
  status: Status;
  data: Data[];
}

interface Data {
  id: number;
  user_id?: any;
  email: string;
  phone?: any;
  line?: any;
  google?: any;
  register_date: string;
  verify_date: string;
  register_with: string;
  title_name?: any;
  name?: any;
  surname?: any;
  gender?: any;
  birthdate?: any;
  address?: any;
  moo?: any;
  road?: any;
  soi?: any;
  district?: any;
  area?: any;
  province?: any;
  zipcode?: any;
  address_delivery?: any;
  moo_delivery?: any;
  road_delivery?: any;
  soi_delivery?: any;
  district_delivery?: any;
  area_delivery?: any;
  province_delivery?: any;
  zipcode_delivery?: any;
  job?: any;
  income_amount?: any;
  convenient_time?: any;
  have_overdue?: any;
  job_age?: any;
  active?: any;
}

interface Status {
  code: number;
  description: string;
}

interface resSuccess {
  status: Status;
  data: string;
}

interface resGetTracking {
  status: Status;
  data: [
    {
      group: string;
      job_number: string;
      job_result: string;
      job_status: string;
      status_job: [];
    }
  ];
}

interface resValidatePhoneEmail {
  status: Status;
  data: string;
}

interface resUpdateProfile {
  status: Status;
  data: {
    access_token: string;
  };
}

export interface ResUpdatePayment {
  status: Status;
  data: {
    transaction_id: string;
    collection_job_number: string;
    create_success: string;
  };
}

export interface ResGetPaymentHistoryDetail {
  status: Status;
  data: PaymentHistroyDetail;
}

export interface PaymentHistroyDetail {
  transaction_id: string;
  due_date: string | Date;
  payment_status: string;
  payment_amount: number;
  payment_detail: PaymentDetail[];
  paid_date: string;
  paid_time: string;
  file_name: string;
  reject_reason: string | null;
  reject_reason_other: string | null;
}

interface PaymentDetail {
  payment_detail: string;
  amount: number;
  payment_type: string;
  payment_code: number;
}

export interface ResGetPaymentHistory {
  status: Status;
  data: PaymentHistroyItem[];
}

export interface PaymentHistroyItem {
  carfinn_job_number: string;
  collection_job_number: string;
  group: string;
  car_detail: CarDetail;
  payment_list: PaymentList[];
}

export interface PaymentList {
  transaction_id: string;
  collection_job_number: string;
  paid_date: string;
  create_date: string;
  create_by: string;
  payment_amount: number;
  accountor_id: string | null;
  payment_status: string;
  paid_time: string;
  file_name: string;
  due_date: string | null;
  reject_reason: string | null;
  note: string | null;
  reject_reason_other: string | null;
}

export interface CarDetail {
  car_type: string;
  car_brand: string;
  car_year: string;
  car_model: string;
  car_gear: string;
  car_engine: string;
  car_class: string;
  car_plate_number: string;
  car_plate_province: string;
}

export interface ResGetDebtPayment {
  status: Status;
  data: DebtPayment;
}

export interface DebtPayment {
  payment_due: string;
  carfinn_job_number: string;
  collection_job_number: string;
  group: string;
  customer_name: string;
  customer_surname: string;
  payment_amount: number;
}

export interface ResGetDebt {
  status: Status;
  data: Debt[];
}

export interface Debt {
  carfinn_job_number: string;
  collection_job_number: string;
  group: string;
  car_detail: {
    car_type: string;
    car_brand: string;
    car_year: string;
    car_model: string;
    car_gear: string;
    car_engine: string;
    car_class: string;
    car_plate_number: string;
    car_plate_province: string;
  };
  car_debt: {
    payment_due: string;
    now_number_installment: number;
    numberOfinstallment: number;
    outstanding: number;
    principal_amount: number;
    interest_amount: number;
    lete_fee_amount: number;
    followup_fee_amount: number;
    total_vat_amount: number;
    payment_amount: number;
    balance_amount: number;
  };
}

export interface ResGetDetail {
  status: Status;
  data: {
    job_number: string;
    group: string;
    statusAndDoc: {
      job_status: string;
      status_job: string[];
      docs: any[];
      doc_remark: null;
    };
    car_detail: {
      car_type: string;
      car_brand: string;
      car_class: string;
      car_model: string;
      car_plate_number: string;
      car_plate_province: string;
      bankname: string;
      monthly_installment: number;
      loan_amount: number;
      numberOfinstallment: number;
      interest_rate: null;
      maximum_loan_percent: null;
    };
    home_detail: {
      property_id: number;
      property_job_number: string;
      property_type: string;
      property_form: string | null;
      bedroom_amount: string | null;
      bathroom_amount: string | null;
      area_amount: number;
      property_province: string;
      property_district: string;
      property_moo: string | null;
      property_road: string | null;
      property_soi: string | null;
      property_area: string;
      property_address: string;
      property_zipcode: string;
      private_price: number;
      private_price_file: string | null;
      government_price: number;
      government_price_file: string | null;
      side_property_price: number;
      property_location: string;
      property_image: string | null;
      property_area_type: string | null;
      property_no: string;
      property_village: string | null;
    };
    job_detail: {
      property_job_number: string;
      job_status: string;
      job_substatus: string | null;
      loan_id: number;
      property_id: number;
      customer_id: number;
      broker_id: number | null;
      loan_type: string;
      bankname: string;
      purposeOfloan: string;
      job_type: string;
      job_owner_name: string;
      note: string | null;
      detail: string | null;
      create_by: string;
      create_date: string;
      update_by: string | null;
      update_date: string;
      job_active: number;
      job_create_from: string | null;
      assign_date: string | null;
      pfa_job_number: string | null;
      create_from_team: string;
      job_close_from: string | null;
      job_number: string;
    };
    loan_detail: {
      loan_id: number;
      property_job_number: string;
      loan_amount: number;
      numberOfYear: number;
      previous_bankname: string;
      liabilities_amount: number;
      previous_contract_years: number;
      previous_monthly_installment: number;
      monthly_installment: number;
    };
    customer_detail: {
      user_id: string;
      customer_title: string;
      customer_name: string;
      customer_surname: string;
      customer_phone: string;
      customer_email: string;
      customer_birthdate: string;
      customer_income_amount: string;
      customer_age_job: string;
      customer_job: string;
      customer_province: string;
      customer_have_overdue: string;
      convenient_time: string;
    };
  };
}

interface resGetTrack {
  status: Status;
  data: Datum[];
}

interface Datum {
  job_number: string;
  job_status: string;
  group: string;
  job_result: string;
  status_job: string[];
  car_detail: Cardetail;
}

interface Cardetail {
  car_type: string;
  car_brand: string;
  car_year: string;
  car_model: string;
  car_gear: string;
  car_engine: string;
  car_class: string;
  car_plate_number: string;
  car_plate_province: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  getProfile() {
    return this.http.get<ResGetProfile>(
      environment.URL_FRONT + '/authen/profile'
    );
  }

  updateProfile(form, currentAddress) {
    // // console.log(this.header);
    // // console.log(form);
    let formData = new FormData();

    if (form.account_image) {
      formData.append('image', form.account_image);
    }

    if (form.gender) {
      formData.append('gender', form.gender);
    }

    // console.log(typeof form.id);
    // console.log(form.id);

    formData.append('id', form.id ? form.id.toString() : '');
    formData.append('user_id', form.user_id ? form.user_id : '');
    formData.append('title_name', form.prefix ? form.prefix : '');
    formData.append('name', form.name ? form.name : '');
    formData.append('surname', form.surname ? form.surname : '');
    formData.append('phone', form.phone ? form.phone : '');
    formData.append('email', form.email ? form.email : '');
    formData.append('birthdate', form.birthDateStr ? form.birthDateStr : '');
    formData.append('address_obj[address]', form.address ? form.address : '');
    formData.append('address_obj[moo]', form.moo ? form.moo : '');
    formData.append('address_obj[road]', form.road ? form.road : '');
    formData.append('address_obj[soi]', form.soi ? form.soi : '');
    formData.append('address_obj[area]', form.area ? form.area : '');
    formData.append(
      'address_obj[district]',
      form.district ? form.district : ''
    );
    formData.append(
      'address_obj[province]',
      form.province ? form.province : ''
    );
    formData.append('address_obj[zipcode]', form.zipcode ? form.zipcode : '');
    formData.append(
      'delivery_obj[address_delivery]',
      form.current_address ? form.current_address : ''
    );
    formData.append(
      'delivery_obj[moo_delivery]',
      form.current_moo ? form.current_moo : ''
    );
    formData.append(
      'delivery_obj[road_delivery]',
      form.current_road ? form.current_road : ''
    );
    formData.append(
      'delivery_obj[soi_delivery]',
      form.current_soi ? form.current_soi : ''
    );
    formData.append(
      'delivery_obj[area_delivery]',
      form.current_area ? form.current_area : ''
    );
    formData.append(
      'delivery_obj[district_delivery]',
      form.current_district ? form.current_district : ''
    );
    formData.append(
      'delivery_obj[province_delivery]',
      form.current_province ? form.current_province : ''
    );
    formData.append(
      'delivery_obj[zipcode_delivery]',
      form.current_zipcode ? form.current_zipcode : ''
    );
    formData.append('checkbox_delivery', currentAddress);
    formData.append('job', form.current_job ? form.current_job : '');
    formData.append(
      'income_amount',
      form.income_per_month ? form.income_per_month : ''
    );
    formData.append('job_age', form.job_age ? form.job_age : '');
    formData.append(
      'convenient_time',
      form.convenient_time ? form.convenient_time : ''
    );
    formData.append(
      'have_overdue',
      form.customer_overdue ? form.customer_overdue : ''
    );

    return this.http.post<resUpdateProfile>(
      environment.URL_FRONT + '/authen/update_profile',
      formData
    );
  }

  getTracking(body) {
    return this.http.post<resGetTrack>(
      environment.URL_FRONT + '/tracking/get_tracking',
      body
    );
  }

  validateProfilePhone(body) {
    return this.http.post<resValidatePhoneEmail>(
      environment.URL_FRONT + '/authen/validateProfile_phone',
      body
    );
  }

  validateProfileEmail(body) {
    return this.http.post<resValidatePhoneEmail>(
      environment.URL_FRONT + '/authen/validateProfile_email',
      body
    );
  }

  getDetail(job_number) {
    return this.http.get<ResGetDetail>(
      environment.URL_FRONT + `/tracking/get_detail?job_number=${job_number}`
    );
  }

  delDoc(body) {
    return this.http.post<resSuccess>(
      environment.URL_FRONT + '/tracking/del_doc',
      body
    );
  }

  delDocProp(body) {
    return this.http.post<resSuccess>(
      environment.URL_FRONT + '/tracking/del_prop_doc',
      body
    );
  }

  uploadDoc(formData) {
    return this.http.post(
      environment.URL_FRONT + '/tracking/upload_docs',
      formData
    );
  }

  uploadDocProp(formData) {
    return this.http.post(
      environment.URL_FRONT + '/tracking/upload_prop_docs',
      formData
    );
  }

  getDebt(group) {
    return this.http.get<ResGetDebt>(
      environment.URL_FRONT + `/payment/get_debt?group=${group}`
    );
  }

  getDebtPayment(collection_job_number) {
    return this.http.get<ResGetDebtPayment>(
      environment.URL_FRONT +
        `/payment/get_debt_payment?collection_job_number=${collection_job_number}`
    );
  }

  getPaymentHistory() {
    return this.http.get<ResGetPaymentHistory>(
      environment.URL_FRONT + '/payment/get_payment_history'
    );
  }

  getPaymentHistoryDetail(transaction_id) {
    return this.http.get<ResGetPaymentHistoryDetail>(
      environment.URL_FRONT +
        `/payment/get_payment_history_detail?transaction_id=${transaction_id}`
    );
  }

  uploadPayment(formData) {
    return this.http.post<ResUpdatePayment>(
      environment.URL_FRONT + '/payment/payment',
      formData
    );
  }

  connectLine(data) {
    return this.http.post<any>(
      environment.URL_FRONT + '/authen/connect_line',
      data
    );
  }

  disconnectLine() {
    return this.http.post<any>(
      environment.URL_FRONT + '/authen/disconnect_line',
      {}
    );
  }
}
