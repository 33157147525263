<div class="row banner-button" style="">
  <div class="col-lg-6 col-md-12 col-sm-12 col-6 d-flex justify-content-center">
    <button
      class="buttonAdvice mb-4"
      type="button"
      (click)="toLine()"
      data-cy="text banner bn 1"
      style="
        background-color: #01b7f6;
        border: 2.5px solid #199cc6;
        box-shadow: 0px 4px 4px 0 rgb(0, 182, 241, 0.5);
      "
    >
      <a
        href="https://lin.ee/sfqF1Vo"
        target="_blank"
        style="text-decoration: none; color: white"
      >
        ปรึกษาฟรี ตอนนี้เลย</a
      >
    </button>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 col-6 d-flex justify-content-center">
    <button
      class="buttonContact mb-4"
      type="button"
      (click)="scrollToSection()"
      data-cy="text banner bn 2"
      style="
        background-color: #0a428f;
        border: 2.5px solid #093168;
        box-shadow: 0px 4px 4px 0 rgba(3, 66, 142, 0.5);
      "
    >
      {{ buttonText2 }}
    </button>
  </div>
</div>
