import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgetPasswordService } from './forget-password.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RegisterService } from '../register/register.service';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  isLoading: boolean = false;
  isError: boolean = false;
  forgetPasswordForm: FormGroup;
  otpForm: FormGroup;
  updatePasswordForm: FormGroup;
  errorMessage: string = '';
  state: string = 'initial';
  userType: string = 'phone';
  isChange: boolean = true;

  errorPhoneMessage: string = 'โปรดระบุ';
  errorEmailMessage: string = 'โปรดระบุ';

  // for params from url
  validate_token: string = '';
  setPassword: boolean = false;

  checkUppercase: boolean = false;
  checkLowercase: boolean = false;
  checkNumber: boolean = false;

  regexUpperCase = new RegExp("^(?=.*[A-Z])[a-zA-Z\\d]{1,}$");
  regexLowerCase = new RegExp("^(?=.*[a-z])[a-zA-Z\\d]{1,}$");
  regexNumber = new RegExp("^(?=.*\\d)[a-zA-Z\\d]{1,}$");

  constructor(
    private fb: FormBuilder,
    private forgetPasswordService: ForgetPasswordService,
    private registerService: RegisterService,
    private router: Router,
    public matDialog: MatDialog,
  ) {
    this.forgetPasswordForm = fb.group({
      type: ['phone', Validators.required],
      email: ['', [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      phone: ['', [Validators.required, Validators.pattern('(^02[0-9]{7}$)|(^06[0-9]{8}$)|(^08[0-9]{8}$)|(^09[0-9]{8}$)')]]
    })

    this.otpForm = this.fb.group({
      emailorphone: ['', Validators.required],
      otp: ['', Validators.required],
      ref: ['', Validators.required]
    })

    this.updatePasswordForm = this.fb.group({
      forgotpass_token: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$')]],
      confirm_password: ['', [Validators.required, this.confirmationValidator]]
    })


  }

  ngOnInit(): void {
    this.forgetPasswordForm.get('email').disable();

    this.setPassword = JSON.parse(new URL(location.href).searchParams.get('set-password'));
    if (this.setPassword) {
      this.validate_token = new URL(location.href).searchParams.get('validatepass_token');
      this.userType = 'email';
      this.validateForgotpassToken(this.validate_token);
    }
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.updatePasswordForm.value.password) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validatePhone(form) {
    if (form.hasError('required')) {
      this.errorPhoneMessage = 'โปรดระบุ';
    } else if (form.invalid) {
      this.errorPhoneMessage = 'เบอร์โทรศัพท์ไม่ถูกต้อง'
    }
  }

  validateEmail(form) {
    if (form.invalid) {
      this.errorEmailMessage = 'อีเมลไม่ถูกต้อง'
    }
  }

  checkType(type) {
    this.isError = false;
    if (type === 'phone') {
      this.forgetPasswordForm.get('phone').enable();
      this.forgetPasswordForm.get('phone').setValidators(Validators.required);
      this.forgetPasswordForm.get('phone').markAsDirty();

      this.forgetPasswordForm.patchValue({ email: '' });
      this.forgetPasswordForm.get('email').clearValidators();
      this.forgetPasswordForm.get('email').markAsPristine();
      this.forgetPasswordForm.get('email').disable();

    } else if (type === 'email') {
      this.forgetPasswordForm.get('email').enable();
      this.forgetPasswordForm.get('email').setValidators(Validators.required);
      this.forgetPasswordForm.get('email').markAsDirty();

      this.forgetPasswordForm.patchValue({ phone: '' });
      this.forgetPasswordForm.get('phone').clearValidators();
      this.forgetPasswordForm.get('phone').markAsPristine();
      this.forgetPasswordForm.get('phone').disable();
    }
    this.forgetPasswordForm.get('phone').updateValueAndValidity();
    this.forgetPasswordForm.get('email').updateValueAndValidity();
  }

  submit(value) {
    this.isLoading = true;
    let errStr = value.type === 'phone' ? 'หมายเลขโทรศัพท์' : 'อีเมล'
    let body = {
      type: value.type,
      value: value.type === 'phone' ? value.phone : value.email
    }
    // console.log(body);
    this.forgetPasswordService.validateValue(body).pipe(
      map(res => {
        if (res.status.code === 1000) {
          if (res.data.validate) {
            if (value.type === 'phone') {
              this.requestOtp(body);
            } else {
              this.forgetPassword(value);
            }
          } else {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = `ไม่พบ${errStr}ในระบบ`;
          }
        } else {
          this.isLoading = false;
          return of(false);
        }
      }),
      catchError(err => {
        this.isLoading = false;
        this.router.navigate(['/'])
        alert('ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง');
        return of(false);
      })
    ).subscribe();
  }

  requestOtp(value) {
    let body = {
      type: 'phone',
      emailorphone: value.value
    }
    setTimeout(() => {
      this.registerService.requestOTP(body).pipe(
        map(res => {
          if (res.status.code === 1000) {
            this.otpForm.controls['emailorphone'].setValue(body.emailorphone);
            this.otpForm.controls['ref'].setValue(res.data.ref);
            this.isLoading = false;
            this.state = 'request-otp';
          }
        }),
        catchError(err => {
          alert(err.error.message);
          // // console.log(err.error.message)
          return of(false)
        })
      ).subscribe();
    }, 400);
  }

  forgetPassword(value) {
    // isChange ไว้เช็คว่าเรียก function หรือยัง เพราะมันยิงจากหน้า html หลายรอบ

    if (this.isChange) {
      this.isChange = false;
      let body = {
        type: value.type,
        value: value.type === 'phone' ? value.phone : value.email
      }
      // console.log(body);
      this.forgetPasswordService.forgotPassword(body).pipe(
        map(pw => {
          if (pw.status.code === 1000) {
            if (value.type === 'phone') {
              this.validateForgotpassToken(pw.data.forgotpass_token);
            } else {
              setTimeout(() => {
                this.isLoading = false;
                this.state = 'check-email';
              }, 400);
            }
          } else {
            return of(false);
          }
        }),
        catchError(err => {
          alert(err.error.message);
          this.router.navigate(['/forget-password']);
          return of(false);
        })
      ).subscribe();
    }
  }

  validateForgotpassToken(token) {
    let body = {
      forgotpass_token: token
    }

    this.forgetPasswordService.validateForgotPassToken(body).pipe(
      map(res => {
        if (res.status.code === 1000) {
          this.isLoading = false;
          this.state = 'password';

          this.updatePasswordForm.patchValue({
            forgotpass_token: token,
          })
          this.updatePasswordForm.get('forgotpass_token').updateValueAndValidity();
          // console.log(this.updatePasswordForm);
        } else {
          this.isLoading = false;
          return of(null);
        }
      }),
      catchError(err => {
        alert(err.error.message);
        this.isLoading = false;
        this.router.navigate(['/forget-password']);
        return of(false)
      })
    ).subscribe();
  }

  updatePassword(body) {
    this.isLoading = true;

    const saveDialogConfig = new MatDialogConfig();
    saveDialogConfig.disableClose = false;
    saveDialogConfig.id = 'modalConfirm';
    saveDialogConfig.width = '500px';
    saveDialogConfig.autoFocus = false;
    saveDialogConfig.data = {
      description: `<h3 class="mb-4">เปลี่ยนรหัสสำเร็จ</h3>
      <img src="../../../assets/logo/confirm.png" width="100px;" class="img-center">
      <h5 class="text-center my-4">บัญชีของคุณพร้อมใช้งานแล้ว</h5>`
    }

    this.forgetPasswordService.updatePassword(body).pipe(
      switchMap(res => {
        if (res.status.code === 1000) {
          const saveModal = this.matDialog.open(ModalComponent, saveDialogConfig);
          return saveModal.afterClosed().pipe(
            map(save => {
              if (!save) {
                setTimeout(() => {
                  this.isLoading = false;
                  this.router.navigate(['/login']);
                }, 100)
              } else {
                this.isLoading = false;
                return of(null);
              }
            })
          )
        } else {
          this.isLoading = false;
          return of(null);
        }
      })
    ).subscribe();
  }

  checkInput(password) {
    // // console.log(password);

    if (this.regexUpperCase.test(password)) {
      this.checkUppercase = true;
    } else {
      this.checkUppercase = false;
    }
    if (this.regexLowerCase.test(password)) {
      this.checkLowercase = true;
    } else {
      this.checkLowercase = false;
    }
    if (this.regexNumber.test(password)) {
      this.checkNumber = true;
    } else {
      this.checkNumber = false;
    }
  }

  // * for password validate
  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.updatePasswordForm.controls.confirm_password.updateValueAndValidity()
    );
  }

}
