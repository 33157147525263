<!-- desktop -->
<div
  class="container-fluid pt-5 pb-3 carfinn-footer d-none d-xxl-block d-xl-block d-lg-block d-md-none"
>
  <div class="container">
    <div class="text-center mb-4">
      <img
        src="assets/logo/carfinn-logo-white.webp"
        class="carfinn-logo"
        alt="carfinn"
        fetchpriority="low"
      />
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a (click)="goToSection('carLoan')" data-cy="Footer 1">
          <p class="click footer-text text-white">สินเชื่อรถยนต์</p>
        </a>
        <!-- <p class="click footer-text text-white" routerLink="/">สินเชื่อรถยนต์</p> -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/propfinn" data-cy="Footer 5">
          <p class="footer-text text-white">สินเชื่อบ้าน</p>
        </a>
        <!-- <p class="click footer-text text-white" routerLink="/about-us">รู้จักเรา</p> -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/policy" data-cy="Footer 9">
          <p class="click footer-text text-white">ข้อตกลงและนโยบายส่วนบุคคล</p>
        </a>
        <!-- <p class="click footer-text text-white" routerLink="/policy">ข้อตกลงและนโยบายส่วนบุคคล</p> -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <p class="footer-text text-white" data-cy="contact">ติดต่อเราได้ที่</p>
      </div>
    </div>

    <div class="row d-flex justify-content-center my-4">
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/car-loan" data-cy="Footer 2">
          <p class="click footer-text text-white">เช็กราคารถยนต์</p>
        </a>
        <!-- <p class="click footer-text text-white" routerLink="/car-loan">เช็กราคารถยนต์</p> -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/about-us" data-cy="Footer 6">
          <p class="footer-text text-white">เกี่ยวกับคาร์ฟินน์</p>
        </a>
        <!-- <p class="click footer-text text-white" routerLink="/articles">บทความ</p> -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="tel:0946655114" class="tel" data-cy="Footer 10">
          <p class="footer-text text-white">
            <i class="bi bi-telephone"></i> 094-665-5114
          </p>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <div class="contact-box">
          <div class="d-flex">
            <a
              (click)="facebook()"
              id="carfinn-facebook-footer"
              class="footer-logo"
              data-cy="FC footer"
            >
              <img
                class="img-social"
                src="assets/social-logo/facebook-circle.webp"
                alt="facebook-white"
                fetchpriority="low"
              />
            </a>
            <a
              href="https://twitter.com/c_carfinn"
              target="_blank"
              id="carfinn-twitter-footer"
              class="footer-logo"
              data-cy="X footer"
            >
              <img class="img-social" src="assets/social-logo/twitter.webp" alt="twiiter-white" fetchpriority="low" />
            </a>
            <a
              href="https://lin.ee/sfqF1Vo"
              id="carfinn-line-footer"
              target="_blank"
              class="footer-logo"
              data-cy="Line footer"
            >
              <img class="img-social" src="assets/social-logo/line-circle.webp" alt="line-white" fetchpriority="low" />
            </a>
          </div>
          <div class="d-flex mt-2">
            <a
              href="https://www.tiktok.com/@carfinn.official"
              target="_blank"
              id="carfinn-tiktok-footer"
              class="footer-logo"
              data-cy="TikTok footer"
            >
              <img
                class="img-social"
                src="assets/social-logo/tiktok-circle.webp"
                alt="tiktok-white"
                fetchpriority="low"
              />
            </a>
            <a
              href="https://www.instagram.com/carfinn_official/"
              target="_blank"
              id="carfinn-instagram-footer"
              class="footer-logo"
              data-cy="IG footer"
            >
              <img
                class="img-social"
                src="assets/social-logo/instagram-circle.webp"
                alt="instagram-white"
                fetchpriority="low"
              />
            </a>
            <a
              href="https://www.youtube.com/@carfinn"
              target="_blank"
              id="carfinn-youtube-footer"
              class="footer-logo"
              data-cy="Youtube footer"
            >
              <img
                class="img-social"
                src="assets/social-logo/youtube-circle.webp"
                alt="youtube-white"
                fetchpriority="low"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/calculate" data-cy="Footer 3">
          <p class="footer-text text-white">คำนวณอัตราดอกเบี้ย</p>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/vision" data-cy="Footer 7">
          <p class="footer-text text-white">วิสัยทัศน์และพันธกิจ</p>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a (click)="location()" data-cy="Footer 11">
          <p class="footer-text text-white">
            <i class="bi bi-geo-alt"></i> ที่ตั้งของเรา
          </p>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12"></div>
    </div>

    <div class="row d-flex justify-content-center mt-4">
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/faq" data-cy="Footer 4">
          <p class="footer-text text-white">คำถามที่พบบ่อย</p>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12">
        <a href="/articles" data-cy="Footer 8">
          <p class="click footer-text text-white">บทความ</p>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12"></div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-12"></div>
    </div>

    <div class="d-flex justify-content-center version pt-5 pb-3">
      <p class="version-text m-0">
        © 2016 - 2023 Carfinn Co., Ltd. All Rights Reserved | Version 2.0.0
      </p>
    </div>
  </div>
</div>

<!-- mobile -->
<div
  class="container-fluid carfinn-footer d-block d-sm-block d-md-block d-xxl-none d-xl-none d-lg-none"
>
  <div class="container">
    <div class="text-center">
      <img
        src="assets/logo/carfinn-logo-white.png"
        class="carfinn-logo"
        alt="carfinn"
        fetchpriority="low"
      />
    </div>

    <div class="row px-4">
      <div class="col-12 mt-4">
        <a (click)="goToSection('carLoan')" data-cy="Footer 1">
          <p class="click footer-text text-white">สินเชื่อรถยนต์</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/car-loan" data-cy="Footer 2">
          <p class="click footer-text text-white">เช็กราคารถยนต์</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/calculate" data-cy="Footer 3">
          <p class="footer-text text-white">คำนวณอัตราดอกเบี้ย</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/faq" data-cy="Footer 4">
          <p class="footer-text text-white">คำถามที่พบบ่อย</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/propfinn" data-cy="Footer 5">
          <p class="footer-text text-white">สินเชื่อบ้าน</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/vision" data-cy="Footer 7">
          <p class="footer-text text-white">วิสัยทัศน์และพันธกิจ</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/about-us" data-cy="Footer 6">
          <p class="footer-text text-white">เกี่ยวกับคาร์ฟินน์</p>
        </a>
      </div>
      <div class="col-12 mt-4">
        <a href="/articles" data-cy="Footer 8">
          <p class="click footer-text text-white">บทความ</p>
        </a>
      </div>
      <!-- <div class="col-12 mt-4">
                <p class="click footer-text text-white" routerLink="/coming-soon">สำหรับนักลงทุน</p>
            </div> -->
      <div class="col-12 mt-4">
        <a href="/policy" data-cy="Footer 9">
          <p class="click footer-text text-white">ข้อตกลงและนโยบายส่วนบุคคล</p>
        </a>
      </div>
    </div>
    <div class="row justify-content-center px-4">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 my-3">
        <p class="footer-text text-white" data-cy="contact">ติดตามเราได้ที่</p>
        <div class="d-flex justify-content-start mt-3">
          <a
            (click)="facebook()"
            id="carfinn-facebook-footer"
            class="footer-logo p-1"
            data-cy="FC footer"
          >
            <img
              src="assets/social-logo/facebook-circle.webp"
              alt="facebook-white"
              fetchpriority="low"
            />
          </a>

          <a
            href="https://twitter.com/c_carfinn"
            target="_blank"
            id="carfinn-twitter-footer-p"
            class="footer-logo p-1"
            data-cy="X footer"
          >
            <img src="assets/social-logo/twitter.webp" alt="twiiter-white" fetchpriority="low" />
          </a>

          <a
            href="https://lin.ee/sfqF1Vo"
            id="carfinn-line-footer-p"
            target="_blank"
            class="footer-logo p-1"
            data-cy="Line footer"
          >
            <img src="assets/social-logo/line-circle.webp" alt="line-white" fetchpriority="low" />
          </a>

          <a
            href="https://www.tiktok.com/@carfinn.official"
            target="_blank"
            id="carfinn-tiktok-footer-p"
            class="footer-logo p-1"
            data-cy="TikTok footer"
          >
            <img
              src="assets/social-logo/tiktok-circle.webp"
              alt="tiktok-white"
              fetchpriority="low"
            />
          </a>

          <a
            href="https://www.instagram.com/carfinn_official/"
            target="_blank"
            id="carfinn-instagram-footer-p"
            class="footer-logo p-1"
            data-cy="IG footer"
          >
            <img
              src="assets/social-logo/instagram-circle.webp"
              alt="instagram-white"
              fetchpriority="low"
            />
          </a>

          <a
            href="https://www.youtube.com/@carfinn"
            target="_blank"
            id="carfinn-youtube-footer-p"
            class="footer-logo p-1"
            data-cy="Youtube footer"
          >
            <img
              src="assets/social-logo/youtube-circle.webp"
              alt="youtube-white"
              fetchpriority="low"
            />
          </a>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <a href="tel:0946655114" class="tel" data-cy="Footer 10">
          <p class="footer-text text-white mt-3 mb-4">
            <i class="bi bi-telephone"></i> 094-665-5114
          </p>
        </a>
        <a (click)="location()" data-cy="Footer 11">
          <p class="footer-text text-white my-3">
            <i class="bi bi-geo-alt"></i> ที่ตั้งของเรา
          </p>
        </a>
      </div>
    </div>

    <div class="d-flex justify-content-center version px-4 pt-3 pb-3">
      <p class="version-text text-center m-0">
        © 2016 - 2023 Carfinn Co., Ltd. All Rights Reserved <br />| Version
        2.0.0
      </p>
    </div>
  </div>
</div>
