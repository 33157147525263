import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AdvertisingDto, ResIntoAdvertise} from '../gateways/advertising/dto/advertise.dto';
import {AdvertisementGatewayService} from '../gateways/advertising/advertisement-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingService {

  constructor(private http: HttpClient, private gateway: AdvertisementGatewayService) { }

  public getAdvertisement(): Observable<AdvertisingDto> {
    return this.gateway.getAdvertisement().pipe(map(
      (res) => {
        return res.data;
      }
    ));
  }

  public intoAdvertising(advertisingId: number): Observable<ResIntoAdvertise> {
    return this.gateway.intoAdvertising(advertisingId).pipe(map(
      (res) => {
        return res.data;
      }
    ));
  }
}
