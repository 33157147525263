import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { RedirectService } from 'src/app/core/services/redirect.service';

@Component({
  selector: 'carfinn-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() token: string = '';
  @Input() profile;

  panelOpenState = false;
  language: string = 'th';
  path: string;

  redirectUrl: string;
  queryParams: any;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private modal: NzModalService,
    private redirectService: RedirectService
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.visible = false;
  }

  setRedirectUrl() {
    // Capture the current URL and query parameters
    const url = this.router.url;
    const urlSegments = url.split('?');
    this.redirectUrl = urlSegments[0];
    this.queryParams = this.route.snapshot.queryParams;
  }

  setLocalStorage() {
    this.redirectService.setLocalStorage(this.redirectUrl, this.queryParams);
  }

  login() {
    this.setRedirectUrl();
    this.setLocalStorage();
    this.router.navigate(['/login']);
  }

  changeLanguage(lang): void {
    this.translate.use(lang);
    this.cookieService.delete('language');
    this.cookieService.set('language', lang);
  }

  showLogout(): void {
    this.visible = false;
    this.modal.confirm({
      nzIconType: null,
      nzClosable: false,
      nzTitle: 'ยืนยันการออกจากระบบ',
      nzContent: 'คุณต้องลงชื่อออกจากระบบใช่หรือไม่ ?',
      nzCancelText: 'ยกเลิก',
      nzOkText: 'ใช่',
      nzOnOk: () => this.logout(),
    });
  }

  async logout() {
    try {
      // this.loginService.logout();
      this.authService
        .deleteToken()
        .pipe(
          map((res) => {
            // if (res) {
            if (this.path === '') {
              location.reload();
            } else {
              this.router.navigate(['/']).then(() => {
                location.reload();
              });
            }
            // }
          })
        )
        .subscribe();
    } catch (error) {
      console.log(error);
    }
  }

  currentUrl: string;

  scrollToSection(sectionId: string) {
    this.currentUrl = this.router.url;
    console.log('Current URL:', this.currentUrl);
    console.log(sectionId);

    const element = document.getElementById(sectionId);
    console.log('element:', element);

    if (this.currentUrl !== '/') {
      this.router.navigate(['/'], { fragment: sectionId });
    }

    if (element) {
      const offset = element.offsetTop - window.innerHeight * 0.1;
      this.renderer.setProperty(document.documentElement, 'scrollTop', offset);
    }
  }
}
