import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-of-directors',
  templateUrl: './board-of-directors.component.html',
  styleUrls: ['./board-of-directors.component.scss']
})
export class BoardOfDirectorsComponent implements OnInit {

  directors: director[] = [
    {
      img: 'assets/about-us/board-of-direc/Kreat.webp',
      name: 'คุณเกียรติศักดิ์ กีรติยากรสกุล',
      role: 'CEO & Founder',
      discript: 'ความเชี่ยวชาญเกี่ยวกับรถยนต์และการซื้อขายรถยนต์มือสองกว่า 25 ปี โดยมีความรู้และ ประสบการณ์ตั้งแต่ ชิ้นส่วนอะไหล่รถยนต์ ไปจนถึงการปล่อยสินเชื่อรถยนต์ทุกประเภท',
      exp: ['จําหน่ายชิ้นส่วนอะไหล่รถยนต์',
        'ดีลเลอร์จําหน่ายยางรถยนต์แบรนด์ GoodYearเป็นอันดับ 2 ของประเทศไทย',
        'ศูนย์บริการซ่อมรถยนต์ 2 สาขา',
        'ผู้ก่อตั้งและปล่อยสินเชื่อสหกรณ์แท็กซี่ลาดพร้าว มีรถแท๊กซี่กว่า 7,000 คัน',
        'ธุรกิจรถยนต์มือสอง 2 สาขา (KPN Motor Groups)',
        'ผู้ก่อตั้งธุรกิจแพลตฟอร์ม CARFINN']
    },
    {
      img: 'assets/about-us/board-of-direc/Yao.webp',
      name: 'ดร.เยาวลักษณ์ เตชะรุ่งโรจน์วงศ์',
      role: 'Chief Financial Officer(CFO)',
      discript: 'ที่ปรึกษาด้านการเงิน และมีประสบการณ์ในสายงานธนาคารกว่า 20 ปี',
      exp: ['นักบริหารเงินและสภาพคล่องธนาคารโตเกียวมิตซูบิชิ',
        'นักวิเคราะห์อนุพันธ์ทางการเงินและสภาพคล่องธนาคารแห่งประเทศไทย',
        'ผู้อํานวยการอาวุโส บล. บัวหลวง (มหาชน)',
        'อาจารย์ มหาวิทยาลัยอัสสัมชัญ และมหาวิทยาลัยธรรมศาสตร์',
        'ผู้ก่อตั้งธุรกิจแพลตฟอร์ม CARFINN']
    },
    {
      img: 'assets/about-us/board-of-direc/Wachara.webp',
      name: 'คุณวัชรพงศ์ เสาววัฒนพัฒน์',
      role: 'Chief Technology Officer(CTO)',
      discript: 'มีความเชี่ยวชาญการเขียนโปรแกรมและได้ร่วมก่อตั้งและพัฒนาแพลตฟอร์มของคาร์ฟินน์ (CARFINN) ขึ้นมา',
      exp: ['ผู้เชี่ยวชาญ ด้าน วิศวกรรมคอมพิวเตอร์ ร่วมงานกับ บริษัท ไอที ชั้นนํา (IBM, CISCO)',
        'เป็นผู้ก่อตั้ง ซอฟต์แวร์เฮ้าส์ บริษัท โกร์ส ไอที จํากัด',
        'ผู้ก่อตั้งธุรกิจแพลตฟอร์ม CARFINN']
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

interface director {
  img: string
  name: string
  role: string
  discript: string
  exp: string[]
}
