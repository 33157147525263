<!-- home page, car loan  -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="navbar" appScroll>
  <a class="navbar-brand" routerLink="/">
    <img src="assets/logo/carfinn-logo-white.webp" alt="carfinn-logo" class="logo" fetchpriority="low" />
  </a>
  <ul class="navbar-nav ml-auto" style="flex-direction: initial">
    <li *ngIf="token && profile"
      class="nav-item my-auto mx-md-2 mx-sm-2 mx-2 d-none d-xxl-block d-xl-block d-lg-block d-md-block">
      <div class="layout-config-footer" style="width: 400px">
        <div class="d-flex" style="justify-content: space-evenly; align-items: center">
          <a href="https://www.facebook.com/carfiinn/" target="_blank" id="carfinn-facebook">
            <img class="img-social" style="height: 30px" src="assets/social-logo/facebook-circle.webp"
              alt="facebook-white" fetchpriority="low" />
          </a>
          <a href="https://twitter.com/c_carfinn" target="_blank" id="carfinn-twitter" style="background-color: rgb(0, 0, 0);color: white;padding: 5px; border-radius: 50%;color: #01213C
            ;">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-twitter-x"
              style="color: white;" viewBox="0 0 16 16">
              <path
                d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
            </svg>
          </a>
          <a href="https://www.instagram.com/carfinn_official/" target="_blank" id="carfinn-instagram">
            <img class="img-social" style="height: 30px" src="assets/social-logo/instagram-circle.webp"
              alt="instagram-white" fetchpriority="low" />
          </a>
          <a href="https://www.tiktok.com/@carfinn.official" target="_blank" id="carfinn-tiktok">
            <img class="img-social" style="height: 30px" src="assets/social-logo/tiktok-circle.webp"
              alt="tiktok-white" fetchpriority="low" />
          </a>
          <a href="https://lin.ee/sfqF1Vo" target="_blank" id="carfinn-line">
            <img class="img-social" src="assets/social-logo/line-circle.webp" alt="line-white" style="height: 30px" fetchpriority="low" />
          </a>
          <a href="https://www.youtube.com/@carfinn" target="_blank" id="carfinn-youtube">
            <img class="img-social" style="height: 30px" src="assets/social-logo/youtube-circle.webp"
              alt="youtube-white" fetchpriority="low" />
          </a>

          <nz-avatar *ngIf="image_profile !== ''" class="mx-4" nzIcon="user" [nzSrc]="image_profile"
            style="cursor: pointer" nz-popover [(nzPopoverVisible)]="visiblepop"
            (nzPopoverVisibleChange)="change($event)" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate"
            nzPopoverPlacement="bottomRight">
          </nz-avatar>
        </div>
      </div>
      <ng-template #contentTemplate>
        <div *ngIf="!confirmLogoutPop" class="container">
          <div class="d-flex justify-content-center">
            <div *ngIf="token && profile" class="profile-wrapper">
              <img *ngIf="profile.data[0].image_profile === ''" src="assets/icons/default.png" alt="user-profile"
                class="profilePic rounded-circle mr-2 img-fluid" fetchpriority="low" />
              <img *ngIf="profile.data[0].image_profile !== ''" [src]="image_profile" class="profile-img" alt="" fetchpriority="low" />
            </div>
            <div class="info-wrapper">
              <div *ngIf="token && profile">
                <p class="carfinn-primary-blue-text m-0" style="font-size: 22px;font-weight: 500;color: #0A428F;">
                  {{ profile.data[0].name !== "" ? profile.data[0].name : "-" }}
                  {{
                  profile.data[0].surname !== ""
                  ? profile.data[0].surname
                  : ""
                  }}
                </p>
                <a routerLink="/profile" class="carfinn-primary-blue-text user-detail"
                  style="text-decoration: none;font-size: 18px;font-weight: 500;color: #0A428F;">
                  <i class="bi bi-eye mr-2" style="font-size: 16px"></i>
                  เข้าดูโปรไฟล์ของฉัน
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <button (click)="confirmLogoutPop = !confirmLogoutPop" class="buttonLogout">
              <i class="bi bi-box-arrow-left mr-2"></i>
              ออกจากระบบ
            </button>
          </div>
        </div>
        <div *ngIf="confirmLogoutPop" class="container">
          <p class="text-left text-danger">ยืนยันการออกจากระบบ</p>
          <p class="text-left">คุณต้องลงชื่อออกจากระบบใช่หรือไม่ ?</p>
          <div class="d-flex flex-row-reverse">
            <button (click)="visiblepop = false" class="btn carfinn-btn-outline-blue">
              ยกเลิก
            </button>
            <button (click)="logout()" class="btn carfinn-btn-blue mr-3">
              ใช่
            </button>
          </div>
        </div>
      </ng-template>
    </li>
    <li *ngIf="!token"
      class="nav-item my-auto mx-md-2 mx-sm-2 mx-2 d-none d-xxl-block d-xl-block d-lg-block d-md-block">
      <!-- color icon -->
      <div class="d-flex" style="justify-content: space-evenly; align-items: center; width: 400px">
        <a href="https://www.facebook.com/carfiinn/" target="_blank" id="carfinn-facebook">
          <img class="img-social" style="height: 30px" src="assets/social-logo/facebook-circle.webp"
            alt="facebook-white" fetchpriority="low" />
        </a>
        <a href="https://twitter.com/c_carfinn" target="_blank" id="carfinn-twitter" style="background-color: rgb(0, 0, 0);color: white;padding: 5px; border-radius: 50%;color: #01213C
          ;">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-twitter-x"
            style="color: white;" viewBox="0 0 16 16">
            <path
              d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
          </svg>
        </a>
        <a href="https://www.instagram.com/carfinn_official/" target="_blank" id="carfinn-instagram">
          <img class="img-social" style="height: 30px" src="assets/social-logo/instagram-circle.webp"
            alt="instagram-white" fetchpriority="low" />
        </a>
        <a href="https://www.tiktok.com/@carfinn.official" target="_blank" id="carfinn-tiktok">
          <img class="img-social" style="height: 30px" src="assets/social-logo/tiktok-circle.webp" alt="tiktok-white" fetchpriority="low" />
        </a>
        <a href="https://lin.ee/sfqF1Vo" target="_blank" id="carfinn-line">
          <img class="img-social" src="assets/social-logo/line-circle.webp" alt="line-white" style="height: 30px" fetchpriority="low" />
        </a>
        <a href="https://www.youtube.com/@carfinn" target="_blank" id="carfinn-youtube">
          <img class="img-social" style="height: 30px" src="assets/social-logo/youtube-circle.webp"
            alt="youtube-white" fetchpriority="low" />
        </a>
        <a class="nav-link" (click)="login()"><span
            style="font-size: 18; font-weight: 500; color: white">เข้าสู่ระบบ</span></a>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="open()">
        <i nz-icon nzType="menu" nzTheme="outline" style="font-size: 23px"></i>
      </a>
    </li>
  </ul>
</nav>

<carfinn-sidebar [visible]="visible" [token]="token" [profile]="profile"></carfinn-sidebar>
