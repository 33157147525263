import {AfterViewInit, Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appSelectWidth]'
})
export class SelectWidthDirective {

  constructor(private ElementRef: ElementRef, private Render2: Renderer2) {
  }

  ngAfterViewInit() {
      setTimeout(() => {
          const {width} = this.ElementRef.nativeElement.getBoundingClientRect();
          console.log(width);
          this.Render2.setStyle(this.ElementRef.nativeElement, 'width', width + 'px')
      }, 200)

  }
}
