import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  tabIndex: number

  menuItem = ['ประวัติความเป็นมา', 'โครงสร้างองค์กร', 'คณะกรรมการบริษัท']

  banner = [
    { imageUrl: 'assets/about-us/about-us-banner.webp', name: 'about-us-banner' },
  ];

  bannerOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    margin: 15,
    autoplay: false,
    dots: false,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      760: { items: 1 },
      1000: { items: 1 }
    }
  };


  constructor() { }

  ngOnInit(): void {
    this.tabIndex = 0
  }

  onTabIndexChange(index: number) {
    this.tabIndex = index
  }

}
