import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})
export class ModalSuccessComponent implements OnInit {
  modalData: any;
  isConfirm: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalSuccessComponent>,
  ) {
    this.modalData = data;
  }

  ngOnInit(): void {
  }

  confirm() {
    const response = {
      status: true
    }
    this.dialogRef.close(response);

  }

  closeModal() {
    const response = {
      status: false
    }
    this.dialogRef.close(response);
  }


}
