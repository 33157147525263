import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { PolicyComponent } from './modules/policy/policy.component';
import { RequestSuccessComponent } from './modules/request-success/request-success.component';
import { RegisterComponent } from './modules/register/register.component';
import { LoginGuard } from './core/guards/login.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { ForgetPasswordComponent } from './modules/forget-password/forget-password.component';
import { SetPasswordComponent } from './modules/forget-password/set-password/set-password.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { ArticlesComponent } from './modules/articles/articles.component';
import { ArticleContentComponent } from './modules/articles/pages/article-content/article-content.component';
import { ComingSoonComponent } from './shared/components/coming-soon/coming-soon.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { NewMoneyChangerComponent } from './shared/components/new-money-changer/new-money-changer.component';
import { CalculatorComponent } from './modules/home/components/calculator/calculator.component';

const routes: Routes = [
  {
    path: '',
    // component: HomeComponent
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    title: 'เข้าสู่ระบบ | Carfinn',
  },
  {
    path: 'register',
    component: RegisterComponent,
    title: 'ลงทะเบียน | Carfinn',
  },
  {
    path: 'car-loan',
    loadChildren: () =>
      import('./modules/car-loan/car-loan.module').then((m) => m.CarLoanModule),
  },
  {
    path: 'policy',
    component: PolicyComponent,
    title: 'นโยบายความเป็นส่วนตัว | CarFinn',
  },
  {
    path: 'request-success',
    component: RequestSuccessComponent,
    title: 'ขอบคุณที่ใช้บริการคาร์ฟินน์',
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
    // component: UserProfileComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'set_password',
    component: SetPasswordComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'articles',
    children: [
      {
        path: '',
        component: ArticlesComponent,
      },
      {
        path: ':articleId',
        component: ArticleContentComponent,
      },
    ],
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    title: 'รู้จักกับเรา | Carfinn',
  },
  {
    path: 'vision',
    loadChildren: () =>
      import('./modules/vision/vision.module').then((m) => m.VisionModule),
    title: 'วิสัยทัศน์ | CarFinn',
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
  },
  {
    path: 'calculate',
    component: CalculatorComponent,
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'propfinn',
    loadChildren: () =>
      import('./modules/propfinn/propfinn.module').then(
        (m) => m.PropfinnModule
      ),
  },
  // {
  //   path: 'investor-relations',
  //   loadChildren: () =>
  //     import('./modules/investor-relations/investor-relations.module').then(
  //       (m) => m.InvestorRelationsModule
  //     ),
  //   title: 'สำหรับนักลงทุน | CarFinn',
  // },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   component: NotFoundComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
