import { Pipe, PipeTransform } from '@angular/core';

let longThaiMonth = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

let shortThaiMonth = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
];
@Pipe({
  name: 'date',
})
export class DatePipe implements PipeTransform {
  transform(date: string, format: string): unknown {
    let inputDate = new Date(date);
    let dataDate = [
      inputDate.getDay(),
      inputDate.getDate(),
      inputDate.getMonth(),
      inputDate.getFullYear(),
    ];
    let outputDateFull = [
      dataDate[1] + ' ' + longThaiMonth[dataDate[2]],
      +(dataDate[3] + 543),
    ];
    let outputDateMedium = [
      dataDate[1],
      longThaiMonth[dataDate[2]],
      dataDate[3] + 543,
    ];

    let returnDate: string;
    returnDate = outputDateMedium.join(' ');
    if (format == 'full') {
      returnDate = outputDateFull.join(' ');
    } else if (format === 'DD/MM/YYYY') {
      return `${dataDate[1]}/${dataDate[2] + 1}/${dataDate[3]}`;
    }
    return returnDate;
  }
}

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  transform(date: string, format: string): unknown {
    // // // console.log(date);
    // // // console.log(new Date(date[0]['dateTime'][0]));

    let inputDate = new Date(date);
    // // // console.log(inputDate);

    let dataDate = [
      inputDate.getDay(),
      inputDate.getDate(),
      inputDate.getMonth(),
      inputDate.getFullYear(),
      inputDate.getHours(),
      (inputDate.getMinutes() < 10 ? '0' : '') + inputDate.getMinutes(),
    ];
    let outputDateMedium = [
      dataDate[1] + ' ' + shortThaiMonth[dataDate[2]],
      +(+dataDate[3] + 543).toString().substr(2, 2) +
        ' ' +
        dataDate[4] +
        ':' +
        dataDate[5] +
        ' น.',
    ];
    let outputDateFull = [
      dataDate[1],
      longThaiMonth[dataDate[2]],
      +dataDate[3] + 543,
      ' ' + dataDate[4] + ':' + dataDate[5] + ' น.',
    ];
    let outputDateShort = [dataDate[4] + ':' + dataDate[5]];
    let outputDateShortNotime = [
      dataDate[1] + ' ' + shortThaiMonth[dataDate[2]] + ' ',
      +(+dataDate[3] + 543).toString().substr(2, 2),
    ];
    let outputDateFullNoTime = [
      dataDate[1],
      longThaiMonth[dataDate[2]],
      +dataDate[3] + 543,
    ];

    let returnDate: string;
    returnDate = outputDateMedium.join(' ');
    if (format == 'full') {
      returnDate = outputDateFull.join(' ');
    }
    if (format == 'time') {
      returnDate = outputDateShort.join(' ');
    }
    if (format == 'shortNoTime') {
      returnDate = outputDateShortNotime.join(' ');
    }
    if (format == 'FullNoTime') {
      returnDate = outputDateFullNoTime.join(' ');
    }

    return returnDate;
  }
}

@Pipe({
  name: 'dateTimeAgo',
})
export class DateTimeAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      const date = new Date(value);

      if (seconds >= 86400)
        return `${date.getDate()} ${longThaiMonth[date.getMonth()]} ${
          date.getFullYear() + 543
        } ${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${
          date.getMinutes() < 10 ? '0' : ''
        }${date.getMinutes()} น.`;
      else if (seconds < 86400 && seconds >= 3600)
        return Math.floor(seconds / 3600) + ' ชั่วโมงที่แล้ว';
      else if (seconds < 3600 && seconds >= 60)
        return Math.floor(seconds / 60) + ' นาทีที่แล้ว';
      else if (seconds < 60)
        if (seconds < 30) return 'เมื่อสักครู่';
        else return seconds + ' วินาทีที่แล้ว';
    }
  }
}

@Pipe({
  name: 'convertDate',
})
export class convertDate implements PipeTransform {
  transform(date: string, format: string): unknown {
    const split = date.split(/[-/]/);
    const _index = parseInt(split[1]);
    const year = parseInt(split[2]) + 543;

    if (format === 'full') {
      return `${split[0]} ${shortThaiMonth[_index - 1]} ${year}`;
    }

    if (format === 'short') {
      return `${split[0]} ${shortThaiMonth[_index - 1]} ${year
        .toString()
        .slice(-2)}`;
    }
  }
}

@Pipe({
  name: 'timeFormatter',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return null;
    const [hours, minutes, seconds] = value.split(':');
    return `${hours}.${minutes} น.`;
  }
}
