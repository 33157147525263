<div class="container">
  <div class="row d-flex justify-content-center" style="row-gap: 20px">
    <div class="col-lg-4 col-12" data-cy="picture1">
      <img
        class="lazyload"
        fetchpriority="low"
        src="assets/tab-home/image 520.webp"
        alt=""
        style="border-radius: 5px; width: 100%; aspect-ratio: 397/286;"
      />
    </div>

    <div
      class="col-lg-8 col-12 discript-text d-flex flex-column justify-content-evenly"
      style="
        font-size: 23px;
      "
    >
      <p data-cy="text7" style="margin: 10px 0; color: #0a428f">
        เป็นการทำสินเชื่อรูปแบบหนึ่ง
        <span style="font-weight: 500">
          ที่นำอสังหาริมทรัพย์มาเป็นประกันในการชำระหนี้
        </span>
        โดย ไม่ส่งมอบทรัพย์สินนั้นให้แก่ผู้จำนอง
      </p>
      <p data-cy="text8" style="margin: 10px 0">
        ซึ่งผู้จำนอง(ผู้กู้)ที่ต้องการทำธุรกรรม ต้องไปที่สำนักงานที่ดินเพื่อ
        <span style="font-weight: 500; color: #0a428f">
          จดนิติกรรมสัญญาผู้รับจำนองกับคู่สัญญาผู้จำนองต่อหน้าพนักงานเจ้าหน้าที่กรมที่ดินเท่านั้น</span
        >
      </p>
    </div>
  </div>
  <div class="row d-flex part-2" style="margin-top: 10px; row-gap: 20px">
    <div
      class="col-lg-8 col-12 discript-text"
      style="
        font-size: 25px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
      "
    >
      <p data-cy="text9">
        <span style="font-weight: 500; color: #0a428f">
          แต่กรรมสิทธิ์ในทรัพย์สินยังคงเป็นของผู้จำนอง (ผู้กู้) อยู่ </span
        ><br />
        ซึ่งผู้จำนองสามารถทำประโยชน์หรือถือครองใช้ประโยชน์ได้ตามปกติ
      </p>
    </div>

    <div class="col-lg-4 col-12" data-cy="picture2">
      <img
        class="lazyload"
        fetchpriority="low"
        src="assets/tab-home/image 521.webp"
        alt=""
        style="border-radius: 5px; width: 100%; height: auto; aspect-ratio: 397/207;"
      />
    </div>
  </div>
</div>