import { Component, Input, OnInit } from '@angular/core';
interface OfUs {
  img: string,
  headText: string,
  number: number,
  footerText: string
}
@Component({
  selector: 'app-of-us',
  templateUrl: './of-us.component.html',
  styleUrls: ['./of-us.component.scss']
})
export class OfUsComponent implements OnInit {

  @Input() totalLoanAmout: number;
  @Input() registAmout: number;

  // OfUsList: OfUs[];

  constructor() { }

  ngOnInit(): void {
    // this.OfUsList = [
    //   {
    //     img: "assets/static/group_1.webp",
    //     headText: "ผู้สมัครสินเชื่อกับเรา",
    //     number: this.registAmout,
    //     footerText: "ราย"
    //   },
    //   {
    //     img: "assets/static/money_1.webp",
    //     headText: "วงเงินอนุมัติทั้งหมด",
    //     number: this.totalLoanAmout,
    //     footerText: "ล้านบาท"
    //   },
    //   {
    //     img: "assets/static/relationship_1.webp",
    //     headText: "จากไฟแนนซ์ทั้งหมด",
    //     number: 20,
    //     footerText: "สถาบัน"
    //   },
    // ]
  }

}
