<div class="mt-4">
  <p style="font-size: 22px; font-weight: 500" class="text-center">
    กรุณาเข้าสู่ระบบหรือสมัครสมาชิก<br />
    เพื่อสมัครสินเชื่อกับคาร์ฟิน
  </p>
  <div class="row d-flex justify-content-center">
    <div class="col-lg-auto col-12 m-1 d-flex justify-content-center">
      <button class="login-button" (click)="redirectToLogin()" nz-button>
        เข้าสู่ระบบ
      </button>
    </div>
    <div class="col-lg-auto col-12 m-1 d-flex justify-content-center">
      <button class="reg-button" (click)="redirectToRegister()" nz-button>
        สมัครสมาชิก
      </button>

    </div>
  </div>
</div>
