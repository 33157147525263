import { Component, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button-banner',
  templateUrl: './button-banner.component.html',
  styleUrls: ['./button-banner.component.scss']
})
export class ButtonBannerComponent implements OnInit {

  @Input() buttonText2: string;
  @Input() contactScrolling: string;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  toLine() {
    window.open('https://lin.ee/sfqF1Vo', '_blank');
  }

  scrollToSection(sectionId: string = this.contactScrolling) {
    console.log(this.contactScrolling);
    console.log(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop - (window.innerHeight * 0.1);
      this.renderer.setProperty(document.documentElement, 'scrollTop', offset);
    }
  }

}
