import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

interface Status {
  code: number;
  description: string;
}

interface resHotcall {
  status: Status;
  data: string;
}

interface resGetProvince {
  status: Status;
  data: [
    {
      area: string;
      district: string;
      province: string;
      zipcode: number;
    }
  ];
}

interface resGetHome {
  status: Status;
  data: {
    sec_brand: [
      {
        id: number;
        image_name: string;
        floder: string;
        type: string;
        number: number;
        active: number;
        detail: string;
        image_url: string;
      }
    ];
    sec_bank: [
      {
        id: number;
        image_name: string;
        floder: string;
        type: string;
        number: number;
        active: number;
        detail: string;
        image_url: string;
      }
    ];
    sec_hightlight: [
      {
        id: number;
        image_name: string;
        floder: string;
        type: string;
        number: number;
        active: number;
        detail: string;
        image_url: string;
      }
    ];
    sec_leasing: [
      {
        id: number;
        image_name: string;
        floder: string;
        type: string;
        number: number;
        active: number;
        detail: string;
        image_url: string;
      }
    ];
    sec_advertising_media: [
      {
        image_name: string;
        image_url: string;
      }
    ];
    sec_summarize: [
      {
        total_loan_amount: number;
        total_registed_user: number;
      }
    ]
    
    // sec_product: [
    //   {
    //     id: number,
    //     image_name: string,
    //     floder: string,
    //     type: string,
    //     number: number,
    //     active: number,
    //     detail: string,
    //     image_url: string,
    //   }
    // ],
  };
}

interface resGetCar {
  status: Status;
  data: [
    {
      car_brand: string;
      logo: string;
      create_date: string;
      update_date?: any;
      create_by: string;
      update_by?: any;
      csv: string;
      priority: number;
    }
  ];
}

interface resGetModel {
  status: Status;
  data: [{ car_model: string }];
}

interface resGetYear {
  status: Status;
  data: [{ car_year: string }];
}

interface resGetGear {
  status: Status;
  data: [{ car_gear: string }];
}

interface resGetEngine {
  status: Status;
  data: [{ car_engine: string }];
}

interface resGetClass {
  status: Status;
  data: [{ car_class: string }];
}

interface resGetCarType {
  status: Status;
  data: [
    {
      car_type: string;
      priority: number;
    }
  ];
}

interface restGetFaq {
  status: Status;
  data: {
    trust: [
      {
        title: string | null;
        text: string | null;
      }
    ];
    frequently_ask: [
      {
        title: string | null;
        text: string | null;
      }
    ];
  };
}

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient) {}

  getAddress(): any {
    return this.http.get<resGetProvince>(
      environment.URL + '/thaiaddress/get_address'
    );
  }

  createHotCall(body) {
    return this.http.post<resHotcall>(
      environment.URL_FRONT + '/carjobs/create_hotcall',
      body
    );
  }

  getWebHome() {
    return this.http.get<resGetHome>(
      environment.URL_FRONT + `/home/web_get_home`
    );
  }

  getCarBrand() {
    return this.http.get<resGetCar>(environment.URL + `/car/brand`);
  }

  getCarModel(brand) {
    return this.http.get<resGetModel>(
      environment.URL + `/car/model?car_brand=${brand}`
    );
  }

  getCarYear(brand, model) {
    return this.http.get<resGetYear>(
      environment.URL + `/car/year?car_brand=${brand}&car_model=${model}`
    );
  }

  getCarGear(brand, model, year) {
    return this.http.get<resGetGear>(
      environment.URL +
        `/car/gear?car_brand=${brand}&car_model=${model}&car_year=${year}`
    );
  }

  getCarEngine(brand, model, year, gear) {
    return this.http.get<resGetEngine>(
      environment.URL +
        `/car/engine?car_brand=${brand}&car_model=${model}&car_year=${year}&car_gear=${gear}`
    );
  }

  getCarClass(brand, model, year, gear, engine) {
    return this.http.get<resGetClass>(
      environment.URL +
        `/car/class?car_brand=${brand}&car_model=${model}&car_year=${year}&car_gear=${gear}&car_engine=${engine}`
    );
  }

  getCarType() {
    return this.http.get<resGetCarType>(environment.URL + `/car/car_type`);
  }

  getArticles() {
    return this.http.get<any>('assets/articles/articles.json');
  }

  getArticleById(id) {
    return this.http.get<any>(
      environment.URL_FRONT + `/article/getArticle?id=${id}`
    );
  }

  getAllArticle() {
    return this.http.get<any>(
      environment.URL_FRONT + `/article/getAllArticles`
    );
  }

  getAllArticleV2(tabName: string = 'all') {
    const options = {
      params: new HttpParams().set('tab_name', tabName),
    }
    return this.http.get<any>(
      environment.URL_FRONT + `/article/getAllArticlesV2`,
      options
    );
  }

  getFaq() {
    return this.http.get<restGetFaq>(
      environment.URL_FRONT + `/home/web_get_faq`
    );
  }
}
