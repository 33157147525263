<carfinn-navbar-transparent></carfinn-navbar-transparent>

<div class="container py-5" style="margin-top:70px;">
  <div class="row d-flex justify-content-center">
    <div class="col-12 header ">
      <p class="main-header text-left ml-5 mt-3">
        <span>เครื่องคำนวณ</span>
      </p>
    </div>
    <div class="col-12">
      <h2 class="text-left ex-text mt-3 carfinn-primary-blue-text">
        ตัวอย่างการคำนวณดอกเบี้ยและค่าธรรมเนียม
      </h2>
      <p class="text-left ex-subtext mt-3 carfinn-primary-blue-text">
        *ตัวอย่างการคำนวณดอกเบี้ยและค่าธรรมเนียมนี้ เป็นการคำนวณเมื่อลูกค้าจ่ายค่างวดตรงตามกำหนด ตลอดอายุสัญญา
      </p>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
      <form [formGroup]="calculationForm">
        <div class="row mt-3 px-lg-3 px-md-3 px-sm-1 px-1">
          <div class="col-6">
            <nz-form-item>
              <nz-form-control>
                <nz-form-label>
                  สินเชื่อ
                </nz-form-label>
                <nz-select id="loan_type" nzPlaceHolder="โปรดระบุ" formControlName="loan_type">
                  <nz-option *ngFor="let lt of loanTypeSelect" [nzLabel]="lt" [nzValue]="lt">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control>
                <nz-form-label>
                  ระยะเวลาการผ่อนชำระ (เดือน)
                </nz-form-label>
                <nz-select id="numberOfInstallment" nzPlaceHolder="โปรดระบุ" formControlName="numberOfInstallment">
                  <nz-option *ngFor="let nt of numberOfInstallmentSelect" [nzLabel]="nt + ' เดือน'" [nzValue]="nt">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-6">
            <nz-form-item>
              <nz-form-control>
                <nz-form-label>เงินต้น (บาท)</nz-form-label>
                <nz-input-number [nzFormatter]="formatterPrice" formControlName="principle" id="principle" [nzMin]="0"
                  [nzPlaceHolder]="''">
                </nz-input-number>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control>
                <nz-form-label>อัตราดอกเบี้ย (%)</nz-form-label>
                <nz-input-number [nzFormatter]="formatterPrice" formControlName="rate" id="rate" [nzMin]="0"
                  [nzPlaceHolder]="''">
                </nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-end px-lg-3 px-md-3 px-sm-1 px-1">
        <button (click)="clearCal()" class="btn btn-cancel m-3">ล้างข้อมูล</button>
        <button *ngIf="calculationForm.valid" (click)="onCalculate(calculationForm.value)"
          class="btn btn-submit my-3">คำนวณ</button>
        <button *ngIf="!calculationForm.valid" class="btn btn-submit my-3 disabled">คำนวณ</button>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
      <div class="cal-section">
        <img class="img-fluid mx-auto d-block img-cal" src="assets/home-image/machine.png" alt="">

        <!-- desktop -->
        <div *ngIf="isCal" class="section-card d-none d-xxl-block d-xl-block d-lg-block d-md-none">
          <div class="card cal-card mx-auto py-lg-5 py-md-5 py-sm-5 py-5 px-lg-5 px-md-5 px-sm-5 px-2">
            <p class="title-text">
              ผลการคำนวณ
            </p>
            <div class="d-flex">
              <span class="first-text">ยอดผ่อนชำระต่อเดือน</span>
              <span class="amount-text">{{ interest_price | number:'1.0-0' }}</span>
              <span class="end-text">บาท</span>
            </div>
            <div class="triangle-left"></div>
          </div>
        </div>

        <!-- mobile -->
        <div *ngIf="isCal" class="section-card d-block d-sm-block d-md-block d-xxl-none d-xl-none d-lg-none">
          <div class="card cal-card mx-auto py-lg-5 py-md-5 py-sm-5 py-4 px-lg-5 px-md-5 px-sm-5 px-2">
            <p class="title-text">
              ผลการคำนวณ
            </p>
            <div class="d-flex">
              <span class="first-text">ยอดผ่อนชำระต่อเดือน</span>
              <span class="amount-text">{{ interest_price | number:'1.0-0' }}</span>
              <span class="end-text">บาท</span>
            </div>
            <div class="triangle-left"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<carfinn-footer></carfinn-footer>