<div class="container">
  <div *ngFor="let panel of dataRender;let i = index" style="margin:20px auto;">

    <button class="text-header" (click)="activePanel(i)" [ngClass]="{active:panel.active}">
      <span [attr.data-cy]="'FAQ ' + (i+1)">{{panel.title}}</span>
      <img [attr.data-cy]="'FAQ ' + (i+1) + ' bn'" src="assets/faq/+.png" height="20px" alt="+" *ngIf="!panel.active; else minus">
      <ng-template #minus>
        <img [attr.data-cy]="'FAQ ' + (i+1) + ' bn'" src="assets/faq/-.png" height="5px" alt="-">
      </ng-template>
    </button>

    <div class="text-sub" *ngIf="panel.active">
      <p [attr.data-cy]="'ANS ' + (i+1)" style="margin-bottom: 0; line-height: 1.25;" [innerHTML]="panel.text"></p>
    </div>
  </div>

</div>