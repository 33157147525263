import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectService } from 'src/app/core/services/redirect.service';

@Component({
  selector: 'carfinn-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.scss']
})
export class LoginBoxComponent implements OnInit {

  redirectUrl: string;
  queryParams: any;

  constructor(private router: Router, private route: ActivatedRoute, private redirectService: RedirectService) { }

  ngOnInit(): void {
  }

  setRedirectUrl() {
    // Capture the current URL and query parameters
    const url = this.router.url;
    const urlSegments = url.split('?');
    this.redirectUrl = urlSegments[0];
    this.queryParams = this.route.snapshot.queryParams
  }

  setLocalStorage() {
    this.redirectService.setLocalStorage(this.redirectUrl, this.queryParams);
  }

  login() {
    this.setRedirectUrl();
    this.setLocalStorage();
    this.router.navigate(['/login']);
  }

  regis() {
    this.setRedirectUrl();
    this.setLocalStorage();
    this.router.navigate(['/register']);
  }

}
