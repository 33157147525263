<div class="container-fluid background p-0">
  <carfinn-navbar-transparent></carfinn-navbar-transparent>

  <div class="container pt-5 py-3 secNav">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="login-section">
          <div class="row d-flex justify-content-center" style="height: 100%">
            <div
              class="col-lg-5 col-md-12 col-sm-12 col-12 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block"
            >
              <div class="left-section">
                <div class="top-left">
                  <p class="text-center text-left-section">ยินดีต้อนรับสู่</p>
                  <p class="text-center text-left-section">CarFinn</p>
                </div>
                <div class="end-left">
                  <p class="text-center text-left-section">
                    <i>แพลตฟอร์มรวมสินเชื่อรถยนต์</i>
                  </p>
                  <p class="text-center text-left-section">
                    <i>
                      <span style="font-size: 20px">ฟินเทคระดับโลก </span>
                      <span class="">กับการเข้าถึง</span>
                    </i>
                  </p>
                  <p class="text-center text-left-section">
                    <i>สินเชื่อรถยนต์ออนไลน์</i>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div
                class="main-section py-lg-3 pl-lg-1 pr-lg-4 py-md-3 pl-md-3 pr-md-3 py-sm-3 pl-sm-3 pr-sm-3 py-3 pl-3 pr-3"
              >
                <h1 class="text-color m-3" style="text-align: center">
                  {{ "LOGIN.TITLE" | translate }}
                </h1>

                <nz-alert
                  *ngIf="isError"
                  class="my-3"
                  nzType="error"
                  [nzMessage]="errorMessage"
                  nzShowIcon
                ></nz-alert>

                <form
                  [formGroup]="loginForm"
                  nz-form
                  class="login-form"
                  (keydown.enter)="systemLogin()"
                >
                  <nz-form-item>
                    <nz-form-control [nzErrorTip]="ErrorTips">
                      <label for="email">อีเมลหรือเบอร์โทรศัพท์</label>
                      <nz-input-group
                        nzPrefixIcon="user"
                        style="height: 40px; border-radius: 5px"
                      >
                        <input
                          formControlName="emailorphone"
                          type="text"
                          nz-input
                          nzSize="large"
                          [placeholder]="'LOGIN.EMAIL_OR_PHONE' | translate"
                          autocomplete="off"
                          (ngModelChange)="validate($event)"
                        />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-control nzErrorTip="โปรดระบุ">
                      <label for="email">รหัสผ่าน</label>
                      <nz-input-group
                        nzPrefixIcon="lock"
                        [nzSuffix]="suffixIconPassword"
                        style="height: 40px; border-radius: 5px"
                      >
                        <input
                          formControlName="password"
                          [type]="passwordVisible ? 'text' : 'password'"
                          nz-input
                          nzSize="large"
                          [placeholder]="'LOGIN.PASSWORD_LABEL' | translate"
                          autocomplete="off"
                        />
                      </nz-input-group>
                      <ng-template #suffixIconPassword>
                        <i
                          nz-icon
                          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          nzTheme="fill"
                          (click)="passwordVisible = !passwordVisible"
                          style="cursor: pointer"
                        ></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </form>

                <label nz-checkbox [(ngModel)]="remember"> จำชื่อผู้ใช้ </label>
                <div class="d-flex justify-content-center">
                  <a href="forget-password" class="text-center text-secondary">
                    <u>ลืมรหัสผ่าน ?</u>
                  </a>
                </div>

                <carfinn-btn
                  (click)="systemLogin()"
                  label="LOGIN.TITLE"
                  customStyle="background: #03428e; color: white; width: 100%; height: 40px; margin-top: 10px"
                >
                </carfinn-btn>

                <!-- <div class="choice-section">
                  <p class="choice-text">
                    <span>หรือ</span>
                  </p>
                </div> -->

                <div class="d-flex justify-content-center mb-4"></div>

                <div class="d-flex justify-content-center mt-3">
                  <p class="text-center">ยังไม่ได้ลงทะเบียน ?</p>
                  <a (click)="register()" class="register">สร้างบัญชี</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
    <img
      src="assets/login-page/building.png"
      class="bg-image-building"
      alt="building"
    />
    <img src="assets/login-page/car.png" class="bg-image-car" alt="car" />
    <img src="assets/login-page/text.png" class="bg-image-text" alt="text" />
    <img src="assets/login-page/home.png" class="bg-image-home" alt="home" />
  </div>
</div>

<div *ngIf="isLoading">
  <div
    style="
      position: fixed;
      z-index: 900;
      width: 100vw;
      height: 100vh;
      opacity: 80;
      top: 0;
      left: 0;
      background: #e8e8e880;
    "
  >
    <nz-spin
      nzSimple
      [nzSize]="'large'"
      style="
        z-index: 1000;
        padding: 400px 50px;
        margin: 20px 0;
        text-align: center;
      "
    ></nz-spin>
  </div>
</div>
