<div class="container py-lg-4 py-md-3 py-1">
  <!-- <div class="row mb-4">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 header ">
            <p class="main-header text-left ml-5">
                <span>ความประทับใจจากลูกค้าของเรา</span>
            </p>
        </div>
    </div> -->

  <div class="row d-flex justify-content-center px-lg-4 px-md-4 px-0">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let review of allReview">
        <ng-template carouselSlide>
          <!-- <img [src]="p.image" [id]="p.name" class="mx-auto d-block partner-img img-fluid" [alt]="p.name"> -->
          <div class="review-section my-lg-3 my-md-3 my-sm-3 my-3 mx-auto">
            <div class="card review-card">
              <div class="profile mb-lg-2 mb-md-2 mb-0">
                <!-- <div class="profile-pic">
                                    <img [src]="review.image" alt="">
                                </div> -->
                <div class="username">
                  <strong>{{ review.name }}</strong>
                  <span>({{ review.job }})</span>
                </div>
              </div>
              <div class="text-center">
                <ngb-rating [(rate)]="review.rate" readonly="true" max="5">
                  <ng-template let-fill="fill" let-index="index">
                    <i
                      class="bi-star{{ fill === 100 ? '-fill' : '' }}"
                      [class.filled]="fill === 100"
                    ></i>
                  </ng-template>
                </ngb-rating>
              </div>
              <div class="content-section">
                <div class="comma-top"><i class="bi bi-quote"></i></div>
                <div class="content-text p-3">
                  {{ review.review }}
                </div>
                <div class="comma-bottom"><i class="bi bi-quote"></i></div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="date">
                  <span class="date-text">{{
                    review.date ? (review.date | dateTime : "shortNoTime") : ''
                  }}</span>
                </div>
                <div class="tag">
                  {{ review.review_type }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <!-- <div class="col-lg-3 col-md-6 col-sm-12 col-12" *ngFor="let review of allReview">

        </div> -->
  </div>
</div>
