import { GlobalFunctionService } from 'src/app/core/services/global-function.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { ModalSettingCookiesComponent } from './modal-setting-cookies/modal-setting-cookies.component';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

declare const gtag: Function;

@Component({
  selector: 'carfinn-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  @Output() closeBanner = new EventEmitter<boolean>();

  constructor(
    private cookieService: CookieService,
    public matDialog: MatDialog,
    private globalFunctionService: GlobalFunctionService
  ) { }

  ngOnInit(): void { }

  acceptAllCookies() {
    this.cookieService.set('cookie_banner', 'close');
    if (environment.production) {
      this.cookieService.set(
        'OptIn',
        'strictly=accept&performance=accept&target=accept'
      );
      this.globalFunctionService.addGA()
    }

    this.closeBanner.emit(true);
  }

  settingCookies() {
    const setCookiesDialogConfig = new MatDialogConfig();
    setCookiesDialogConfig.autoFocus = false;
    setCookiesDialogConfig.width = '550px';

    const setCookiesModal = this.matDialog.open(
      ModalSettingCookiesComponent,
      setCookiesDialogConfig
    );
    setCookiesModal
      .afterClosed()
      .pipe(
        map((consent) => {
          if (consent.status) {
            if (consent.accept === 'all') {
              this.acceptAllCookies();
              // // console.log('all')
            } else {
              this.checkOptionCookies(consent);
            }
            // this.cookieService.set('cookie_banner', 'close');
            // this.cookieService.set('consent', 'accept');
            // this.closeBanner.emit(true);
          } else {
            return of(null);
          }
        }),
        catchError((err) => {
          return of(false);
        })
      )
      .subscribe();
  }

  checkOptionCookies(consent) {
    this.cookieService.set('cookie_banner', 'close');
    if (consent.strictly && !consent.performance && !consent.target) {
      if (environment.production) {
        this.cookieService.set(
          'OptIn',
          'strictly=accept&performance=deny&target=deny'
        );
      }

      this.closeBanner.emit(true);
    } else if (consent.strictly && consent.performance && !consent.target) {
      if (environment.production) {
        // this.cookieService.set('cookie_banner', 'close');
        this.cookieService.set(
          'OptIn',
          'strictly=accept&performance=accept&target=deny'
        );
        this.globalFunctionService.addGA()
      }

      this.closeBanner.emit(true);
    }
    //  else if (consent.strictly && !consent.performance && consent.target) {
    //   // console.log('3');
    // } else if (consent.strictly && consent.performance && consent.target) {
    //   // console.log('4');
    // }
    else {
      return of(null);
    }
  }
}
