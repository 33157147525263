<div class="layout-config" [class.layout-config-active]="visible">
  <div class="layout-config-content-wrapper">
    <div class="layout-config-content">
      <div class="container profile-container">
        <div
          class="row profile-section d-flex"
          style="justify-content: space-evenly"
        >
          <div class="col-12 d-flex justify-content-end">
            <i
              nz-icon
              nzType="close"
              class="close-icon"
              nzTheme="outline"
              (click)="close()"
            ></i>
          </div>
          <div class="col-4" style="align-content: center">
            <div *ngIf="token && profile">
              <img
                *ngIf="profile.data[0].image_profile !== ''"
                [src]="profile.data[0].image_profile"
                alt="user-profile"
                class="profilePic rounded-circle img-fluid"
              />
            </div>
            <div *ngIf="!token">
              <nz-avatar
                nzIcon="user"
                [nzSize]="76"
                style="width: 76px; height: 76px"
              ></nz-avatar>
            </div>
          </div>
          <div class="col-7">
            <div *ngIf="token && profile">
              <p
                class=""
                style="
                  color: #0a428f;
                  font-size: 22px;
                  font-weight: 500;
                  margin: 2% !important;
                "
                data-cy="name"
              >
                {{ profile.data[0].name !== "" ? profile.data[0].name : "-" }}
                {{
                  profile.data[0].surname !== "" ? profile.data[0].surname : ""
                }}
              </p>
              <a
                routerLink="/profile"
                class="user-email"
                data-cy="my profile"
                style="
                  text-decoration: none;
                  color: #0a428f;
                  font-size: 16px;
                  font-weight: 500;
                "
              >
                <i
                  class="bi bi-eye mr-1"
                  style="font-size: 16px; color: #0a428f"
                ></i>
                เข้าดูโปรไฟล์ของฉัน
              </a>
            </div>
            <div *ngIf="!token" class="">
              <p
                class="carfinn-primary-blue-text"
                style="font-size: 18px; line-height: 1.25"
              >
                คุณยังไม่ได้เข้าสู่ระบบ
              </p>
              <button
                (click)="login()"
                class="btn btn-blue"
                style="font-size: 18px; line-height: 1.25"
              >
                <i class="bi bi-box-arrow-right"></i>
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="token" class="d-flex col-12 justify-content-center my-2">
          <button (click)="showLogout()" class="buttonLogout" style="">
            <i class="bi bi-box-arrow-left mr-2"></i>
            ออกจากระบบ
          </button>
        </div>
      </div>
      <div class="container">
        <p
          class="menu-text container pr-5 py-2"
          data-cy="car loan"
          style="border-bottom: solid 1px #d9d9d9"
        >
          <a
            href="javascript:void(0);"
            class="menu-text"
            style="text-decoration: none"
            (click)="scrollToSection('carLoan')"
          >
            {{ "SIDEBAR.CAR_LOAN" | translate }}</a
          >
        </p>
        <p
          class="menu-text container pr-5 py-2"
          data-cy="home loan"
          style="border-bottom: solid 1px #d9d9d9"
        >
          <a
            routerLink="/propfinn"
            class="menu-text"
            style="text-decoration: none"
          >
            {{ "SIDEBAR.HOME_LOAN" | translate }}</a
          >
        </p>
        <p
          class="menu-text container pr-5 py-2"
          data-cy="car price"
          style="border-bottom: solid 1px #d9d9d9; margin: 10px 0"
        >
          <a
            routerLink="/car-loan"
            class="menu-text"
            style="text-decoration: none"
          >
            {{ "SIDEBAR.CHECK_CAR_PRICES" | translate }}</a
          >
        </p>
        <mat-accordion>
          <mat-expansion-panel disabled style="margin: -5px 0 0 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="nav-text" routerLink="/calculate">
                  <p class="menu-text" data-cy="Calculate">
                    {{ "SIDEBAR.CALCULATE_INTEREST_RATES" | translate }}
                  </p>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="menu-text" data-cy="know us">
                  {{ "SIDEBAR.ABOUT" | translate }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a class="nav-text" routerLink="/vision" data-cy="vision">
              <!--  class="submenu-text" -->
              <p class="sub-about">
                {{ "SIDEBAR.VISION_AND_MISSION" | translate }}
              </p>
            </a>
            <a class="nav-text" routerLink="/about-us" data-cy="about">
              <!--  class="submenu-text" -->
              <p class="sub-about">
                {{ "SIDEBAR.ABOUT_CARFINN" | translate }}
              </p>
            </a>
            <a
              class="nav-text"
              href="javascript:void(0);"
              (click)="scrollToSection('cusReview')"
              data-cy="review"
            >
              <!--  class="submenu-text" -->
              <p class="sub-about">
                {{ "SIDEBAR.REVIEW" | translate }}
              </p>
            </a>
          </mat-expansion-panel>

          <mat-expansion-panel disabled>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="nav-text" routerLink="/articles" data-cy="article">
                  <p class="menu-text">{{ "SIDEBAR.ARTICLE" | translate }}</p>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <mat-expansion-panel disabled>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="nav-text" routerLink="/faq" data-cy="FAQ tag">
                  <p class="menu-text">
                    {{ "SIDEBAR.FAQ" | translate }}
                  </p>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <!-- <mat-expansion-panel disabled>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="nav-text" routerLink="/investor-relations" data-cy="">
                  <p class="menu-text">
                    {{ "SIDEBAR.FOR_INVESTER" | translate }}
                  </p>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel> -->

          <mat-expansion-panel disabled>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="nav-text" routerLink="/policy" data-cy="policy">
                  <p class="menu-text">
                    {{ "SIDEBAR.TERMS_AND_PRIVACY_POLICY" | translate }}
                  </p>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="d-flex justify-content-center my-3" data-cy="contact us">
          <p class="medium-font m-0"><i class="bi bi-telephone"></i></p>
          <p class="medium-font my-0 mx-2">
            {{ "SIDEBAR.CONTACT_US" | translate }}
          </p>
          <a href="tel:0946655114" class="tel">
            <p class="medium-font" style="margin: 0">094-665-5114</p>
          </a>
        </div>

        <div class="d-flex justify-content-center my-3">
          <mat-button-toggle-group
            appearance="legacy"
            [(ngModel)]="language"
            (ngModelChange)="changeLanguage(language)"
          >
            <mat-button-toggle value="th">
              <img src="assets/logo/th.png" class="logo-toggle" alt="" /> TH
            </mat-button-toggle>
            <mat-button-toggle value="en">
              <img src="assets/logo/en.png" class="logo-toggle" alt="" /> EN
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </div>
</div>
