<mat-dialog-content class="mat-typography">
    <div class="container p-3 m-0" style="position: relative;">
        <div class="close-btn">
            <a [mat-dialog-close]="true" (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
        <p class="text-center my-4 header-policy">นโยบายข้อมูลส่วนบุคคล</p>

        <div class="row d-flex justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="policy-box">
                    <p class="text-center policy-text bold">
                        นโยบายความเป็นส่วนตัวสำหรับลูกค้าของ บริษัท คาร์ฟินน์ อินเตอร์ กรุ๊ป จำกัด
                    </p>
                    <p class="policy-text">
                        นโยบายความเป็นส่วนตัวฉบับนี้ออกโดย บริษัท คาร์ฟินน์ อินเตอร์ กรุ๊ป จำกัด (ใช้คำเรียกแทนว่า
                        “บริษัท”
                        หรือ
                        “ของบริษัท”) เพื่อแจ้งบุคคลภายนอกที่บริษัทติดต่อด้วย รวมถึงลูกค้า ผู้ใช้บริการเว็บไซต์ แพลตฟอร์ม
                        แอปพลิเคชัน และผู้รับบริการอื่น ๆ ของบริษัท (ทั้งหมดรวมเรียกว่า “ คุณ” หรือ “ของคุณ”)
                        ได้ทราบถึงจุดประสงค์ของบริษัทเกี่ยวกับการเก็บรวบรวม การใช้ การเปิดเผย
                        และ/หรือการโอนข้อมูลส่วนบุคคลของคุณ
                    </p>
                    <ol class="listNumDot">
                        <li class="fsrbo">ข้อมูลส่วนบุคคลที่จัดเก็บรวบรวม
                            <p class="fsrb">บริษัทอาจได้รับข้อมูลส่วนบุคคลจากคุณโดยตรง (ในฐานะลูกค้า ผู้ใช้บริการ
                                หรือผู้รับบริการของบริษัท) หรือโดยทางอ้อมจากแหล่งอื่น ๆ และ/หรือผ่านบริษัทในเครือ
                                บริษัทอื่น
                                ๆ พันธมิตรทางธุรกิจ หรือบุคคลที่สาม (เช่น ตัวแทนจำหน่าย ตัวแทน นายหน้าช่วง
                                และบุคคลอ้างอิงอื่น ๆ)</p>
                            <p class="fsrb">บริษัทจะดำเนินการเก็บรวบรวม ใช้
                                หรือเปิดเผยข้อมูลส่วนบุคคลของคุณโดยอาศัยข้อกำหนดทางกฎหมาย ได้แก่ (1)
                                เพื่อปฏิบัติตามสัญญาสำหรับให้บริการหรือทำธุรกรรมกับคุณ (2)
                                เพื่อปฏิบัติหน้าที่ตามกฎหมายของบริษัท (3) เพื่อประโยชน์อันชอบด้วยกฎหมายของบริษัท
                                หรือเพื่อประโยชน์อันชอบด้วยกฎหมายของบุคคลอื่น (4)
                                หลักความสำคัญเพื่อป้องกันหรือระงับมิให้เกิดอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
                                และ/หรือ (5) ผลประโยชน์สาธารณะ เพื่อดำเนินการอันเป็นสาธารณประโยชน์
                                หรือเพื่อปฏิบัติหน้าที่ในการใช้อำนาจรัฐ หรือ
                                กรณีที่จำเป็นต้องได้รับความยินยอมสำหรับดำเนินกิจกรรมบางอย่างในการเก็บรวบรวม ใช้
                                หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
                                บริษัทจะดำเนินการขอรับความยินยอมจากคุณสำหรับกิจกรรมดังกล่าวแยกต่างหาก</p>
                            <p class="fsrb">ข้อมูลที่บริษัทเก็บรวบรวมไว้ขึ้นอยู่กับสิ่งที่คุณได้ติดต่อดำเนินการกับบริษัท
                                และบริการหรือผลิตภัณฑ์ที่คุณต้องการจากบริษัท
                                โดยบริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณดังต่อไปนี้</p>
                            <ol class="listNumDot">
                                <li class="fsrbo">ข้อมูลทั่วไป
                                    <ul>
                                        <li class="fsrb"><span class="bold">ข้อมูลระบุตัวตน</span> เช่น คำนำหน้าชื่อ
                                            ชื่อ-นามสกุล
                                            อักษรย่อจากชื่อจริง ชื่อเล่น นามแฝง อายุ วันเดือนปีเกิด เพศ ส่วนสูง น้ำหนัก
                                            สถานภาพการสมรส สัญชาติ ประเทศที่เกิด
                                            ความเป็นพลเมืองและสถานภาพรายละเอียดการเกณฑ์ทหาร ลายเซ็น
                                            หมายเลขโทรศัพท์ส่วนบุคคล
                                            หมายเลขโทรศัพท์ที่ทำงาน Line ID อีเมล ที่อยู่ รหัสไปรษณีย์
                                            ภาพวาดแผนที่หรือสถานที่ตั้งของที่อยู่ สถานะที่อยู่ปัจจุบัน (เช่น
                                            เป็นเจ้าของบ้าน
                                            ผู้อาศัยในนามเจ้าบ้าน บ้านพักสวัสดิการ บ้านเช่า และอัตราค่าเช่ารายเดือน
                                            ค่าผ่อนชำระบ้านต่อเดือน และระยะเวลาที่ได้เข้าอยู่อาศัย) ระดับการศึกษา
                                            ข้อมูลระบุตัวตนที่ออกโดยรัฐบาล (เช่น บัตรประจำตัวประชาชน
                                            เลขบัตรประจำตัวประชาชน
                                            ใบขับขี่ หนังสือเดินทาง บัตรข้าราชการ และทะเบียนบ้าน) อาชีพ สถานะการจ้างงาน
                                            ตำแหน่งงาน และอายุงาน</li>
                                        <li class="fsrb"><span class="bold">รายละเอียดทางการเงิน</span> เช่น
                                            แหล่งรายได้หลัก
                                            เอกสารทางการเงินที่ออกโดยนายจ้าง แหล่งรายได้อื่น รายละเอียดบัญชีธนาคาร
                                            รายงานการเคลื่อนไหวในบัญชี (Bank Statement) สำเนาบัตรเครดิต เลขบัตรเครดิต
                                            วันหมดอายุ ใบสมัครสินเชื่อ บันทึกการชำระเงินและความน่าเชื่อถือทางการเงิน
                                            (เช่น
                                            สถานะข้อมูลเครดิตแห่งชาติ ข้อมูลเกี่ยวกับสภาพคล่องทางการเงิน
                                            และข้อมูลอื่นที่คล้ายคลึงกัน)</li>
                                        <li class="fsrb"><span
                                                class="bold">รายละเอียดรถยนต์ที่เกี่ยวข้องกับสินเชื่อ</span>
                                            ข้อมูลเกี่ยวกับการจดทะเบียนยานพาหนะ เช่น จังหวัดที่ลงทะเบียน วันที่จดทะเบียน
                                            ไมล์สะสม รถยนต์ ยี่ห้อรถ รุ่น ชนิดเกียร์ ประเภทยานพาหนะ สี ขนาดเครื่องยนต์
                                            หมายเลขเครื่องยนต์ เลขตัวถัง ระยะเวลาการเป็นเจ้าของ น้ำหนักสุทธิรถยนต์
                                            วันที่ต่ออายุประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)</li>
                                        <li class="fsrb"><span class="bold">การตั้งค่าและความสนใจ</span> เช่น
                                            ความสนใจของคุณ
                                            การตั้งค่าต่าง ๆ (รวมถึงช่องทางการติดต่อที่มีขั้นตอนการเข้าสู่ระบบ
                                            และ/หรือวิธีการถ่ายโอนความสนใจของคุณ) ประวัติการใช้เว็บไซต์
                                            และเอชทีทีพีคุกกี้
                                        </li>
                                        <li class="fsrb"><span class="bold">รายละเอียดผลิตภัณฑ์/บริการ</span> เช่น
                                            ประเภทผลิตภัณฑ์ที่ให้บริการ (ผลิตภัณฑ์สินเชื่อส่วนบุคคลภายใต้การกำกับ
                                            สินเชื่อรายย่อยเพื่อการประกอบอาชีพภายใต้การกำกับ สินเชื่อรถจักรยานยนต์
                                            สินเชื่อรถยนต์และรถกระบะ สินเชื่อทะเบียนรถ สินเชื่อรถบรรทุกซื้อขาย (C2C)
                                            สินเชื่อรถบรรทุกซื้อขาย (Dealer) สินเชื่อรถบรรทุกซื้อขายผ่านนายหน้าซื้อ
                                            สินเชื่อเช่าซื้อรถ/ยานพาหนะ ประกันภัยรถยนต์ ประกันอุบัติเหตุ ประกันมะเร็ง
                                            ประกันชีวิตคุ้มครองสินเชื่อ (PPI) ประกันภัยคุ้มครองผู้ประสบภัยจากรถ (พ.ร.บ.)
                                            หมายเลขสัญญาของคุณ หมายเลขกรมธรรม์ประกันภัยของคุณ รายละเอียดบริษัทประกันภัย
                                            วิธีชำระเบี้ยประกัน ระยะเวลากรมธรรม์ประกันภัย
                                            (ตั้งแต่วันเอาประกันและวันสิ้นสุดการเอาประกัน) รายละเอียดเบี้ยประกัน
                                            เหตุผลการยกเลิกกรมธรรม์ประกันภัย จำนวนงวด จำนวนเงินที่จ่ายต่องวด
                                            จำนวนเงินการชำระงวดสุดท้าย ภาษีมูลค่าเพิ่ม ภาษียานยนต์
                                            วันที่จ่ายค่างวดงวดแรก
                                            วันที่จ่ายเงินแต่ละงวด ค่าธรรมเนียมการโอนกรรมสิทธิ์
                                            และ/หรือค่าธรรมเนียมการจดทะเบียนยานพาหนะ ค่าธรรมเนียมการต่อภาษี
                                            จุดประสงค์การขอสินเชื่อ รายละเอียดสินเชื่อ (เช่น สินเชื่อรถยนต์ ราคาเงินสด
                                            เงินดาวน์ จำนวนงวด จำนวนเงินกู้ทั้งหมด
                                            และอัตราดอกเบี้ยและค่าธรรมเนียมแบบลดต้นลดดอก) มูลค่าหลักประกัน
                                            รายละเอียดหลักประกัน วิธีชำระหนี้ ยอดปิดปรับ/รีไฟแนนซ์
                                            จำนวนรวมของการชำระหนี้
                                            และวิธีการรับเงินสินเชื่อ (เช่น รับเป็นเงินสดโดยตรง
                                            หรือรับฝากเข้าบัญชีธนาคาร)
                                        </li>
                                        <li class="fsrb"><span class="bold">ข้อมูลการสื่อสาร</span> เช่น
                                            ข้อมูลเสียงของคุณซึ่งบันทึกไว้ที่ศูนย์บริการข้อมูล บันทึกการสอบถามของคุณ
                                            และรายละเอียดหรือเนื้อหาการสื่อสารระหว่างคุณกับบริษัท</li>
                                        <li class="fsrb"><span class="bold">ข้อมูลการตลาด</span> เช่น
                                            การตั้งค่าทางการตลาดของคุณ
                                            ข้อมูลผลิตภัณฑ์หรือบริการที่บริษัทคาดว่าคุณอาจสนใจ
                                            และการอ้างถึง/การอนุมานจากการโต้ตอบหรือปฏิสัมพันธ์ของคุณกับบริษัท
                                            บริษัทในเครือ
                                            บริษัทอื่น ๆ หรือพันธมิตรของบริษัท</li>
                                        <li class="fsrb"><span
                                                class="bold">ข้อมูลประวัติการเยี่ยมชมใช้งานอินเตอร์เน็ต</span> เช่น
                                            ไฟล์คุกกี้ และพฤติกรรมการใช้อินเทอร์เน็ต ข้อมูลการเข้าสู่ระบบ ประเภทสืบค้น
                                            ประวัติสืบค้น ภาษาที่ใช้สืบค้น
                                            เลขที่ใช้สำหรับระบุตัวตนของเครื่องคอมพิวเตอร์ที่เชื่อมต่อบนเครือข่าย (IP
                                            Address)
                                            ข้อมูลเกี่ยวกับวิธีใช้งานและการโต้ตอบกับบริการออนไลน์หรือการโฆษณาของบริษัท
                                            (รวมถึงเว็บเพจที่เยี่ยมชม เนื้อหาที่เยี่ยมชม ลิงก์ที่คลิก
                                            และคุณสมบัติที่ใช้)
                                            เวลาที่ใช้และความถี่ในการใช้บริการออนไลน์ของบริษัท
                                            หน้าเว็บที่คุณคลิกลิงก์เพื่อเข้าสู่บริการออนไลน์ของบริษัท (เช่น อ้างอิงจาก
                                            URL)
                                            และรายงานข้อขัดข้อง ซึ่งเป็นไปได้ที่จะผสานข้อมูลใด ๆ
                                            เข้ากับข้อมูลส่วนบุคคลของคุณ หรืออาจใช้ข้อมูลอื่นเพื่อสร้างโปรไฟล์ของคุณ
                                            บริษัทจะถือว่าข้อมูลอื่น ๆ
                                            และข้อมูลที่ผสานกันแล้วนั้นเป็นข้อมูลส่วนบุคคลด้วย
                                        </li>
                                        <li class="fsrb"><span class="bold">ข้อมูลอื่น ๆ ที่เป็นไปตาม FATCA</span> เช่น
                                            คุณมีสัญชาติหรือสถานที่เกิดที่เกี่ยวข้องกับประเทศสหรัฐอเมริกาหรือไม่
                                            คุณถือสัญชาติอเมริกัน (Green Card) หรือไม่
                                            คุณมีภาระผูกพันที่จะต้องจ่ายภาษีให้กรมสรรพากรของประเทศสหรัฐอเมริกาหรือไม่
                                        </li>
                                        <li class="fsrb"><span class="bold">ข้อมูลส่วนบุคคลของบุคคลอื่น</span>
                                            บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลของบุคคลอื่นที่คุณได้ไห้ไว้กับบริษัท (เช่น
                                            สมาชิกในครอบครัวของคุณ บุคคลอ้างอิง ผู้ค้ำประกัน
                                            และบุคคลที่ต้องติดต่อเพื่อเรียกเก็บหนี้ พยาน ผู้รับมอบอำนาจ
                                            และ/หรือผู้รับผลประโยชน์)
                                            รวมถึงข้อมูลที่คุณให้กับบริษัทในโครงการเพื่อนแนะนำเพื่อน
                                            สำหรับการจัดหาผลิตภัณฑ์
                                            หรือการดำเนินการให้บริการของบริษัท
                                            โดยหากคุณได้ให้ข้อมูลส่วนบุคคลของบุคคลอื่นไว้กับบริษัท
                                            กรุณาตรวจสอบให้แน่ใจว่าคุณมีอำนาจในการเปิดเผยข้อมูลนั้นด้วย โดย (ก)
                                            แจ้งให้บุคคลอื่นทราบเกี่ยวกับนโยบายฉบับนี้ และ (ข)
                                            ต้องได้รับความยินยอมในกรณีที่เกี่ยวข้อง หรือเมื่อจำเป็น
                                            เพื่อให้บริษัทใช้ข้อมูลส่วนบุคคลดังกล่าวได้ตามที่ระบุในนโยบายฉบับนี้</li>
                                        <li class="fsrb"><span class="bold">ความสัมพันธ์ทางสังคม</span> เช่น
                                            สถานภาพทางการเมือง
                                            ความสัมพันธ์กับกรรมการ ฝ่ายจัดการ และบุคคลผู้มีอำนาจควบคุมของบริษัท
                                            ความสัมพันธ์กับนิติบุคคลอื่น (เช่น กรรมการ ฝ่ายจัดการ และผู้ถือหุ้น)
                                            และความสัมพันธ์อื่น</li>
                                        <li class="fsrb"><span class="bold">มุมมองและความคิดเห็น</span>
                                            ข้อมูลเกี่ยวกับมุมมองและความคิดเห็นของคุณ เช่น
                                            ความชื่นชอบเกี่ยวกับบริการของบริษัท หรือข้อมูลอื่นใดที่คุณเลือกส่งให้บริษัท
                                            (รวมถึงการส่งข้อมูลผ่านทางสื่อสังคมออนไลน์ หรือการสำรวจความคิดเห็นออนไลน์)
                                            การแสดงความคิดเห็น การให้ข้อติชม การร้องเรียน การให้คำแนะนำ
                                            การตอบแบบสำรวจความคิดเห็น ข้อซักถาม
                                            และข้อมูลที่คุณสมัครใจให้ไว้ระหว่างที่ติดต่อสื่อสารด้านการตลาดหรือการสื่อสารเกี่ยวกับการบริการลูกค้า
                                            รวมทั้งข้อมูลส่วนบุคคลของคุณที่เปิดเผยบนโปรไฟล์สื่อสังคมออนไลน์ของคุณ และ
                                        </li>
                                        <li class="fsrb"><span class="bold">รายละเอียดอื่นใดที่คุณให้ไว้กับบริษัท</span>
                                            ข้อมูลที่ได้ให้ไว้กับบริษัทเป็นส่วนหนึ่งในการเริ่มสร้างความสัมพันธ์ใหม่หรือความสัมพันธ์ที่มีในปัจจุบันของคุณ
                                            การที่คุณสมัครใช้ผลิตภัณฑ์และ/หรือบริการของบริษัท
                                            หรือที่บริษัทให้การบริการแก่คุณ รวมทั้งกรณีอื่น ๆ</li>
                                    </ul>
                                </li>
                                <li class="fsrbo">ข้อมูลละเอียดอ่อน (Sensitive Data)
                                    <ul>
                                        <li class="fsrb"><span class="bold">ข้อมูลที่ละเอียดอ่อน</span> เช่น
                                            ข้อมูลประวัติอาชญากรรม
                                            ข้อมูลศาสนา ข้อมูลสุขภาพ ข้อมูลความพิการ ข้อมูลชีวภาพ (เช่น
                                            ข้อมูลจำลองลายนิ้วมือ ข้อมูลภาพจำลองใบหน้า) โดยบริษัทจะดำเนินการเก็บรวบรวม
                                            ใช้
                                            และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่ละเอียดอ่อนเหล่านี้จากความยินยอมที่ถูกต้องชัดเจนจากคุณ
                                            หรือเป็นข้อยกเว้นตามกฎหมายซึ่งไม่ต้องขอความยินยอม</li>
                                    </ul>
                                </li>
                            </ol>
                            <p class="fsrb">ในกรณีที่บริษัทมีการเก็บรวบรวม ใช้ เปิดเผย ข้อมูลของผู้เยาว์
                                ผู้ไร้ความสามารถ
                                และผู้เสมือนไร้ความสามารถ
                                และต้องมีการขอความยินยอมจากบุคคลดังกล่าวบริษัทจะมีการขอความยินยอมจากจากผู้ปกครองซึ่งมีอำนาจกระทำแทนผู้เยาว์
                                ผู้พิทักษ์ หรือผู้อนุบาล เท่านั้นตามลำดับ
                                เว้นแต่ในกรณีที่บริษัทสามารถอ้างเหตุผลทางกฎหมายอื่นๆ ได้
                                กรณีถ้าบริษัททราบภายหลังว่าได้เก็บข้อมูลส่วนบุคคลของคนที่มีอายุต่ำกว่า 20
                                ปีโดยไม่ได้รับความยินยอมจากผู้ปกครองซึ่งมีอำนาจกระทำแทนผู้เยาว์
                                หรือกรณีของผู้ไร้ความสามารถ
                                ผู้เสมือนไร้ความสามารถซึ่งไม่ได้รับความยินยอมจาก ผู้พิทักษ์ ผู้อนุบาล ตามลำดับ
                                โดยที่บริษัทไม่รู้ข้อเท็จจริงดังกล่าว บริษัทจะลบข้อมูลนั้นทันที หรือจะเก็บรวบรวม ใช้
                                เปิดเผย
                                และ/หรือ
                                โอนข้อมูลดังกล่าวก็ต่อเมื่อบริษัทสามารถอ้างเหตุผลทางกฎหมายส่วนอื่นนอกจากความยินยอมได้เท่านั้น
                            </p>
                        </li>
                        <li class="fsrbo">ช่องทางการเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ
                            <p class="fsrb">บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลผ่านช่องทางต่าง ๆ ได้แก่</p>
                            <ul class="list-unstyled ps-0">
                                <li class="d-flex"><span class="fsrbo pe-1">(1)</span>
                                    <p class="fsrb mb-0"><span class="bold">ช่องทางการบริการ</span>
                                        บริษัทอาจจัดเก็บข้อมูลส่วนบุคคลของคุณผ่านช่องทางการให้บริการ
                                        ไม่ว่าจะเป็นสถานที่ให้บริการของบริษัท การให้บริการออฟไลน์หรือออนไลน์
                                        หรือให้บริการทางไกล (เช่น ทางโทรศัพท์ ทางแอปพลิเคชั่นบนโทรศัพท์มือถือ
                                        รวมทั้งช่องทางการส่งเสริมการขายและการตลาดอื่น ๆ)</p>
                                </li>
                                <li class="d-flex"><span class="fsrbo pe-1">(2)</span>
                                    <p class="fsrb mb-0"><span class="bold">ช่องทางอื่นนอกจากการบริการ</span> นอกจากนี้
                                        บริษัทยังอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณผ่านช่องทางอื่นนอกจากการบริการ เช่น
                                        เมื่อคุณติดต่อบริษัท ก่อนการทำธุรกรรม สมัครใช้การบริการ ขอเอกสารเสนองาน
                                        หรือข้อเสนอต่าง ๆ จากบริษัท หรือเข้าร่วมการทำธุรกรรมหรือการทำสัญญา
                                        (ไม่ว่าจะติดต่อและให้ข้อมูลส่วนบุคคลผ่านช่องทางใด ๆ เช่น ผ่านสาขาบริษัท
                                        แพลตฟอร์มทางอิเล็กทรอนิกส์ สื่อสังคมออนไลน์ของบริษัท บัญชี LINE
                                        ที่เป็นทางการของบริษัท) หรือข้อมูลที่ได้รับจากห้องเก็บเอกสาร (Data Room)
                                        ที่เกี่ยวข้องกับการทำธุรกรรม</p>
                                </li>
                                <li class="d-flex"><span class="fsrbo pe-1">(3)</span>
                                    <p class="fsrb mb-0"><span class="bold">จากแหล่งอื่น</span>
                                        บริษัทอาจได้รับข้อมูลส่วนบุคคลของคุณจากแหล่งอื่น เช่น ข้อมูลสาธารณะ
                                        แหล่งข้อมูลของทางการ (เช่น หน่วยงานราชการที่มีฐานข้อมูลของบุคคลที่น่าเชื่อถือ)
                                        นายจ้างของคุณ องค์กรที่บริษัทให้บริการ
                                        (ซึ่งรวมถึงเว็บไซต์และเครือข่ายสังคมออนไลน์ขององค์กรเหล่านั้น)
                                        ผู้ให้บริการที่บริษัทว่าจ้างให้เก็บรวบรวมข้อมูลส่วนบุคคลแทนบริษัท
                                        บริษัทในเครือของบริษัท คู่ค้าของบริษัท (เช่น
                                        บริษัทประกันภัยเพื่อจุดประสงค์เกี่ยวกับการประกันภัย
                                        แพลตฟอร์มของพันธมิตรทางธุรกิจของบริษัท
                                        คู่ค้าของบริษัทที่คุณทำงานอยู่หรือเป็นผู้กระทำการแทน) และจากบุคคลภายนอกอื่น ๆ
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li class="fsrbo">จุดประสงค์การเก็บรวบรวม การใช้ หรือการเปิดเผยข้อมูลส่วนบุคคลของคุณ
                            <p class="fsrb">บริษัทอาจเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคลของคุณ เพื่อจุดประสงค์ต่าง ๆ
                                ขึ้นอยู่กับว่าคุณติดต่อดำเนินการใด ๆ กับบริษัท ดังนี้</p>
                            <ul>
                                <li class="fsrb"><span class="bold">เพื่อให้บริการคุณ</span> เช่น
                                    เพื่อให้คุณสมัครและรับผลิตภัณฑ์หรือบริการของบริษัท
                                    เพื่อดำเนินการตามกระบวนการอนุมัติสินเชื่อ
                                    เพื่อสนับสนุนและดำเนินกิจกรรมอื่นที่เกี่ยวข้องกับบริการหรือผลิตภัณฑ์
                                    เพื่อให้บริการลูกค้า (รวมถึงแต่ไม่จำกัดเฉพาะการตอบข้อสอบถาม)
                                    เพื่อโอนกรรมสิทธิ์รถยนต์
                                    เพื่อดำเนินการเบิกจ่ายสินเชื่อจัดทำใบเสนอราคา
                                    และดำเนินการพิจารณาใบสมัครของคุณเพื่อให้บุคคลภายนอกดำเนินการจัดหาการประกันภัยที่เหมาะกับคุณ
                                    เพื่อส่งมอบและรับคืนเล่มกรมธรรม์ประกันภัย
                                    เพื่ออำนวยความสะดวกในการสมัครรับผลิตภัณฑ์และบริการของบุคคลที่สาม เช่น
                                    ในกระบวนการอนุมัติประกันภัย และการออกเล่มกรมธรรม์ประกันภัยเพื่อดำเนินการเคลมประกัน
                                    และยกเลิกกรมธรรม์ประกันภัยเพื่อบริหารจัดการบัญชีของคุณ
                                    เพื่อดำเนินการเกี่ยวกับการสมัคร
                                    ข้อตกลงสัญญา หรือการติดต่อใด ๆ กับคุณ
                                    เพื่อดูแลและปรับปรุงการบันทึกข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการที่ให้แก่คุณ
                                    บางกรณีเพื่อยุติสัญญาใด ๆ ที่บริษัทมีกับคุณ
                                    เพื่อทำหรือช่วยตัดสินใจเกี่ยวกับเครดิตของคุณ
                                    เพื่อประเมินความเสี่ยงในการปล่อยสินเชื่อและการประกันภัย
                                    และเพื่อตรวจสอบรายละเอียดที่คุณให้ไว้กับบริษัท และผู้ให้บริการของบริษัท</li>
                                <li class="fsrb"><span class="bold">เพื่อยืนยันตัวตนของคุณ</span> เช่น
                                    เพื่อดำเนินการที่เกี่ยวข้องกับการให้บริการตามกระบวนการที่ต้องรู้จักลูกค้าและระบุตัวตนหรือพิสูจน์ตัวตนได้อย่างถูกต้อง
                                    (KYC) หรือในกระบวนการตรวจสอบเพื่อให้ทราบข้อเท็จจริงเกี่ยวกับลูกค้า (CDD)
                                    เพื่อตรวจสอบข้อมูลของคุณจากบัญชีดำและการฟอกเงิน
                                    เพื่อให้เข้าถึงบัญชีของคุณจากการลงทะเบียน การยืนยัน การระบุตัวตน
                                    และการรับรองความถูกต้องของคุณ หรือตัวตนของคุณและการลงนาม เพื่อยืนยันตำแหน่ง
                                    และเพื่อเก็บคำถามรักษาความปลอดภัยที่ใช้ยืนยันตัวตนในภายหลังสำหรับช่องทางออนไลน์หรือมือถือ
                                </li>
                                <li class="fsrb"><span class="bold">เพื่อใช้ข้อมูลทางธุรกรรมและ/หรือการชำระเงิน</span>
                                    เช่น
                                    การประมวลผลการชำระเงินหรือธุรกรรม การเรียกเก็บเงิน การประมวลผล การหักบัญชี
                                    การคืนเงิน
                                    หรือกิจกรรมกระทบยอดบัญชีต่าง ๆ (กิจกรรมการประนีประนอม)
                                    รวมถึงกิจกรรมทางการเงินทั้งหมด
                                    ทั้งการทำธุรกรรมหรือบันทึกการชำระเงินที่เกี่ยวข้อง การออกใบเสร็จเรียกเก็บเงิน
                                    ใบกำกับภาษี ใบเสร็จการชำระเงิน และการส่งมอบเอกสารดังกล่าว</li>
                                <li class="fsrb"><span
                                        class="bold">เพื่อสื่อสารและจัดการความสัมพันธ์ของบริษัทกับคุณ</span>
                                    เช่น
                                    เพื่อแจ้งผลการสมัครใช้ผลิตภัณฑ์หรือบริการของคุณ การสื่อสารทางข้อความ (SMS)
                                    เพื่อส่งจดหมายหรือเอกสาร (เช่น จดหมายต้อนรับ รหัสบัตรและบัตรเครดิต กรมธรรม์ประกันภัย
                                    จดหมายแจ้งต่ออายุประกันภัย ใบแจ้งหนี้หรือแจ้งเตือนวันครบกำหนดอื่น ๆ
                                    หรือจดหมายเรียกเก็บหนี้) และการติดตามการจัดส่งไปรษณีย์
                                    การแจ้งให้ทราบเกี่ยวกับจำนวนเงินค้ำประกันที่เหลืออยู่เพื่อดำเนินการขอรับคืน
                                    เพื่อให้ความช่วยเหลือด้านเทคนิคและจัดการกับปัญหาทางเทคนิค
                                    เพื่อใช้และปรับปรุงข้อมูลของคุณให้เป็นปัจจุบัน
                                    เพื่ออำนวยความสะดวกในการใช้บริการของบริษัท เพื่อดำเนินการตามข้อร้องเรียนของคุณ
                                    เพื่อตอบสนองและปฏิบัติตามคำขอของคุณเกี่ยวกับผลิตภัณฑ์หรือบริการที่บริษัทเสนอหรือให้
                                    เพื่อจัดการบัญชีและความสัมพันธ์ของบริษัท</li>
                                <li class="fsrb"><span class="bold">เพื่อติดตามทวงถามหนี้</span>
                                    เพื่อดำเนินการติดตามทวงถามหนี้โดยบริษัท และผู้ให้บริการการทวงถามหนี้
                                    เพื่อเรียกคืนเงิน
                                    เพื่อติดตามทรัพย์สินกลับคืน หรือ เพื่อรับทรัพย์สินจากคุณ
                                    และจัดการกับบัญชีของคุณตามความเหมาะสมกับสถานการณ์ของคุณ
                                    (ซึ่งอาจรวมถึงการยื่นฟ้องลูกค้าที่ค้างชำระ)</li>
                                <li class="fsrb"><span class="bold">เพื่อโฆษณาและการตลาด</span> เช่น
                                    ส่งข้อเสนอสำหรับโปรโมชัน
                                    ข้อเสนอพิเศษ และข้อเสนออื่น ๆ/โปรโมชัน (รวมถึงแต่ไม่จำกัดเฉพาะการให้ส่วนลด)
                                    เพื่อจัดกิจกรรมเสี่ยงโชคลุ้นของรางวัลหรือกิจกรรมอื่นในด้านความร่วมมือ/การเป็นพันธมิตร
                                    และบริการโฆษณาที่เกี่ยวข้องทั้งหมดเพื่อทำการตลาดทางตรงและทำการตลาดเฉพาะบุคคล
                                    เพื่อแสดงโฆษณาออนไลน์ เพื่อให้ข้อเสนอหรือโปรโมชันแบบกำหนดเป้าหมาย
                                    เพื่อดำเนินการและจัดการลงทะเบียนบัญชีและกิจกรรมของคุณ
                                    เพื่อดำเนินการวางกลยุทธ์และการตลาดดิจิทัล
                                    เพื่อดำเนินการด้านความสัมพันธ์ที่เกี่ยวข้องกับโซเชียลมีเดีย
                                    เพื่อวิเคราะห์ข้อมูลสำหรับจุดประสงค์ทางการตลาด เพื่อวิจัยการตลาด
                                    และประเมินหรือปรับปรุงประสิทธิภาพการตลาดของบริษัท
                                    เพื่อดำเนินการด้านสื่อสังคมออนไลน์ในการแจ้งให้คุณทราบเกี่ยวกับการตลาดของบริษัท
                                    การสื่อสาร ประกาศ ข่าว และข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการอื่น ๆ
                                    รวมถึงบริการโฆษณาและของรางวัลจากบริษัท
                                    หรือบริษัทในเครือและพันธมิตรทางธุรกิจของบริษัท
                                    การสื่อสารการตลาดอื่น ๆ ที่เกี่ยวข้องผ่านเอเจนซี่</li>
                                <li class="fsrb"><span class="bold">คำแนะนำและการปรับเปลี่ยนเฉพาะบุคคล</span>
                                    บริษัทได้เก็บข้อมูลเกี่ยวกับการตั้งค่าของคุณ เช่น
                                    ผลิตภัณฑ์ประเภทใดที่คุณมีแนวโน้มจะสนใจ
                                    เพื่อนำไปปรับการโฆษณา
                                    และให้ข้อแนะนำเกี่ยวกับผลิตภัณฑ์หรือบริการได้ตรงกับความสนใจของคุณมากที่สุด
                                    โดยปรับแต่งประสบการณ์ของคุณ นอกจากนี้ บริษัทยังใช้บริการสื่อสังคมออนไลน์
                                    เพื่อแสดงโฆษณาและข้อเสนอพิเศษผ่านสื่อสังคมออนไลน์
                                    ซึ่งบางกรณีจะกำหนดเป้าหมายไปที่คุณตามความสนใจและการเรียกดูเว็บออนไลน์
                                    (รวมถึงเว็บไซต์บริษัท)</li>
                                <li class="fsrb"><span class="bold">การปรับปรุงผลิตภัณฑ์และบริการของบริษัท</span> เช่น
                                    การวิเคราะห์ข้อมูลเพื่อปรับปรุงประสิทธิภาพการตลาดและข้อเสนอในการขายผลิตภัณฑ์และบริการของบริษัท
                                    เพื่อวิเคราะห์ข้อมูลสำหรับปรับปรุงระบบ (เช่น
                                    พัฒนาแบบจำลองการจำแนกกลุ่มลูกค้าเพื่อให้บริการที่ดีขึ้น)
                                    วิเคราะห์ข้อมูลเพื่อเพิ่มโอกาสทางธุรกิจ
                                    เพื่อตรวจสอบใบเสร็จเรียกเก็บเงินและจดหมายต้อนรับแบบสุ่มสำหรับปรับปรุงความแม่นยำ
                                    เพื่อปรับปรุงผลิตภัณฑ์และบริการที่มีอยู่และพัฒนาผลิตภัณฑ์และบริการใหม่
                                    เพื่อสำรวจความพึงพอใจลูกค้าสำหรับปรับปรุงการให้บริการ
                                    เพื่อตรวจสอบว่าส่วนใดในเว็บไซต์บริษัทที่คุณเข้าชม
                                    หรือส่วนใดของเว็บไซต์ที่คุณพบว่ามีประโยชน์มากที่สุด
                                    การประเมินส่วนที่เชื่อมประสานกับผู้ใช้และประสบการณ์
                                    การทดลองใช้คุณสมบัติหรือฟังก์ชันต่าง
                                    ๆ เพื่อแก้ไขปรับปรุงจุดบกพร่องในโปรแกรม (Debug) และแก้ไขข้อผิดพลาด
                                    เพื่อวิเคราะห์การใช้บริการออนไลน์ของบริษัท
                                    เพื่อปรับแต่งและเพิ่มประสิทธิภาพเว็บไซต์และประสบการณ์การใช้แพลตฟอร์มของคุณ
                                    เพื่อจดจำคุณในเบราว์เซอร์และอุปกรณ์ต่าง ๆ ที่คุณใช้
                                    เพื่อระบุและแก้ไขปัญหาเกี่ยวกับผลิตภัณฑ์และบริการที่มีอยู่
                                    และเพื่อพัฒนาข้อมูลในเชิงคุณภาพ</li>
                                <li class="fsrb"><span class="bold">การวิเคราะห์ความเสี่ยง</span> เช่น
                                    การประเมินความเสี่ยงตามข้อมูลของคุณและข้อมูลที่ได้รับ
                                    เพื่อประเมินคุณสมบัติในการเข้ารับผลิตภัณฑ์หรือบริการของบริษัท
                                    เพื่อประเมินใบสมัครของคุณหรือคุณสมบัติในการเข้ารับผลิตภัณฑ์หรือบริการ (เช่น
                                    เพื่อประเมินคะแนนเครดิตสำหรับการสมัครสินเชื่อรถยนต์
                                    เพื่อพิจารณาการอนุมัติสินเชื่อรถยนต์
                                    เพื่อให้เข้าใจถึงจุดประสงค์การขอสินเชื่อและธุรกรรม การใช้รถยนต์ติดสินเชื่อ
                                    และแหล่งที่มาของการชำระหนี้
                                    เช่นเดียวกับเพื่อประเมินความสามารถของลูกค้าในการปฏิบัติตามภาระผูกพันได้อย่างทันท่วงที)
                                    เพื่อประเมินความเสี่ยงอย่างต่อเนื่อง เพื่อวิเคราะห์ข้อมูลสำหรับป้องกันความเสี่ยง
                                    (เช่น
                                    พัฒนาแบบจำลองเพื่อทำนายความเป็นไปได้ของสินเชื่อที่ไม่ก่อให้เกิดรายได้
                                    หรือคาดการณ์โอกาสของการเกิดอุบัติเหตุ) เพื่อควบคุมความเสี่ยงของบริษัท
                                    การตรวจสอบและการเก็บบันทึกการตรวจสอบ การวิเคราะห์ความเสี่ยงด้านเครดิต</li>
                                <li class="fsrb"><span class="bold">การดำเนินงานตามจุดประสงค์ทางธุรกิจ</span> เช่น
                                    เพื่อปรับปรุงแก้ไขข้อมูลของคุณเพิ่มเติม เพื่อรักษาข้อมูลให้ถูกต้อง
                                    เพื่อเก็บบันทึกทางธุรกิจและอื่น ๆ เพื่อปฏิบัติงาน จัดการ
                                    และรักษาการดำเนินธุรกิจของบริษัท
                                    และเพื่อดูแลการจัดการธุรกิจภายในสำหรับข้อกำหนดการปฏิบัติงานภายใน นโยบาย
                                    และขั้นตอนของบริษัท</li>
                                <li class="fsrb"><span class="bold">การปกป้องประโยชน์ของบริษัท</span> เช่น
                                    เพื่อป้องกันความปลอดภัยและความมั่นคงของบริษัท
                                    การใช้สิทธิและการปกป้องผลประโยชน์ของบริษัทเมื่อจำเป็น เช่น เพื่อตรวจจับ ป้องกัน
                                    และปฏิบัติตามข้อร้องเรียนเรื่องการทุจริต และเพื่อประเมินความเสี่ยงจากการทุจริต
                                    และการระบุธุรกรรมที่มีการทุจริต การเรียกร้องการละเมิดทรัพย์สินทางปัญญาหรือกฎหมาย
                                    เพื่อจัดการและป้องกันการสูญเสียทรัพย์สินและสินทรัพย์ของบริษัท
                                    เพื่อดำเนินการตรวจสอบรายชื่อการติดโทษ (Sanction List) การบริหารความเสี่ยง
                                    การตรวจสอบภายในและบันทึก การจัดการสินทรัพย์ ระบบและการควบคุมธุรกิจอื่น ๆ
                                    เพื่อป้องกันหรือปราบปรามอันตรายซึ่งอาจเกิดขึ้นกับชีวิต ร่างกาย หรือสุขภาพบุคคล
                                    เพื่อตรวจสอบรับรองการปฏิบัติตามข้อกำหนดและเงื่อนไขบริษัท การติดตามเหตุการณ์
                                    เพื่อป้องกันและรายงานความผิดทางอาญา
                                    และเพื่อสร้างความปลอดภัยและความมั่นคงให้กับบริษัทสำหรับใช้อ้างอิงและเป็นหลักฐานที่เกี่ยวข้องกับการเรียกร้องหรือการดำเนินคดี
                                </li>
                                <li class="fsrb"><span class="bold">เพื่อดำเนินการตามจุดประสงค์ทางกฎหมาย</span> เช่น
                                    การปฏิบัติตามกฎหมาย การดำเนินคดี หรือคำสั่งของเจ้าหน้าที่ของรัฐ
                                    ซึ่งอาจรวมถึงคำสั่งจากหน่วยงานของรัฐนอกประเทศไทย และ/หรือการให้ความร่วมมือกับศาล
                                    หน่วยงานกำกับดูแล หน่วยงานราชการ และหน่วยงานที่บังคับใช้กฎหมาย
                                    เมื่อบริษัทเห็นสมควรว่าบริษัท
                                    จำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อให้ความร่วมมือตามกฎหมาย การดำเนินการ
                                    คำสั่งของเจ้าหน้าที่ของรัฐ หลักจรรยาบรรณ และนโยบายภายในของบริษัท
                                    การใช้สิทธิและการโต้ตอบข้อเรียกร้องทางกฎหมาย (ซึ่งรวมถึงการติดตามทวงถามหนี้
                                    การนำทรัพย์สินออกขายทอดตลาดเพื่อนำมาชำระหนี้ การเรียกคืนจากบัญชีค้างชำระ)
                                    เพื่อเก็บรักษาบันทึกและแก้ไขข้อร้องเรียนและข้อพิพาท
                                    เพื่อการใช้ข้อมูลการดำเนินกิจกรรม
                                    เพื่อการใช้ข้อมูลผลกิจกรรมการรายงานเครดิต และการกำกับดูแลและการรายงานภาษี
                                    การเปิดเผยเพื่อจุดประสงค์ในการตรวจสอบ</li>
                                <li class="fsrb"><span class="bold">เพื่อการทำงานของเว็บไซต์และแพลตฟอร์มบริษัท</span>
                                    เช่น
                                    เพื่อดูแลการปฏิบัติงานด้านเทคโนโลยีสารสนเทศของบริษัท การจัดการระบบสื่อสาร
                                    การดำเนินงานด้านความปลอดภัยทางเทคโนโลยีสารสนเทศและการตรวจสอบความปลอดภัยด้านเทคโนโลยีสารสนเทศ
                                    การบริหารงาน การดำเนินงาน การติดตามงาน การตรวจสอบ
                                    และจัดการเว็บไซต์และแพลตฟอร์มของบริษัท
                                    เพื่ออำนวยความสะดวกและตรวจสอบให้แน่ใจว่าทำงานได้อย่างถูกต้อง มีประสิทธิภาพ
                                    และปลอดภัย
                                    เพื่ออำนวยความสะดวกต่าง ๆ ให้กับคุณในเว็บไซต์และแพลตฟอร์มของบริษัท
                                    ปรับปรุงรูปแบบและเนื้อหาในเว็บไซต์และแพลตฟอร์มบริษัท
                                    เพื่อให้คุณเข้าถึงระบบที่มีอยู่และให้ความช่วยเหลือด้านเทคนิค</li>
                                <li class="fsrb"><span class="bold">การทำธุรกรรมขององค์กร</span> กรณีที่มีการขาย การโอน
                                    การควบรวมกิจการ การปรับโครงสร้าง หรือเหตุการณ์ที่คล้ายกัน
                                    ซึ่งบริษัทอาจโอนข้อมูลของคุณไปยังบุคคลที่สามอย่างน้อยหนึ่งรายอันเป็นส่วนหนึ่งของธุรกรรมนั้น
                                </li>
                                <li class="fsrb"><span class="bold">เพื่อให้ความสำคัญกับชีวิต</span>
                                    เพื่อป้องกันหรือระงับอันตรายอันอาจเกิดกับชีวิต ร่างกาย หรือสุขภาพบุคคล</li>
                                <li class="fsrb"><span class="bold">เพื่อป้องกันความเสี่ยงด้านความปลอดภัย</span> เช่น
                                    การตรวจสอบบันทึกกิจกรรมเครือข่าย เพื่อตรวจสอบเหตุการณ์ความปลอดภัย
                                    เพื่อดำเนินการตรวจสอบความปลอดภัย และเพื่อป้องกันอันตรายอันอาจเกิดกับข้อมูล การฉ้อฉล
                                    การฉ้อโกง หรือกิจกรรมที่ผิดกฎหมายและกิจกรรมอื่น ๆ
                                    ที่อาจเกี่ยวโยงกับการบำรุงรักษาข้อมูล
                                </li>
                                <li class="fsrb"><span
                                        class="bold">จุดประสงค์ที่เกี่ยวข้องกับข้อมูลที่ละเอียดอ่อน</span>
                                    <ul>
                                        <li class="fsrb">
                                            เพื่อจุดประสงค์ในการยื่นสำเนาบัตรประชาชนของคุณในการดำเนินการต่อหน่วยงานที่เกี่ยวข้อง:
                                            <span class="bold">ข้อมูลเกี่ยวกับศาสนา</span>
                                            (ตามที่ปรากฏบนบัตรประจำตัวประชาชน)<br>
                                            <span class="bold">หมายเหตุ</span>:
                                            บริษัทขอแจ้งให้ทราบว่าบริษัทไม่มีวัตถุประสงค์ในการเก็บใช้และไม่มีการบันทึกข้อมูลส่วนบุคคลที่มีความละเอียดอ่อนซึ่งปรากฏอยู่บนหน้าบัตรประชนของคุณในระบบข้อมูลของบริษัท
                                        </li>
                                        <li class="fsrb">
                                            เพื่อจุดประสงค์ในการดำเนินการตรวจสอบประวัติและกระบวนการรับประกันภัย
                                            (Underwriting Process) เพื่อนำไปใช้กับผลิตภัณฑ์และบริการของบริษัท:
                                            <span class="bold">ประวัติอาชญากรรม</span>
                                        </li>
                                        <li class="fsrb">เพื่อจุดประสงค์ในการขอรับใบเสนอราคากรมธรรม์ประกันภัย
                                            และเพื่อจุดประสงค์ในการเรียกร้องค่าสินไหมทดแทน
                                            หรือกระบวนการพิจารณาเรียกร้องค่าสินไหมทดแทน การดำเนินการเรียกสินไหมทดแทน
                                            และการชำระค่าสินไหมในนามผู้เอาประกันภัยอันเกินขอบเขตของกฎหมายที่อนุญาต:
                                            <span class="bold">ข้อมูลสุขภาพ</span>
                                        </li>
                                        <li class="fsrb">เพื่อจุดประสงค์ในการขอรับใบเสนอราคากรมธรรม์ประกันภัย
                                            และเพื่อจุดประสงค์ในการเรียกร้องค่าสินไหมทดแทน
                                            หรือกระบวนการพิจารณาเรียกร้องค่าสินไหมทดแทน การดำเนินการเรียกค่าสินไหมทดแทน
                                            และการชำระค่าสินไหมในนามผู้เอาประกันภัยอันเกินขอบเขตของกฎหมายที่อนุญาต:
                                            <span class="bold">ข้อมูลความพิการ</span>
                                        </li>
                                        <li class="fsrb">เพื่อจุดประสงค์ในการรับรองความถูกต้องและการยืนยันตัวตน
                                            และการดำเนินการของกระบวนการตรวจสอบตัวตนทางอิเล็กทรอนิกส์ตามกระบวนการทำความรู้จักลูกค้าทางอิเล็กทรอนิกส์
                                            (Electronic Know Your Customer (E-KYC))
                                            และเพื่อจุดประสงค์ในการสร้างลายมือชื่ออิเล็กทรอนิกส์:
                                            <span class="bold">ข้อมูลชีวภาพ</span> (เช่น ข้อมูลจำลองลายนิ้วมือ
                                            ข้อมูลภาพจำลองใบหน้า)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p class="fsrb">หมายเหตุ:
                                กรณีที่บริษัทจำเป็นต้องรวบรวมข้อมูลส่วนบุคคลของคุณตามที่กฎหมายกำหนด
                                หรือกรณีที่บริษัทต้องเข้าทำสัญญา หรือปฏิบัติตามสัญญากับคุณ
                                หรือปฏิบัติตามเงื่อนไขการจัดกิจกรรมกับคุณ หรือดำเนินการใด ๆ
                                เพื่อคุณตามจุดประสงค์ที่ระบุไว้ข้างต้น และคุณไม่ได้ให้ข้อมูลนั้นเมื่อร้องขอ
                                บริษัทอาจไม่บรรลุจุดประสงค์ที่เกี่ยวข้องตามที่ระบุไว้ข้างต้นได้</p>
                        </li>
                        <li class="fsrbo">บุคคลที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของคุณ
                            <p class="fsrb">
                                บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณไปยังบุคคลที่สามดังต่อไปนี้ตามจุดประสงค์ที่กล่าวไว้ข้างต้น
                            </p>
                            <ul>
                                <li class="fsrb"><span class="bold">กลุ่มธุรกิจทางการเงินและบริษัทในเครือกรุงศรี</span>
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณต่อกลุ่มธุรกิจทางการเงินและบริษัทในเครือกรุงศรี<br>
                                    * คุณสามารถตรวจสอบรายละเอียดรายชื่อผู้รับข้อมูลได้จากหัวข้อ FACT SHEET
                                    ผ่านทางลิงก์นี้:
                                    (<a href="https://www.krungsri.com/bank/th/InvestorRelations/GeneralInformation/FACT-SHEET.html"
                                        onclick="if (!window.__cfRLUnblockHandlers) return false; PushGTMClickATag('click_link', this.textContent+' | สำหรับลูกค้าบริษัท')"
                                        target="_blank"
                                        title="สำหรับลูกค้าบริษัท">https://www.krungsri.com/bank/th/InvestorRelations/GeneralInformation/FACT-SHEET.html</a>)
                                </li>
                                <li class="fsrb"><span class="bold">พันธมิตรทางธุรกิจ</span>
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณต่อบริษัทอื่นที่ได้ร่วมนำเสนอหรือพัฒนาผลิตภัณฑ์หรือบริการให้ลูกค้าหรือผู้ที่อาจเป็นลูกค้าในอนาคต
                                    เช่น บริษัทประกันภัย ผู้ให้บริการชำระเงิน ผู้ให้บริการวิเคราะห์ ตัวแทนทำงานวิจัย
                                    ตัวแทนให้บริการจัดผลสำรวจ ตัวแทนให้บริการด้านการตลาด สื่อโฆษณาและการสื่อสาร
                                    ตัวแทนการตลาดและบริการจัดกิจกรรมส่งเสริมการขาย</li>
                                <li class="fsrb"><span class="bold">ผู้ให้บริการ</span>
                                    บริษัทอาจว่าจ้างบริษัทอื่นเป็นผู้ให้บริการในนามบริษัท
                                    และเพื่อสนับสนุนบริษัทในการดำเนินธุรกิจ
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณต่อบริษัทผู้ให้บริการเหล่านี้
                                    หรือบริษัทผู้ให้บริการดังกล่าวอาจรวบรวมข้อมูลส่วนบุคคลของคุณในนามบริษัท
                                    เพื่อจุดประสงค์ทางธุรกิจที่หลากหลาย เช่น ผู้ให้บริการที่เก็บข้อมูล
                                    ผู้ให้บริการคลาวด์
                                    ผู้ให้บริการด้านเทคโนโลยีสารสนเทศ และโครงสร้างพื้นฐาน ซอฟต์แวร์และผู้พัฒนาเว็บไซต์
                                    ผู้ให้บริการการบริหารความเสี่ยง ผู้ให้บริการให้ข้อมูล
                                    ผู้ให้บริการโปรแกรมสนทนาอัตโนมัติ
                                    ตอบคำถามผ่านแพลตฟอร์ม เว็บไซต์ แอปพลิเคชันต่าง ๆ ผู้ให้บริการคอลเซ็นเตอร์
                                    ผู้ให้บริการจัดส่งข้อความ (SMS) ผู้ให้บริการเครือข่ายแพลตฟอร์มที่ใช้สนับสนุน
                                    ผู้ให้บริการด้านการจัดการทั่วไป ผู้ให้บริการด้านการติดตามทวงถามหนี้และติดตามหนี้สิน
                                    ที่ปรึกษาด้านการตรวจสอบ ที่ปรึกษาด้านกฎหมาย ผู้ให้บริการด้านการพิมพ์และการขนส่ง
                                    ผู้สื่อข่าวและประชาสัมพันธ์</li>
                                <li class="fsrb"><span class="bold">บุคคลที่สาม</span>
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้บุคคลที่ปฏิบัติหน้าที่ในนามของคุณ
                                    หรือเกี่ยวข้องกับการจัดหาผลิตภัณฑ์หรือบริการที่คุณได้รับจากบริษัท รวมถึงนายหน้า
                                    ตัวแทน
                                    และผู้แทนจำหน่ายที่ได้ส่งใบคำขอสินเชื่อของคุณแก่บริษัท
                                    ตัวกลางผู้ให้บริการจัดการการชำระเงินของคุณ และผู้ให้บริการชำระเงินผ่านบัตรของบริษัท
                                    ผู้ค้ำประกัน ผู้เรียกร้องค่าสินไหมทดแทน
                                    ผู้จัดหาเงินทุนในกรณีการแปลงสินทรัพย์เป็นหลักทรัพย์
                                    และเพื่อประเมินมูลค่าสินทรัพย์ของบริษัท ตัวแทนการติดตามทวงถามหนี้และการยึดทรัพย์สิน
                                </li>
                                <li class="fsrb">
                                    <span
                                        class="bold">บุคคลที่สามที่คุณอนุญาตหรือขอให้เราเปิดเผยข้อมูลส่วนบุคคลของคุณ</span>
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณด้วยความยินยอมหรือตามคำขอของคุณ
                                </li>
                                <li class="fsrb"><span class="bold">เครดิตบูโร</span>
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณไปยังสถาบันจัดอันดับความน่าเชื่อถือ
                                    รวมถึงบริษัท
                                    ข้อมูลเครดิตแห่งชาติ เพื่อรายงานหรือตรวจสอบเกี่ยวกับสถานการณ์ทางการเงินของคุณ
                                    และเพื่อจุดประสงค์ตามกฎหมายอื่น ๆ</li>
                                <li class="fsrb"><span class="bold">ผู้มีความเป็นไปได้ที่จะเป็นผู้ซื้อ
                                        (รวมถึงตัวแทนและที่ปรึกษา)</span>
                                    กรณีที่บริษัทตั้งใจจะขายธุรกิจส่วนหนึ่งส่วนใดหรือทรัพย์สินของบริษัท
                                    หรือถ้าบุคคลที่สามได้มาซึ่งสินทรัพย์ทั้งหมดของบริษัท
                                    กรณีนี้ข้อมูลส่วนบุคคลของคุณอาจเป็นส่วนหนึ่งของสินทรัพย์ที่บริษัทขาย
                                    โดยบริษัทจะแจ้งผู้ซื้อว่าต้องใช้ข้อมูลส่วนบุคคลของคุณ
                                    เพื่อจุดประสงค์ตามที่อธิบายไว้ในนโยบายนี้เท่านั้น</li>
                                <li class="fsrb">
                                    <span
                                        class="bold">หน่วยงานภาครัฐและหน่วยงานอื่นที่บริษัทต้องเปิดเผยข้อมูลส่วนบุคคลเพื่อปฏิบัติตามกฎหมายหรือตามความจำเป็น</span>
                                    บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณต่อหน่วยงานของรัฐ หน่วยงานที่บังคับใช้กฎหมาย
                                    ศาล
                                    หน่วยงานที่มีหน้าที่กำกับดูแล หรือบุคคลที่สามอื่น ๆ ตามกฎหมายและจุดประสงค์ที่จำเป็น
                                    เช่น
                                    <ul>
                                        <li class="fsrb">เพื่อปฏิบัติตามคำขอจากหน่วยงานกำกับดูแล
                                            หรือเพื่อปฏิบัติตามหมายศาล
                                            การตรวจสอบของรัฐบาลหรือการสอบสวน การขอบังคับใช้กฎหมาย กรมสอบสวนคดีพิเศษ
                                            คำสั่งกฎหมาย หรือกระบวนการทางกฎหมายอื่น ๆ</li>
                                        <li class="fsrb">เพื่อจุดประสงค์ทางกฎหมายอื่น ๆ เช่น
                                            การบังคับใช้ข้อกำหนดและตามเงื่อนไขบริษัท
                                            การใช้หรือการปกป้องสิทธิเรียกร้องตามกฎหมาย
                                            หรือกรณีที่บริษัทพิจารณาว่าการเปิดเผยข้อมูลนั้นเป็นสิ่งจำเป็นหรือเหมาะสม
                                            เพื่อปกป้องสิทธิของบริษัท
                                            สิทธิในการปกป้องความปลอดภัยในชีวิตของบุคคลที่สามหรือบุคคลอื่น ๆ
                                            ความปลอดภัยในทรัพย์สินส่วนบุคคล หรือเพื่อตรวจจับ
                                            ป้องกันหรือจัดการกับการฉ้อโกง
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="fsrbo">การโอนข้อมูลส่วนบุคคลของคุณไปยังต่างประเทศ
                            <p class="fsrb">
                                ข้อมูลส่วนบุคคลของคุณจะได้รับการปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่บังคับใช้ในประเทศไทย
                                และอาจโอนไปยังประเทศอื่นนอกประเทศไทย
                                บริษัทอาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของคุณไปยังแพลตฟอร์มคลาวด์หรือเซิร์ฟเวอร์ที่ตั้งอยู่ในต่างประเทศ
                                เพื่อสนับสนุนระบบเทคโนโลยีสารสนเทศ
                                หรือไปยังบุคคลที่สามเพื่อจุดประสงค์ที่ถูกต้องตามกฎหมาย
                                เช่น เพื่อจุดประสงค์ในการวิเคราะห์ข้อมูลคะแนนเครดิตและการวิเคราะห์ความเสี่ยง
                                ผู้รับข้อมูลส่วนบุคคลของคุณบางรายอาจอยู่ในประเทศอื่นที่ยังไม่มีมาตรการคุ้มครองข้อมูลส่วนบุคคลเพียงพอตามที่สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                                พ.ศ. 2562 กำหนด
                                กรณีดังกล่าวบริษัทจะดำเนินการให้ความคุ้มครองที่เหมาะสมตามภาระหน้าที่กฎหมายของบริษัท
                                เพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของคุณได้รับการคุ้มครองอย่างเพียงพอไม่ว่าประเทศใดก็ตาม
                            </p>
                        </li>
                        <li class="fsrbo">ระยะเวลาเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ
                            <p class="fsrb">บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลของคุณไว้เท่าที่จำเป็น
                                เพื่อใช้ตามจุดประสงค์ของการเก็บรวบรวม
                                และเพื่อปฏิบัติตามข้อผูกพันทางกฎหมายและข้อบังคับของบริษัทตามรายละเอียดในนโยบายฉบับนี้และตามกฎหมายที่บังคับใช้
                                โดยบริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ไม่เกิน 10 ปี
                                นับตั้งแต่วันที่คุณสิ้นสุดความสัมพันธ์ หรือผ่านการติดต่อครั้งสุดท้ายกับบริษัท
                                อย่างไรก็ตาม
                                บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณไว้นานเท่าที่จำเป็น
                                และ/หรือเท่าที่ได้รับอนุญาตตามกฎหมาย</p>
                        </li>
                        <li class="fsrbo">สิทธิของคุณ
                            <p class="fsrb">
                                ภายใต้บทบัญญัติของกฎหมายการคุ้มครองข้อมูลส่วนบุคคลและข้อยกเว้นตามกฎหมายที่เกี่ยวข้องคุณ
                                มีอาจมีสิทธิตามที่ระบุดังต่อไปนี้</p>
                            <ul>
                                <li class="fsrb"><span class="bold">สิทธิในการเข้าถึง</span>
                                    คุณมีสิทธิตรวจสอบข้อมูลที่มีการเก็บรวบรวม ใช้ การเปิดเผย ข้อมูลส่วนบุคคลของคุณ
                                    หรือขอสำเนาข้อมูลส่วนบุคคลของคุณ
                                    หรือขอทราบที่มาของข้อมูลส่วนบุคคลที่คุณไม่ได้ให้ไว้กับบริษัทโดยตรง</li>
                                <li class="fsrb"><span
                                        class="bold">สิทธิในการขอให้แก้ไขข้อมูลส่วนตัวของคุณให้ถูกต้อง</span>
                                    หากคุณเห็นว่าข้อมูลส่วนบุคคลของคุณไม่ถูกต้อง ไม่เป็นปัจจุบัน หรือไม่สมบูรณ์
                                    คุณอาจขอให้แก้ไขข้อมูลส่วนตัวดังกล่าวได้</li>
                                <li class="fsrb"><span class="bold">สิทธิในการลบหรือทำลาย</span> คุณมีสิทธิขอให้ลบ ทำลาย
                                    หรือทำให้ข้อมูลของคุณที่บริษัทเก็บรวบรวม
                                    ให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนที่เป็นเจ้าของข้อมูลได้ แต่ทั้งนี้
                                    ต้องไม่กระทบกระเทือนต่อ สิทธิของผู้อื่น ความมั่นคงปลอดภัย สัญญาที่มีอยู่ต่อบริษัท
                                    รวมถึงเป็นเป็นการขัดต่อกฎหมาย</li>
                                <li class="fsrb"><span class="bold">สิทธิในการระงับใช้</span>
                                    คุณอาจมีสิทธิขอให้ระงับใช้ข้อมูลส่วนบุคคลของคุณในกรณีที่เห็นว่าข้อมูลของคุณไม่ถูกต้องเหมาะสม
                                    หรือขอระงับการลบ/ทำลายข้อมูลส่วนบุคคลของคุณ</li>
                                <li class="fsrb"><span class="bold">สิทธิในการขอรับข้อมูลส่วนบุคคล</span>
                                    ถ้ากฎหมายได้ให้สิทธิไว้
                                    คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณจากบริษัทในรูปแบบที่สามารถอ่านได้ในรูปแบบอิเล็กทรอนิกส์
                                    และเพื่อส่งหรือโอนข้อมูลดังกล่าวไปยังผู้ควบคุมข้อมูลรายอื่น โดยต้องเป็น (ก)
                                    ข้อมูลส่วนบุคคลที่คุณได้ให้ไว้กับบริษัท และ (ข)
                                    กรณีบริษัทได้รับความยินยอมจากคุณหรือเพื่อปฏิบัติตามสัญญาที่มีกับคุณ</li>
                                <li class="fsrb"><span class="bold">สิทธิในการคัดค้าน</span>
                                    คุณมีสิทธิคัดค้านการเก็บรวบรวม
                                    ใช้
                                    เปิดเผย ข้อมูลส่วนบุคคลของคุณ เช่นการคัดค้านการตลาดแบบตรง</li>
                                <li class="fsrb"><span class="bold">สิทธิในการขอถอนความยินยอม</span>
                                    หากคุณได้ยินยอมให้มีการเก็บรวบรวม ใช้ เปิดเผย ข้อมูลส่วนบุคคลของคุณ
                                    คุณมีสิทธิขอยกเลิกความยินยอมเมื่อไหร่ก็ได้</li>
                                <li class="fsrb"><span class="bold">สิทธิในการร้องเรียน</span>
                                    คุณมีสิทธิร้องเรียนไปยังหน่วยงานที่รับผิดชอบการเก็บรวบรวม ใช้ เปิดเผย
                                    ข้อมูลส่วนบุคคลของคุณ ทั้งที่บริษัทเป็นผู้ดำเนินการดังกล่าว
                                    หรือกรณีบุคคลอื่นเป็นผู้ดำเนินการในนามของบริษัท อย่างไรก็ตาม
                                    บริษัทขอโอกาสจัดการแก้ไขสิ่งที่คุณกังวล ก่อนที่คุณจะติดต่อหน่วยงานที่มีอำนาจ
                                    บริษัทขอให้คุณติดต่อบริษัทเป็นอันดับแรก เพื่อพูดคุยแก้ไขในสิ่งที่คุณกังวล</li>
                            </ul>
                            <p class="fsrb">ทั้งนี้ การขอใช้สิทธิของคุณตามที่กำหนดอาจมีข้อจำกัดสิทธิตามกฎหมาย
                                และบางกรณีบริษัทสามารถปฏิเสธคำขอของคุณด้วยเหตุผลที่เหมาะสม
                                โดยคุณสามารถใช้สิทธิดังกล่าวข้างต้นได้ตามที่อยู่ในหัวข้อ “ติดต่อสอบถาม”</p>
                        </li>
                        <li class="fsrbo">การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว
                            <p class="fsrb">
                                บริษัทอาจแก้ไขหรือปรับปรุงนโยบายฉบับนี้เป็นครั้งคราวตามการเปลี่ยนแปลงแนวปฏิบัติหรือนโยบายการคุ้มครองความเป็นส่วนตัวของบริษัทจากเหตุผลต่าง
                                ๆ เช่น การเปลี่ยนแปลงทางเทคโนโลยี การเปลี่ยนแปลงทางกฎหมาย ฯลฯ
                                การแก้ไขหรือการปรับปรุงนโยบายฉบับนี้จะมีผลบังคับใช้เมื่อบริษัทประกาศบนเว็บไซต์
                                บริษัทจะแจ้งให้คุณทราบ หรือขอความยินยอมจากคุณอีกครั้ง
                                หากนโยบายความเป็นส่วนตัวนี้ได้รับการปรับปรุงที่สำคัญ หรือหากบริษัทจำเป็นต้องทำตามกฎหมาย
                            </p>
                        </li>
                        <li class="fsrbo">ลิงก์เว็บไซต์บุคคลที่สามอื่น ๆ
                            <p class="fsrb">สำหรับผู้ใช้บริการเว็บไซต์ แพลตฟอร์ม แอปพลิเคชัน และผู้รับบริการอื่น ๆ
                                ของบริษัทอาจพบลิงก์เชื่อมโยงไปยังเว็บไซต์อื่น ๆ ซึ่งเป็นบุคคลที่สาม
                                โดยบริษัทมุ่งมั่นให้มีการเชื่อมโยงเฉพาะกับเว็บไซต์ที่มีมาตรฐานและมีระบบป้องกันที่ดีซึ่งเคารพความเป็นส่วนตัวของคุณ
                                กรณีนี้บริษัทไม่มีส่วนเกี่ยวข้องกับเนื้อหาหรือแนวปฏิบัติเกี่ยวกับความเป็นส่วนตัวของเว็บไซต์อื่น
                                เว้นแต่บริษัทจะระบุไว้ชัดเจน ทั้งนี้ ข้อมูลส่วนบุคคลใด ๆ
                                ที่คุณให้ไว้กับเว็บไซต์ของบุคคลที่สามจะรวบรวมโดยบุคคลที่สามนั้นซึ่งไม่ใช่บริษัท
                                และจะอยู่ภายใต้ประกาศ/นโยบายความเป็นส่วนตัวของเว็บไซต์นั้นด้วย (ถ้ามี)
                                นอกจากนโยบายบริษัทฉบับนี้
                                กรณีนี้บริษัทอาจไม่สามารถควบคุมและรับผิดชอบหากมีการใช้ข้อมูลส่วนบุคคลของคุณ
                                ซึ่งเป็นข้อมูลที่คุณได้ให้ไว้กับเว็บไซต์ของบุคคลที่สาม</p>
                        </li>
                        <li class="fsrbo">ติดต่อสอบถาม
                            <p class="fsrb">บริษัทให้ความสำคัญกับการปกป้องข้อมูลส่วนบุคคลของลูกค้า
                                และมุ่งมั่นที่จะปฏิบัติตามทุกข้อกฎหมายตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                                โดยคุณสามารถติดต่อ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                                ในการร้องเรียนเรื่องการจัดการข้อมูลส่วนบุคคลได้ที่อีเมล <a class="policy-text"
                                    href="mailto:noreply@carfinn.com"
                                    title="ส่งอีเมลถึง noreply@carfinn.com">noreply@carfinn.com</a> หรือ
                                หากท่านมีข้อสอบถามเกี่ยวกับเรื่องดังกล่าว กรุณาติดต่อ:</p>
                        </li>
                    </ol>
                    <p class="mb-0 bold policy-text">บริษัท คาร์ฟินน์ อินเตอร์ กรุ๊ป จำกัด<br>
                        ที่อยู่: 2156,2158 ถ. สุขุมวิท แขวงพระโขนงใต้ เขตพระโขนง <span
                            class="d-inline-block policy-text">กรุงเทพมหานคร 10260</span><br>
                        Call Center: <a href="tel:0946655114" class="policy-text"
                            title="โทร 094-665-5114">0946655114</a><br>
                        อีเมล: <a href="mailto:noreply@carfinn.com" class="policy-text"
                            title="ส่งอีเมลถึง noreply@carfinn.com">noreply@carfinn.com</a></p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
                <label nz-checkbox [(ngModel)]="isConfirm" class="my-3">
                </label>
                <p class="confirm-text my-3 ml-3">
                    <span class="confirm-check">คุณได้ยอมรับ </span>
                    <span class="confirm-check-link" (click)="policy('term')">ข้อตกลงและเงื่อนไข</span>
                    <span class="confirm-check"> รวมไปถึง </span>
                    <span class="confirm-check-link" (click)="policy('policy')">นโยบายคุ้มครองข้อมูลส่วนตัว</span>
                    <span class="confirm-check"> ของ Carfinn</span>
                </p>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
            <button (click)="confirm()" *ngIf="isConfirm" class="btn confirm-btn">ตกลง</button>
            <button *ngIf="!isConfirm" class="btn confirm-btn disabled">ตกลง</button>
        </div>
    </div>
</mat-dialog-content>