import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'home-our-service',
  templateUrl: './our-service.component.html',
  styleUrls: ['./our-service.component.scss']
})
export class OurServiceComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateLink(link:string) {
    this.router.navigate([`/${link}`]);
  }

  gotoInsurance(){
    window.open('https://page.line.me/gfg2435r?openQrModal=true', '_blank');
  }

  gotoPropfinn(){
    this.router.navigate(['/propfinn']);
  }

}
