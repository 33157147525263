import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { getVideoData, videoData } from './model-video';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'carfinn-video-presentation',
  templateUrl: './video-presentation.component.html',
  styleUrls: ['./video-presentation.component.scss']
})
export class VideoPresentationComponent implements OnInit {

  @Input() dataVideo: getVideoData[] = [];

  dataRender: videoData[] = [];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    margin: 15,
    autoplay: false,
    autoplayTimeout: 5000,
    dots: true,
    navSpeed: 700,
    nav: true,
    navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>'],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      760: { items: 1 },
      1000: { items: 1 }
    }
  }


  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dataRender = this.dataVideo
  }

}
