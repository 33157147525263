import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'carfinn-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  @Input() current: number = 0;
  @Input() changeStep: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
