<div class="container py-5">
    <div class="row d-flex justify-content-center">
        <div class="col-12 header ">
            <p class="main-header text-left ml-5">
                <span>3 ขั้นตอนง่าย ๆ ในการรีไฟแนนซ์</span>
            </p>
        </div>
        <div class="col-12 mt-3">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-4 col-md-12 col-sm-12" data-aos="fade-up">
                    <div class="circle-outside mx-auto d-block">
                        <div class="circle-inside">
                            <img src="assets/home-refinance/verified.png" class="mx-auto d-block img-fluid" alt="check" fetchpriority="low">
                        </div>
                    </div>
                    <p class="text-center refinance-text mt-4"><span class="number-bold">1.</span> เช็กยอด</p>
                    <p class="text-center refinance-text">ประเมินข้อมูลเครดิตลูกค้า</p>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12" data-aos="fade-up">
                    <div class="circle-outside mx-auto d-block">
                        <div class="circle-inside">
                            <img src="assets/home-refinance/contract.png" class="mx-auto d-block img-fluid"
                                alt="contract" fetchpriority="low">
                        </div>
                    </div>
                    <p class="text-center refinance-text mt-4"><span class="number-bold">2.</span> ลูกค้าเตรียมเอกสาร</p>
                    <p class="text-center refinance-text">ทำสัญญา</p>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12" data-aos="fade-up">
                    <div class="circle-outside mx-auto d-block">
                        <div class="circle-inside">
                            <img src="assets/home-refinance/file.png" class="mx-auto d-block img-fluid" alt="file" fetchpriority="low">
                        </div>
                    </div>
                    <p class="text-center refinance-text mt-4"><span class="number-bold">3.</span> ส่งเรื่องเข้าระบบ</p>
                    <p class="text-center refinance-text">รอผลอนุมัติ</p>
                </div>

            </div>

            <!-- arrow in desktop -->
            <div class="d-none d-xxl-block d-xl-block d-lg-block d-md-none">
                <div class="arrow-left">
                    <img src="assets/home-refinance/Vector.png" alt="vector" fetchpriority="low">
                </div>
            </div>

            <!-- arrow in destop -->
            <div class="d-none d-xxl-block d-xl-block d-lg-block d-md-none">
                <div class="arrow-right">
                    <img src="assets/home-refinance/Vector.png" alt="vector" fetchpriority="low">
                </div>
            </div>

            <!-- arrow in mobile -->
            <div class="d-block d-sm-block d-md-block d-xxl-none d-xl-none d-lg-none">
                <div class="arrow-top">
                    <img src="assets/home-refinance/Vector.png" alt="vector" fetchpriority="low">
                </div>
            </div>

            <!-- arrow in mobile -->
            <div class="d-block d-sm-block d-md-block d-xxl-none d-xl-none d-lg-none">
                <div class="arrow-bottom">
                    <img src="assets/home-refinance/Vector.png" alt="vector" fetchpriority="low">
                </div>
            </div>
        </div>
    </div>
</div>