import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-personal-policy',
  templateUrl: './modal-personal-policy.component.html',
  styleUrls: ['./modal-personal-policy.component.scss']
})
export class ModalPersonalPolicyComponent implements OnInit {

  modalData: any;
  isConfirm: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalPersonalPolicyComponent>,
  ) {
    this.modalData = data;
  }

  ngOnInit(): void {
  }

  confirm() {
    const response = {
      status: true
    }
    this.dialogRef.close(response);

  }

  policy(term){
    window.open(`/policy?privacy=${term}`, '_blank')
  }

  closeModal() {
    const response = {
      status: false
    }
    this.dialogRef.close(response);
  }

}
