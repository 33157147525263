<div class="mt-4">
  <p data-cy="note" style="font-size: 22px; font-weight: 500" class="text-center">
    เข้าสู่ระบบ หรือ สมัครสมาชิก<br />
    เพื่อเข้าถึงผลการค้นหาที่มากขึ้น
    พร้อมการประเมินดอกเบี้ยที่ถูกที่สุดสำหรับคุณ
  </p>
  <div class="row d-flex justify-content-center mb-4">
    <div class="col-lg-auto col-12 m-1 d-flex justify-content-center">
      <button data-cy="login" class="login-button" (click)="redirectToLogin()" nz-button>
        เข้าสู่ระบบ
      </button>
    </div>
    <div class="col-lg-auto col-12 m-1 d-flex justify-content-center">
      <button data-cy="member_apply" class="reg-button" (click)="redirectToRegister()" nz-button>
        สมัครสมาชิก
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button data-cy="not_now" style="font-size: 18px;" nz-button nzType="link" (click)="destroyModal()">ยังไม่สนใจสมัครสมาชิก</button>
    </div>
  </div>
</div>
