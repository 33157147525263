import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'carfinn-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent implements OnInit {

  openMenu: boolean = false;
  isOver = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickMenu() {
    this.openMenu = !this.openMenu;
  }

  hello(mex: string) {
    alert('Hello ' + mex + '!');
  }

  facebook() {
    window.open('https://m.me/Carfinnth/', '_blank');
  }

  line() {
    window.open('https://lin.ee/sfqF1Vo', '_blank');
  }

  tel() {
    document.location.href = 'tel:0946655114';
  }
}
