import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollNavOtherPage]'
})
export class ScrollNavOtherPageDirective {

  constructor() { }

  @HostBinding('class.fixed-top') fixedTop: boolean;
  @HostBinding('class.sticky-top') stickyTop: boolean;

  @HostListener('window:scroll') onScroll() {
    // // console.log(window.scrollY);
    // if (window.scrollY >= 5) {
    //   this.fixedTop = true;
    //   // this.stickyTop = false;
    // } else {
    //   this.fixedTop = false;
    //   // this.stickyTop = true;
    // }
  }

}
