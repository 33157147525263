<!-- other page -->
<nav class="navbar navbar-expand-lg navbar-light" id="navbar">
  <a class="navbar-brand" routerLink="/">
    <img
      src="assets/logo/carfinn-logo-white.png"
      alt="carfinn-logo"
      class="logo"
    />
  </a>
  <div class="collapse navbar-collapse" id="navbarSupportContent">
    <ul class="navbar-nav mr-auto">
      <li
        [class.active]="path === 'car-loan'"
        class="nav-item d-none d-xxl-block d-xl-block d-lg-block d-md-block"
      >
        <a class="nav-link" routerLink="/car-loan">{{
          "NAVBAR.CAR_LOAN" | translate
        }}</a>
      </li>
      <li
        [class.active]="path === 'home-loan'"
        class="nav-item d-none d-xxl-block d-xl-block d-lg-block d-md-block"
      >
        <a class="nav-link" routerLink="/coming-soon">{{
          "NAVBAR.HOME_LOAN" | translate
        }}</a>
      </li>
      <li
        [class.active]="path === 'insurance'"
        class="nav-item d-none d-xxl-block d-xl-block d-lg-block d-md-block"
      >
        <a class="nav-link" routerLink="/coming-soon">{{
          "NAVBAR.INSURANCE" | translate
        }}</a>
      </li>
      <li
        [class.active]="path === 'about-us'"
        class="nav-item d-none d-xxl-block d-xl-block d-lg-block d-md-block"
      >
        <a class="nav-link" routerLink="/about-us">{{
          "NAVBAR.ABOUT_CARFINN" | translate
        }}</a>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav ml-auto mr-2" style="flex-direction: initial">
    <!-- <li class="nav-item mx-md-2 mx-sm-2 mx-2">
      <a class="nav-link">
        <i nz-icon nzType="search" nzTheme="outline" style="padding: 2px; font-size: 23px;"></i>
      </a>
    </li> -->
    <li
      *ngIf="token && profile"
      class="nav-item my-auto mx-md-2 mx-sm-2 mx-2 d-none d-xxl-block d-xl-block d-lg-block d-md-block"
    >
      <nz-avatar
        *ngIf="image_profile === ''"
        class=""
        nzIcon="user"
        nzSrc="assets/icons/default.png"
        style="cursor: pointer"
        nz-popover
        [(nzPopoverVisible)]="visiblepop"
        (nzPopoverVisibleChange)="change($event)"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="bottomRight"
      >
      </nz-avatar>
      <nz-avatar
        *ngIf="image_profile !== ''"
        class=""
        nzIcon="user"
        [nzSrc]="image_profile"
        style="cursor: pointer"
        nz-popover
        [(nzPopoverVisible)]="visiblepop"
        (nzPopoverVisibleChange)="change($event)"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="bottomRight"
      >
      </nz-avatar>
      <ng-template #contentTemplate>
        <div *ngIf="!confirmLogoutPop" class="container">
          <div class="d-flex justify-content-center">
            <div *ngIf="token && profile" class="profile-wrapper">
              <img
                *ngIf="profile.data[0].image_profile === ''"
                src="assets/icons/default.png"
                alt="user-profile"
                class="profilePic rounded-circle mr-2 img-fluid"
              />
              <img
                *ngIf="profile.data[0].image_profile !== ''"
                [src]="image_profile"
                class="profile-img"
                alt=""
              />
            </div>
            <div class="info-wrapper">
              <div *ngIf="token && profile">
                <p
                  class="carfinn-primary-blue-text m-0"
                  style="font-size: 16px"
                >
                  {{
                    profile.data[0].name !== "" ? profile.data[0].name : "-"
                  }}
                  {{
                    profile.data[0].surname !== ""
                      ? profile.data[0].surname
                      : ""
                  }}
                </p>
                <a
                  routerLink="/profile"
                  class="carfinn-primary-blue-text user-detail"
                  style="text-decoration: none"
                >
                  <i class="bi bi-eye mr-2" style="font-size: 16px"></i>
                  เข้าดูโปรไฟล์ของฉัน
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <button
              (click)="confirmLogoutPop = !confirmLogoutPop"
              class="btn btn-outline-danger btn-logout"
            >
              <i class="bi bi-box-arrow-left mr-2"></i>
              ออกจากระบบ
            </button>
          </div>
        </div>
        <div *ngIf="confirmLogoutPop" class="container">
          <p class="text-left text-danger">ยืนยันการออกจากระบบ</p>
          <p class="text-left">คุณต้องลงชื่อออกจากระบบใช่หรือไม่ ?</p>
          <div class="d-flex flex-row-reverse">
            <button
              (click)="visiblepop = false"
              class="btn carfinn-btn-outline-blue"
            >
              ยกเลิก
            </button>
            <button (click)="logout()" class="btn carfinn-btn-blue mr-3">
              ใช่
            </button>
          </div>
        </div>
      </ng-template>
    </li>
    <li
      *ngIf="!token"
      class="nav-item my-auto mx-md-2 mx-sm-2 mx-2 d-none d-xxl-block d-xl-block d-lg-block d-md-block"
    >
      <a class="nav-link" routerLink="/login">
        <span>เข้าสู่ระบบ</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="open()">
        <i nz-icon nzType="menu" nzTheme="outline" style="font-size: 23px"></i>
      </a>
    </li>
  </ul>
</nav>

<carfinn-sidebar
  [visible]="visible"
  [token]="token"
  [profile]="profile"
></carfinn-sidebar>
