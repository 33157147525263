import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  privacy: string = '';
  tabIndex: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.privacy = new URL(location.href).searchParams.get('privacy');
    this.tabIndex = this.privacy === 'policy' ? 1 : 0;
  }

}
