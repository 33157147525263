<div mat-dialog-title class="dialog-header">
    <div class="header" style="position: relative;">
        <div style="position: absolute; right:0; margin-top: -5px;">
            <a [mat-dialog-close]="true" (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>

<mat-dialog-content class="mat-typography dialog-centent">
    <div class="container pb-4">
        <div class="d-flex justify-content-center">
            <img src="assets/logo/carfinn.png" class="mx-auto logo img-fluid" alt="">
        </div>
        <div class="p-3">
            <p class="text-center mb-3 carfinn-primary-blue-text p-3 topic-text">
                ต้องขออภัยค่ะ ข้อมูลของคุณยังไม่พบเงื่อนไข
                ในการพิจารณาค้นหาสินเชื่อ
            </p>
            <p class="text-center sub-content">
                <span>ขอขอบคุณที่สนใจใช้บริการค้นหาสินเชื่อกับทาง คาร์ฟินน์ ค่ะ</span>
            </p>
        </div>
    </div>
</mat-dialog-content>