import { NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import th from '@angular/localize/i18n/th';
import {
  DecimalPipe,
  NgOptimizedImage,
  registerLocaleData,
} from '@angular/common';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';

import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { th_TH } from 'ng-zorro-antd/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from './modules/login/login.component';
import { RegisterComponent } from './modules/register/register.component';
import { PhoneNOPipe } from './shared/pipe/phone-no.pipe';
import { SanitizeHtmlPipe } from './shared/pipe/sanitize-html.pipe';
import { PolicyComponent } from './modules/policy/policy.component';
import { RequestSuccessComponent } from './modules/request-success/request-success.component';
import { OtpInputComponent } from './modules/register/otp-input/otp-input.component';
import { ModalCreateSuccessComponent } from './modules/register/modal-create-success/modal-create-success.component';
import { CookieBannerComponent } from './shared/components/cookie-banner/cookie-banner.component';
import { ModalSettingCookiesComponent } from './shared/components/cookie-banner/modal-setting-cookies/modal-setting-cookies.component';
import { ForgetPasswordComponent } from './modules/forget-password/forget-password.component';
import { SetPasswordComponent } from './modules/forget-password/set-password/set-password.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { ModalBanComponent } from './modules/login/modal-ban/modal-ban.component';
import { ArticlesComponent } from './modules/articles/articles.component';
import { ArticleContentComponent } from './modules/articles/pages/article-content/article-content.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { CompanyHistoryComponent } from './modules/about-us/components/company-history/company-history.component';
import { AuthInterceptor } from './core/interceptors/auth/auth.interceptor';
import { NgrokInterceptor } from './core/interceptors/ngrok/ngrok.interceptor';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { JwtModule } from '@auth0/angular-jwt';
import { OrgChartComponent } from './modules/about-us/components/org-chart/org-chart.component';
import { BoardOfDirectorsComponent } from './modules/about-us/components/board-of-directors/board-of-directors.component';
import { ServiceWorkerModule } from '@angular/service-worker';

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + Date.now()
  );
}

// registerLocaleData(th);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    PhoneNOPipe,
    SanitizeHtmlPipe,
    PolicyComponent,
    RequestSuccessComponent,
    OtpInputComponent,
    ModalCreateSuccessComponent,
    CookieBannerComponent,
    ModalSettingCookiesComponent,
    ForgetPasswordComponent,
    SetPasswordComponent,
    NotFoundComponent,
    ModalBanComponent,
    ArticlesComponent,
    ArticleContentComponent,
    AboutUsComponent,
    CompanyHistoryComponent,
    OrgChartComponent,
    BoardOfDirectorsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'carfinnApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token');
        },
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    // SwiperModule,
    ReactiveFormsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgOptimizedImage,
  ],
  providers: [
    DecimalPipe,
    { provide: NZ_I18N, useValue: th_TH },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
