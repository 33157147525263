import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

interface resMoneyChanger {
  status: Status;
  data: string;
}

interface resGetBank {
  status: Status;
  data: [
    {
      id: number;
      bankname: string;
      create_date: string;
      create_by: string;
      update_date: string;
      update_by: string;
      logo: string;
      web_link: string;
      line_token_checkgroup: string;
      active_with: string;
      line_token_sendgroup?: string;
    }
  ];
}

interface Status {
  code: number;
  description: string;
}

interface resCompareMoneyChanger {
  status: Status;
  data: {
    customer_age: number;
    loan_amount: number;
    min_appraisal_amount: number;
    max_appraisal_amount: number;
    compare_respon: [
      {
        bank_id: string;
        bankname: string;
        bank_logo: string;
        longer_contract_term?: number;
        banks_interest: [
          {
            interest: number;
            formula_A: string;
            A: number;
            C: number;
          }
        ];
      }
    ];
  };
}

interface resSuccess {
  status: Status;
}

interface resCompareRefinance {
  status: Status;
  data: {
    min_appraisal_amount: number;
    max_appraisal_amount: number;
    compare_respon: [
      {
        bank_id: string;
        bankname: string;
        bank_logo: string;
        longer_contract_term?: number;
        banks_interest: [
          {
            interest: number;
            formula_A: string;
            A: number;
            C: number;
          }
        ];
      }
    ];
  };
}

@Injectable({
  providedIn: 'root'
})
export class CarLoanService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  getBank() {
    return this.http.get<resGetBank>(environment.URL + `/bank/get_bank`);
  }

  getExternalBank() {
    return this.http.get<resGetBank>(environment.URL + `/bank/getExternalBank`);
  }

  getExternalPropBank() {
    return this.http.get<resGetBank>(environment.URL + '/bank/getExternalPropBank');
  }

  regisMoneyChanger(body, form, interest_rate, maximum_loan_percent, numberOfinstallment, monthly_installment, middle_price, age_length, bankname, loan_amount) {
    let formData = new FormData();
    let trimName = form.name.trim();
    let fullname = trimName.split(/ (.+)/, 2);
    // console.log(form);
    formData.append('car_brand', body.car_brand ? body.car_brand : '');
    formData.append('car_model', body.car_model ? body.car_model : '');
    formData.append('car_year', body.car_year ? body.car_year : '');
    formData.append('car_gear', body.car_gear ? body.car_gear : '');
    formData.append('car_engine', body.car_engine ? body.car_engine : '');
    formData.append('car_class', body.car_class ? body.car_class : '');
    formData.append('car_type', body.car_type ? body.car_type : '');
    formData.append('customer_age', age_length ? age_length : '');
    formData.append('customer_job', body.customer_job ? body.customer_job : '');
    formData.append('customer_income_amount', body.customer_income_amount);
    formData.append('customer_address', body.customer_address ? body.customer_address : '');
    formData.append('customer_moo', body.customer_moo ? body.customer_moo : '');
    formData.append('customer_road', body.customer_road ? body.customer_road : '');
    formData.append('customer_soi', body.customer_soi ? body.customer_soi : '');
    formData.append('customer_district', body.customer_district ? body.customer_district : '');
    formData.append('customer_area', body.customer_area ? body.customer_area : '');
    formData.append('customer_province', body.customer_province ? body.customer_province : '');
    formData.append('customer_zipcode', body.customer_zipcode ? body.customer_zipcode : '');
    formData.append('loan_amount', loan_amount);
    formData.append('have_burden[0][detail]', body.have_burden[0].detail);
    formData.append('have_burden[0][checkbox]', JSON.parse(body.have_burden[0].checkbox));
    formData.append('have_burden[0][value]', body.have_burden[0].value);
    formData.append('have_burden[1][detail]', body.have_burden[1].detail);
    formData.append('have_burden[1][checkbox]', JSON.parse(body.have_burden[1].checkbox));
    formData.append('have_burden[1][value]', body.have_burden[1].value);
    formData.append('have_burden[2][detail]', body.have_burden[2].detail);
    formData.append('have_burden[2][checkbox]', JSON.parse(body.have_burden[2].checkbox));
    formData.append('have_burden[2][value]', body.have_burden[2].value);
    formData.append('have_burden[3][detail]', body.have_burden[3].detail);
    formData.append('have_burden[3][checkbox]', JSON.parse(body.have_burden[3].checkbox));
    formData.append('have_burden[3][value]', body.have_burden[3].value);
    formData.append('bankname', bankname);

    formData.append('interest_rate', interest_rate);
    formData.append('maximum_loan_percent', maximum_loan_percent);
    formData.append('numberOfinstallment', numberOfinstallment);
    formData.append('monthly_installment', monthly_installment);

    formData.append('customer_title', form.prefix);
    formData.append('customer_name', fullname[0]);
    formData.append('customer_surname', fullname[1] ? fullname[1] : '-');
    formData.append('customer_phone', form.phone ? form.phone : '');
    formData.append('customer_email', form.email ? form.email : '');
    formData.append('middle_price', middle_price);

    if (form.file) {
      form.file.fileList.forEach(e => {
        formData.append('docs', e.originFileObj);
      });
    }

    return this.http.post<resMoneyChanger>(environment.URL_FRONT + `/carjobs/regis_money_changer`, formData);
  }

  regisRefinance(body, form, loan_amount, interest_rate, maximum_loan_percent, numberOfinstallment, monthly_installment, middle_price, bankname, previous_per_month) {
    let formData = new FormData();
    let trimName = form.name.trim();
    let fullname = trimName.split(/ (.+)/, 2);

    formData.append("car_brand", body.car_brand ? body.car_brand : '');
    formData.append("car_model", body.car_model ? body.car_model : '');
    formData.append("car_year", body.car_year ? body.car_year : '');
    formData.append("car_gear", body.car_gear ? body.car_gear : '');
    formData.append("car_engine", body.car_engine ? body.car_engine : '');
    formData.append("car_class", body.car_class ? body.car_class : '');
    formData.append("car_type", body.car_type ? body.car_type : '');
    formData.append("previous_bankname", body.previous_bankname ? body.previous_bankname : '');
    formData.append("previous_monthly_installment", previous_per_month ? previous_per_month : '');
    formData.append("liabilities_amount", body.liabilities_amount ? body.liabilities_amount : '');
    formData.append("customer_address", body.customer_address ? body.customer_address : '');
    formData.append("customer_moo", body.customer_moo ? body.customer_moo : '');
    formData.append("customer_road", body.customer_road ? body.customer_road : '');
    formData.append("customer_soi", body.customer_soi ? body.customer_soi : '');
    formData.append("customer_district", body.customer_district ? body.customer_district : '');
    formData.append("customer_area", body.customer_area ? body.customer_area : '');
    formData.append("customer_province", body.customer_province ? body.customer_province : '');
    formData.append("customer_zipcode", body.customer_zipcode ? body.customer_zipcode : '');
    formData.append("customer_income_amount", body.customer_income_amount);
    formData.append("customer_age_job", body.customer_age_job);
    formData.append("customer_have_overdue", body.customer_have_overdue);
    formData.append('bankname', bankname);

    formData.append('loan_amount', loan_amount);
    formData.append('interest_rate', interest_rate);
    formData.append('maximum_loan_percent', maximum_loan_percent);
    formData.append('numberOfinstallment', numberOfinstallment);
    formData.append('monthly_installment', monthly_installment);

    formData.append('customer_title', form.prefix);
    formData.append('customer_name', fullname[0]);
    formData.append('customer_surname', fullname[1] ? fullname[1] : '-');
    formData.append('customer_phone', form.phone ? form.phone : '');
    formData.append('customer_email', form.email ? form.email : '');
    formData.append('middle_price', middle_price);

    if (form.file) {
      form.file.fileList.forEach(e => {
        formData.append('docs', e.originFileObj);
      });
    }

    return this.http.post<resMoneyChanger>(environment.URL_FRONT + `/carjobs/regis_refinance`, formData);
  }

  moneyChangerCompare(body) {
    return this.http.post<resCompareMoneyChanger>(environment.URL_FRONT + `/car/money_changer_compare`, body);
  }

  refinanceCompare(body) {
    return this.http.post<resCompareRefinance>(environment.URL_FRONT + `/car/refinance_compare`, body);
  }
}
