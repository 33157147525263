<div class="container-fluid pt-5 pb-2">
  <div class="row d-flex justify-content-center">
    <!-- ความสำเร็จ -->
    <!-- desktop size -->
    <div class="col-lg-6 col-md-8 col-sm-12 d-block d-xxl-block d-xl-block d-lg-block d-md-block">
      <p class="text-center compare-text carfinn-primary-blue-text">
        รถจัดไฟแนนซ์ได้เท่าไร
      </p>
      <p class="text-center compare-text carfinn-primary-blue-text">
        เช็กได้เลย ใน 1 นาที
      </p>
      <div class="d-flex row justify-content-center img-sec " style="margin: 5% 0%;">
        <div class="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
          <img src="assets/home-image/Group2.webp" class="img-Group" alt="img1" fetchpriority="low">
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
          <img src="assets/home-image/Group1.webp" class="img-Group" alt="img2" fetchpriority="low">
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
          <img src="assets/home-image/Group3.webp" class="img-Group" alt="img3" fetchpriority="low">
        </div>
      </div>

      <div class="d-flex row justify-content-center text-footer">
        <span class="text-center footter-text carfinn-primary-blue-text col-lg-8 col-md-8 col-sm-10 ">
          ด้วยฐานข้อมูลทุกไฟแนนซ์ที่เรามี รวมกับข้อมูลราคาประเมินในระดับเดียวกับแบรนด์ชั้นนำของโลก
          ทำให้คุณเช็กราคาประเมินรถ เช็กยอดจัดไฟแนนซ์
        </span>
        <span class="text-center footter-text carfinn-primary-blue-text col-12">
          เพื่อรู้แนวโน้มไฟแนนซ์ได้ ใน 1 นาที
        </span>
      </div>

    </div>
    <div class="col-lg-6 col-md-8 col-sm-12 d-block">
      <div class="row">
        <app-money-changer></app-money-changer>
      </div>
    </div>

  </div>

</div>

<!-- <div *ngIf="isLoading">
    <div style="
        position: fixed;
        z-index: 900;
        width: 100vw;
        height: 100vh;
        opacity: 80;
        top: 0;
        left: 0;
        background: #e8e8e880;
      ">
      <nz-spin nzSimple [nzSize]="'large'" style="
          z-index: 1000;
          padding: 400px 50px;
          margin: 20px 0;
          text-align: center;
        "></nz-spin>
    </div>
  </div> -->