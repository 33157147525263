<owl-carousel-o [options]="customOptions">
  <ng-container *ngIf="dataRender">
    <ng-template carouselSlide *ngFor="let data of dataRender">
      <div class="container p-lg-3 p-md-4 p-4">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pr-lg-5 pr-0">
            <div class="video">
              <div class="embed-responsive embed-responsive-16by9 my-2">
                <iframe class="lazyload" width="560" height="315" [src]="data.video | safe" title={{data.titleLink}} frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pl-lg-5 pl-0">
            <h2 class="text-left mt-3 carfinn-primary-blue-text">{{data.header}}</h2>
            <h2 class="text-left carfinn-primary-blue-text">{{data.title}}</h2>
            <p class="text-left mt-3 content-text">{{data.discription}}</p>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
      <div class="container p-3">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pr-lg-5 pr-0">
            <div class="video">
              <div class="embed-responsive embed-responsive-16by9 my-2">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/VdnsFg6G_TM"
                  title="BTimes : &#39;CarFinn&#39; แพลทฟอร์มทันใจสายกู้รถยนต์" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pl-lg-5 pl-0">
            <h2 class="text-left mt-3 carfinn-primary-blue-text">[ รู้จักกับเรา ] คาร์ฟินน์</h2>
            <h2 class="text-left carfinn-primary-blue-text">รีไฟแนนซ์รถยนต์ อนุมัติง่าย ทำได้ทุกอาชีพ</h2>
            <p class="text-left mt-3 content-text">
              สินเชื่อรถยนต์ครบวงจร สำหรับตลาดรถยนต์ทุกชนิด
              ทั้งรถมือหนึ่ง/มือสอง ไม่ว่าจะมียอดเงินกู้อยู่ (รีไฟแนนซ์) หรือผ่อนหมดแล้ว
              (จำนำเล่มทะเบียนรถยนต์)ก็สามารถขออนุมัติ
              สินเชื่อรถยนต์ ได้ง่าย สะดวก รวดเร็ว และ ปลอดภัย ผ่านแพลทฟอร์มทันสมัย ได้วงเงินสูงสุด
              ลดขั้นตอน
              ทำง่าย สบายใจ ปลอดภัยแน่นอน
            </p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="container p-3">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pr-lg-5 pr-0">
            <div class="video">
              <div class="embed-responsive embed-responsive-16by9 my-2">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/V75-aOjhSg4"
                  title="CarFinn แพลตฟอร์มรวมสินเชื่อรถยนต์-ส่องฟินเทคระดับโลกเข้าถึงสินเชื่อ I TNN Startup I 31-07-65 (FULL)"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pl-lg-5 pl-0">
            <h2 class="text-left mt-3 carfinn-primary-blue-text">[ รู้จักกับเรา ] คาร์ฟินน์</h2>
            <h2 class="text-left carfinn-primary-blue-text">รีไฟแนนซ์รถยนต์ อนุมัติง่าย ทำได้ทุกอาชีพ</h2>
            <p class="text-left mt-3 content-text">
              สินเชื่อรถยนต์ครบวงจร สำหรับตลาดรถยนต์ทุกชนิด
              ทั้งรถมือหนึ่ง/มือสอง ไม่ว่าจะมียอดเงินกู้อยู่ (รีไฟแนนซ์) หรือผ่อนหมดแล้ว
              (จำนำเล่มทะเบียนรถยนต์)ก็สามารถขออนุมัติ
              สินเชื่อรถยนต์ ได้ง่าย สะดวก รวดเร็ว และ ปลอดภัย ผ่านแพลทฟอร์มทันสมัย ได้วงเงินสูงสุด
              ลดขั้นตอน
              ทำง่าย สบายใจ ปลอดภัยแน่นอน
            </p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="container p-3">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pr-lg-5 pr-0">
            <div class="video">
              <div class="embed-responsive embed-responsive-16by9 my-2">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/29zF-hyXjbA"
                  title="อยากมีรถในฝัน ผ่อนสบาย  รีไฟแนนซ์สะดวก สูงสุด 50 ล้าน l Kong Story EP.678" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>

              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 pl-lg-5 pl-0">
            <h2 class="text-left mt-3 carfinn-primary-blue-text">[ รู้จักกับเรา ] คาร์ฟินน์</h2>
            <h2 class="text-left carfinn-primary-blue-text">รีไฟแนนซ์รถยนต์ อนุมัติง่าย ทำได้ทุกอาชีพ</h2>
            <p class="text-left mt-3 content-text">
              สินเชื่อรถยนต์ครบวงจร สำหรับตลาดรถยนต์ทุกชนิด
              ทั้งรถมือหนึ่ง/มือสอง ไม่ว่าจะมียอดเงินกู้อยู่ (รีไฟแนนซ์) หรือผ่อนหมดแล้ว
              (จำนำเล่มทะเบียนรถยนต์)ก็สามารถขออนุมัติ
              สินเชื่อรถยนต์ ได้ง่าย สะดวก รวดเร็ว และ ปลอดภัย ผ่านแพลทฟอร์มทันสมัย ได้วงเงินสูงสุด
              ลดขั้นตอน
              ทำง่าย สบายใจ ปลอดภัยแน่นอน
            </p>
          </div>
        </div>
      </div>
    </ng-template> -->
  </ng-container>
</owl-carousel-o>