<div mat-dialog-title class="dialog-header">
    <div class="header" style="position: relative;">
        <div style="position: absolute; right:0; margin-top: -5px;">
            <a [mat-dialog-close]="true" (click)="closeModal()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>

<mat-dialog-content class="mat-typography dialog-centent px-0">
    <div class="container wrapper-container">
        <div class="p-3 mb-4">
            <h4 class="text-center mb-3 topic-text">
                บัญชีของคุณถูกระงับการใช้งานชั่วคราว
            </h4>
            <p class="text-center sub-content">
                <span>คุณได้ถูกระงับการใช้งานเนื่องจากมีการใช้งานที่ผิดปกติ</span>
            </p>
        </div>
    </div>
    <div class="ban-footer">
        <p class="text-center footer-text">
            หากมีข้อส่งสัยติดต่อได้ทาง <span (click)="facebook()" class="linktext">Facebook Inbox</span>
            หรือ <span (click)="line()" class="linktext">Line: @CarFinn</span>
            หรือ <span (click)="tel()" class="tel">โทร: 094-665-5114</span>
        </p>
    </div>
</mat-dialog-content>