<div *ngIf="isLoading$ | async">
  <div
    style="
      position: fixed;
      z-index: 900;
      width: 100vw;
      height: 100vh;
      opacity: 80;
      top: 0;
      left: 0;
      background: #e8e8e880;
    "
  >
    <nz-spin
      nzSimple
      [nzSize]="'large'"
      style="
        z-index: 1000;
        padding: 400px 50px;
        margin: 20px 0;
        text-align: center;
      "
    ></nz-spin>
  </div>
</div>

<div *ngIf="article" class="homeInfo">
  <carfinn-navbar-transparent></carfinn-navbar-transparent>

  <div class="container py-5 marginNav" style="">
    <h1
      class="text-left text-header d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none"
    >
      {{ article.article_title }}
    </h1>
    <p
      class="text-left text-header d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block"
    >
      {{ article.article_title }}
    </p>

    <div
      class="d-block d-sm-block d-md-flex d-lg-flex d-xl-flex d-xxl-flex second-sec"
    >
      <div class="writer my-2">เขียนโดย {{ article.writer }}</div>
      <div class="date my-2">
        เผยแพร่เมื่อ {{ article.date | dateTime : "FullNoTime" }}
      </div>
    </div>

    <div class="img-sec d-flex">
      <img
        [src]="article.article_image"
        alt="article"
        class="img-fluid mx-auto img-article"
      />
    </div>

    <div class="content-sec my-3">
      <div [innerHtml]="articleContent" id="_content"></div>
    </div>

    <div class="d-none">
      <div class="share-article-sec d-flex justify-content-between">
        <div class="d-flex">
          <div class="text-left text-share my-auto mr-3">แชร์บทความนี้</div>
          <a href="#" class="logo-share">
            <img
              src="assets/article-img/fb.png"
              class="logo img-fluid"
              alt="fb"
            />
          </a>
          <a href="#" class="logo-share">
            <img
              src="assets/article-img/line.png"
              class="logo img-fluid"
              alt="line"
            />
          </a>
          <a href="#" class="logo-share">
            <img
              src="assets/article-img/tw.png"
              class="logo img-fluid"
              alt="tw"
            />
          </a>
        </div>
        <div class="d-flex">
          <div class="share-detail my-auto mr-5">
            <i class="bi bi-eye mr-3"></i>
            เข้าชม 180 คน
          </div>
          <div class="share-detail my-auto">
            <i class="bi bi-share mr-3"></i>
            แชร์บทความนี้ 25 คน
          </div>
        </div>
      </div>
    </div>
    <div class="d-none">
      <div class="share-article-sec">
        <div class="d-flex my-4">
          <div class="share-detail my-auto mr-5">
            <i class="bi bi-eye mr-3"></i>
            เข้าชม 180 คน
          </div>
          <div class="share-detail my-auto">
            <i class="bi bi-share mr-3"></i>
            แชร์บทความนี้ 25 คน
          </div>
        </div>
        <div class="d-flex my-4">
          <div class="text-left text-share my-auto mr-3">แชร์บทความนี้</div>
          <a href="#" class="logo-share">
            <img
              src="assets/article-img/fb.png"
              class="logo img-fluid"
              alt="fb"
            />
          </a>
          <a href="#" class="logo-share">
            <img
              src="assets/article-img/line.png"
              class="logo img-fluid"
              alt="line"
            />
          </a>
          <a href="#" class="logo-share">
            <img
              src="assets/article-img/tw.png"
              class="logo img-fluid"
              alt="tw"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="interest-article">
      <hr style="width: 100%" />

      <h3 class="text-center my-5 carfinn-primary-cyan-text">
        บทความที่น่าสนใจ
      </h3>

      <div class="row">
        <div
          *ngFor="let rec of recommendArticle"
          class="col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
        >
          <carfinn-article-card [article]="rec"></carfinn-article-card>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <a href="articles" class="btn btn-more my-3"> ดูทั้งหมด </a>
      </div>
    </div>
  </div>

  <carfinn-footer></carfinn-footer>
</div>
