import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

// nz antd module
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';

// material
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';

// pipe
import {
  DatePipe,
  DateTimePipe,
  DateTimeAgoPipe,
  convertDate,
  TimeFormatPipe,
} from '../shared/pipe/date.pipe';
import { SafePipe } from './pipe/safeURL.pipe';

// library
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';

import { ModalPersonalPolicyComponent } from './components/modal-personal-policy/modal-personal-policy.component';
import { ModalSuccessComponent } from './components/modal-success/modal-success.component';
import { RefinanceStepsComponent } from './components/refinance-steps/refinance-steps.component';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { CarfinnBtnComponent } from './components/carfinn-btn/carfinn-btn.component';
import { CarouselShowPicComponent } from './components/carousel-show-pic/carousel-show-pic.component';
import { CarouselPicRouterComponent } from './components/carousel-pic-router/carousel-pic-router.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ComingSoonCardComponent } from './components/coming-soon/coming-soon-card/coming-soon-card.component';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { IconCardComponent } from './components/icon-card/icon-card.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ScrollDirective } from './components/navbar/scroll.directive';
import { ScrollNavOtherPageDirective } from './components/navbar/scroll-nav-other-page.directive';
import { NavbarTransparentComponent } from './components/navbar-transparent/navbar-transparent.component';
import { ModalNotMatchResultComponent } from './components/modal-not-match-result/modal-not-match-result.component';
import { StepsComponent } from './components/steps/steps.component';
import { LoginBoxComponent } from './components/login-box/login-box.component';
import { BannerComponent } from '../modules/home/components/banner/banner.component';
import { NewMoneyChangerComponent } from './components/new-money-changer/new-money-changer.component';
import { MoneyChangerComponent } from '../modules/car-loan/pages/money-changer/money-changer.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { ContactButtonComponent } from './components/contact-button/contact-button.component';
import { SelectWidthDirective } from './directives/select-width.directive';
import { NewSearchPromotionComponent } from '../modules/home/components/new-search-promotion/new-search-promotion.component';
import { OurServiceComponent } from './components/our-service/our-service.component';
import { OfUsComponent } from './components/of-us/of-us.component';
import { ParthnerComponent } from './components/parthner/parthner.component';
import { PlatformComponent } from './components/platform/platform.component';
import { QuestionPageComponent } from './components/question-page/question-page.component';
import { VideoPresentationComponent } from './components/video-presentation/video-presentation.component';
import { TabHomeComponent } from './components/tab-home/pages/tab-home.component';
import { FinanceComponent } from './components/tab-home/pages/finance/finance.component';
import { SaleComponent } from './components/tab-home/pages/sale/sale.component';
import { ButtonBannerComponent } from './components/button-banner/button-banner.component';
import { AddHtmlAttributeDirective } from './directives/add-html-attribute.directive';
import { PropfinnLoginModalComponent } from './components/propfinn-login-modal/propfinn-login-modal.component';
import { CommentComponent } from './components/comment/comment.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { NgChartsModule } from 'ng2-charts';
import { ModalCompleteComponent } from './components/modal-complete/modal-complete/modal-complete.component';
import { AdvertisingModalComponent } from './components/advertising-modal/advertising-modal.component';

const NzModule = [
  NzMenuModule,
  NzDrawerModule,
  NzIconModule,
  NzButtonModule,
  NzGridModule,
  NzCardModule,
  NzFormModule,
  NzInputModule,
  NzDividerModule,
  NzCarouselModule,
  NzRadioModule,
  NzTabsModule,
  NzAvatarModule,
  NzToolTipModule,
  NzStepsModule,
  NzSelectModule,
  NzSpinModule,
  NzDatePickerModule,
  NzTagModule,
  NzModalModule,
  NzCollapseModule,
  NzUploadModule,
  NzCheckboxModule,
  NzSliderModule,
  NzTableModule,
  NzRateModule,
  NzImageModule,
  NzInputNumberModule,
  NzAutocompleteModule,
  NzProgressModule,
  NzAlertModule,
  NzPopoverModule,
  NzSwitchModule,
  NzMessageModule,
  NzListModule,
  NzPaginationModule,
  NzDropDownModule,
  NzTimePickerModule,
  NzAnchorModule,
  NzTabsModule,
];

const MaterialModule = [
  MatButtonToggleModule,
  MatDialogModule,
  MatSidenavModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatInputModule,
  MatButtonModule,
  MatRadioModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
  MatDividerModule,
];

const otherModule = [
  CarouselModule,
  NgbModule,
  NgOtpInputModule,
  CountdownModule,
];

@NgModule({
  declarations: [
    MoneyChangerComponent,
    ModalPersonalPolicyComponent,
    ModalSuccessComponent,
    RefinanceStepsComponent,
    ArticleCardComponent,
    DatePipe,
    DateTimePipe,
    DateTimeAgoPipe,
    TimeFormatPipe,
    convertDate,
    CarfinnBtnComponent,
    CarouselShowPicComponent,
    CarouselPicRouterComponent,
    ComingSoonComponent,
    ComingSoonCardComponent,
    FloatingMenuComponent,
    IconCardComponent,
    ModalComponent,
    ModalLoginComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    ScrollDirective,
    ScrollNavOtherPageDirective,
    NavbarTransparentComponent,
    ModalNotMatchResultComponent,
    StepsComponent,
    LoginBoxComponent,
    ContactButtonComponent,
    // BannerComponent,
    NewMoneyChangerComponent,
    LoginModalComponent,
    SelectWidthDirective,
    NewSearchPromotionComponent,
    OurServiceComponent,
    OfUsComponent,
    ParthnerComponent,
    PlatformComponent,
    VideoPresentationComponent,
    QuestionPageComponent,
    SafePipe,
    TabHomeComponent,
    FinanceComponent,
    SaleComponent,
    ButtonBannerComponent,
    AddHtmlAttributeDirective,
    PropfinnLoginModalComponent,
    CommentComponent,
    LoginDialogComponent,
    ModalCompleteComponent,
    AdvertisingModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NzModule,
    otherModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    NgChartsModule,
  ],
  exports: [
    MaterialModule,
    NzModule,
    otherModule,
    ModalPersonalPolicyComponent,
    ModalSuccessComponent,
    RefinanceStepsComponent,
    ArticleCardComponent,
    DatePipe,
    DateTimePipe,
    DateTimeAgoPipe,
    TimeFormatPipe,
    convertDate,
    CarfinnBtnComponent,
    CarouselShowPicComponent,
    CarouselPicRouterComponent,
    ComingSoonComponent,
    ComingSoonCardComponent,
    FloatingMenuComponent,
    IconCardComponent,
    ModalComponent,
    ModalLoginComponent,
    LoginBoxComponent,
    ContactButtonComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    ScrollDirective,
    ScrollNavOtherPageDirective,
    NavbarTransparentComponent,
    TranslateModule,
    ModalNotMatchResultComponent,
    StepsComponent,
    MoneyChangerComponent,
    NewSearchPromotionComponent,
    OurServiceComponent,
    OfUsComponent,
    ParthnerComponent,
    PlatformComponent,
    VideoPresentationComponent,
    QuestionPageComponent,
    SafePipe,
    TabHomeComponent,
    ButtonBannerComponent,
    AddHtmlAttributeDirective,
    CommentComponent,
    NgChartsModule,
    ModalCompleteComponent,
  ],
})
export class SharedModule {}
