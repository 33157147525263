import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AdvertisingService } from 'src/app/core/services/advertising.service';

@Component({
  selector: 'app-advertising-modal',
  templateUrl: './advertising-modal.component.html',
  styleUrls: ['./advertising-modal.component.scss']
})
export class AdvertisingModalComponent implements OnInit {

  @Input() id: number;
  @Input() fileLocation: string;
  @Input() advertiseLink: string;
  @Input() width: number;


  constructor( private advertisingService: AdvertisingService,
    private modal: NzModalService) { }

  ngOnInit(): void {
  }

  onAdvertisingClick(): void {
    this.advertisingService.intoAdvertising(this.id).subscribe();
    this.modal.closeAll();
  }

  close(): void {
    this.modal.closeAll();
  }


}
